<template>
  <HeaderComponent />
  <router-view :key="$route.path"></router-view>
</template>

<script>
import HeaderComponent from "@/components/registration/views/headerView.vue";
import { useViewsStore } from "@/store/registration/viewsStore";
import checkDataChange from "@/utils/checkDataChange";
import { showNegativeNotify, showPositiveNotify } from "@/utils/useNotify";
import { useLoginStore } from "@/store/loginStore";
export default {
  components: {
    HeaderComponent,
  },
  data() {
    const store = useViewsStore();
    const storeLogin = useLoginStore();
    return {
      store,
      storeLogin,
      textSearch: "",
      isNewView: false,
      currentView: null,
      currentId: null,
      spinnerLoading: false,
      widgetList: [],
    };
  },

  methods: {
    // Criando um novo objeto para atribuir a visão atual
    newView() {
      this.currentView = {
        dash: {
          title: null,
          layoutType: null,
          headTitle: null,
          menu: {
            title: null,
          },
        },
        widget: null,
      };
      this.isNewView = true;
      this.tableIsVisible = false;
    },

    async refreshTable() {
      this.loading = true;
      await this.store.setPagination({
        sortBy: "desc",
        descending: false,
        page: 1,
        rowsPerPage: 50,
        rowsNumber: 0,
      });
      this.rows = this.store.rows;
      this.loading = false;
    },

    //  cria Uma nova visão - Tambem chamada ao editar, porem guiado ate updateView()
    async saveView() {
      if (this.isNewView) {
        // Criar nova View Aqui
        //// console.log(this.currentView);
        let viewToSave = {
          title: this.currentView.dash.title,
          layoutType: this.currentView.dash.layoutType,
          createdBy: this.storeLogin.userId,
          updatedBy: this.storeLogin.userId,
        };
        if (this.currentView.widget !== null)
          viewToSave["widgetId"] = this.currentView.widget.id;
        //// console.log(this.currentView.dash.menu);
        if (this.currentView.dash.menu !== null)
          viewToSave["menu"] = { title: this.currentView.dash.menu.title };
        if (viewToSave && this.currentView.dash.menu.module) {
          viewToSave.menu["moduleId"] = this.currentView.dash.menu.module.id;
        }
        //// console.log(viewToSave);
        const res = await this.store.createView(viewToSave);
        //// console.log(res);
        if (res?.success) {
          await this.refreshTable();
          this.tableIsVisible = true;
          this.isNewView = false;
        } else {
          //// console.log("aqui");
          if (res.data.fieldsRequired) {
            if (res.data.fieldsRequired.some((field) => field === "title"))
              showNegativeNotify("Por favor preencha um titulo");
          }
        }
      } else this.updateView();
    },

    // Verificando se os dados mudaram e se mudou, atualizará os dados.
    async updateView() {
      const defaultCurrentView = await this.store.getById(this.currentId);
      const view = this.currentView;
      //// console.log(view);

      let newData = {};

      // Verifica se alterou o Widget
      const widgetChanged = checkDataChange(
        defaultCurrentView.widget,
        view.widget
      );
      if (widgetChanged.changed) {
        newData["widget"] = widgetChanged.dataChanged;
      }

      // Verifica se alterou a Dash
      const dashChanged = checkDataChange(
        defaultCurrentView.dash,
        view.dash,
        "menu"
      );
      if (dashChanged.changed) {
        newData["dash"] = { title: dashChanged.dataChanged.title };
      }

      //Verifica se alterou o Menu
      const menuChanged = checkDataChange(
        defaultCurrentView.dash.menu,
        view.dash.menu,
        "module"
      );
      if (menuChanged.changed) {
        newData["menu"] = { ...menuChanged.dataChanged, id: view.dash.menu.id };
      }

      //Verifica se alterou o modulo
      const moduleChanged = checkDataChange(
        defaultCurrentView.dash.menu.module,
        view.dash.menu.module
      );
      if (moduleChanged.changed) {
        newData["module"] = {
          moduleId: moduleChanged.dataChanged.id,
          menuId: view.dash.menu.id,
        };
      }

      this.spinnerLoading = true;
      // //// console.log(newData)
      const response = await this.store.updateView(view.dash.id, newData);
      this.spinnerLoading = false;
      let currentViewTitle = this.rows.find(
        (row) => row.id === this.currentId
      ).title;
      if (Object.keys(newData).length > 0) {
        let dataUpdated =
          response.result[
            Object.keys(response.result).find((r) =>
              Object.keys(newData).some((key) => key === r)
            )
          ];
        if (Array.isArray(dataUpdated) && dataUpdated[0] > 0) {
          showPositiveNotify(
            `Os dados da visão ${currentViewTitle} foram atualizados com sucesso!`
          );
          this.currentId = null;
          this.currentView = null;
          this.tableIsVisible = true;
          await this.refreshTable();
        } else {
          showNegativeNotify(
            `Os dados da visão ${currentViewTitle} não foram atualizados!`
          );
        }
      } else {
        showNegativeNotify(
          `Os dados da visão ${currentViewTitle} não foram alterados!`
        );
      }
    },
  },
};
</script>
@/store/certificateManagement/registration/viewsStore
