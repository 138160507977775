export const BoardPT = {
  newSprintFormTitle: "Cadastrar nova Sprint",
  newSprintInBoardFormSubtitle:
    "Defina o nome, a data de inicio e as tarefas para a primeira sprint desse board.",
  newSprintInFormSubtitle:
    "Defina a data de inicio e as tarefas para uma sprint",
  sprintNameInForm: "Dê um nome para essa sprint",
  labelNameSet: "Defina um Nome",
  requiredField: "Campo Obrigatório",
  labelDateSet: "Defina uma data",
  sprintStartDateInForm: "Escolha a data de início",
  searchTask: "Buscar tarefa",
  taskSelect: "Selecione uma tarefa para mover",
  boardNotCreated:
    "Essa Board não pode ser criada, tente novamente mais tarde, ou contate o suporte.",
  boardCreated: "Board criada com sucesso!",
  boardNotUpdated:
    "Essa Board não pode ser atualizada, tente novamente mais tarde, ou contate o suporte.",
  boardUpdated: "Board atualizada com sucesso!",
  updadeSprintError:
    "Não foi possivel atualizar a sprint, tente mais tarde ou contate o suporte!",
  sprintCannotBeTerminated:
    "Existem tarefas não concluídas nessa sprint, mova para uma nova sprint ou finalize-as",
  sprintTerminated: "Sprint encerrada com sucesso",
  sprintCardUpdated:'Tarefa movida para sprint ',
  sprintCardNotUpdated: 'Não foi possivel mover a tarefa de sprint'
};

export const BoardEN = {
  newSprintFormTitle: "Register new Sprint",
  newSprintInBoardFormSubtitle:
    "Define the name, the start date and tasks for the first sprint on this board.",
  newSprintInFormSubtitle: "Define the start date and tasks for a sprint",
  sprintNameInForm: "Give this sprint a name",
  labelNameSet: "Set a name",
  labelDateSet: "Sete a date",
  requiredField: "Required Field",
  sprintStartDateInForm: "Choose the start date",
  searchTask: "Search task",
  taskSelect: "Select a task to move",
  boardNotCreated:
    "This Board cannot be created, please try again later, or contact support.",
  boardCreated: "Board Created Successfully!",
  updadeSprintError: "Unable to update sprint, try later or contact support!",
  boardUpdated: "Board Updated Successfully!",
  boardNotUpdated:
    "This Board cannot be updated, please try again later, or contact support.",
  sprintCannotBeTerminated:
    "There are uncompleted tasks in this sprint, move to a new sprint or finish them",
  sprintTerminated: "Sprint ended successfully",
  sprintCardUpdated:'Task moved to sprint ',
  sprintCardNotUpdated: 'Unable to move sprint task'
};

export const BoardES = {
  newSprintFormTitle: "Registrar nuevo Sprint",
  newSprintInBoardFormSubtitle:
    "Defina el nombre, la fecha de inicio y las tareas para el primer sprint en este tablero.",
  newSprintInFormSubtitle:
    "Definir la fecha de inicio y las tareas para un sprint",
  sprintNameInForm: "Dale un nombre a este sprint",
  labelNameSet: "Establecer un nombre",

  labelDateSet: "Estabelecer una fecha",
  requiredField: "Campo obligatorio",
  sprintStartDateInForm: "Elige la fecha de inicio",
  searchTask: "Tarea de búsqueda",
  taskSelect: "Seleccione una tarea para mover",
  boardNotCreated:
    "Este tablero no se puede crear, inténtelo de nuevo más tarde o comuníquese con el soporte.",
  boardCreated: "¡Tablero creado con éxito!",
  boardNotUpdated:
    "Este tablero no se puede actualizar, inténtelo de nuevo más tarde o comuníquese con el soporte.",
  boardUpdated: "¡Tablero actualizado con éxito!",
  updadeSprintError:
    "No se puede actualizar Sprint, inténtalo más tarde o contacta al soporte.",
  sprintCannotBeTerminated:
    "Hay tareas sin completar en este sprint, pasa a un nuevo sprint o termínalas",
  sprintTerminated: "El sprint finalizó con éxito",
  sprintCardUpdated:'Tarea movida al sprint ',
  sprintCardNotUpdated: 'No se puede mover la tarea de sprint'
};
