import { getDeepDifference } from "@gthrm/deep-diff";
export default function compareDiff(defaultValue, newValue, exclude = null) {
  let dValue = JSON.parse(JSON.stringify(defaultValue));
  let nValue = JSON.parse(JSON.stringify(newValue));

  if (exclude !== null) {
    if (Array.isArray(exclude)) {
      exclude.forEach((ex) => {
        delete dValue[ex];
        delete nValue[ex];
      });
    } else {
      delete dValue[exclude];
      delete nValue[exclude];
    }
  }
  const result = getDeepDifference(dValue, nValue);
  return {
    changed: Object.keys(result).length > 0 || result.length > 0,
    dataChanged: result,
  };
}
