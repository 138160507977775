export const MessagesPT = {
  userExists:"Este usuário para login já esta em uso!",
  fieldsCannotBeEmpty:"Os seguintes campos não podem ser vazios",
  importantWord:'Inportante!',
  deactiveUser:'Desativar Usuário',
  askDeactiveUser:'Você seja desativar o usuário ',
  askDuplicateUser:'Você seja duplicar o usuário ',
  deactivate:'Desativar',
  duplicate:'Duplicar',
  cancel:'Cancelar',
  savedProjectGrouper:'Agrupador de projetos salvo com sucesso!',
  savedProjectError:'Não foi possivel atualizar o agrupador de projetos!',
  removedProjectGrouper:'Agrupador de projetos removido com sucesso!'

};

export const MessagesEN = {
  userExists:"This login user is already in use!",
  fieldsCannotBeEmpty:"The fields below cannot be empty",
  importantWord:'Important!',
  deactiveUser:'Disable User',
  askDeactiveUser:'Do you want to disable user ',
  askDuplicateUser:'Do you want to duplicate the user ',
  deactivate:'Disable',
  duplicate:'Duplicate',
  cancel:'Cancel',
  savedProjectGrouper:'¡El agrupador de proyectos se ha guardado correctamente!',
  savedProjectError:'¡No se puede actualizar el paquete de proyectos!',
  removedProjectGrouper:'¡El agrupador de proyectos se eliminó con éxito!'
};

export const MessagesES = {
  userExists:'¡Este usuario de inicio de sesión ya está en uso!',
  fieldsCannotBeEmpty:'Los campos a continuación no pueden estar vacíos',
  importantWord:"¡importante!",
  askDeactiveUser:'¿Quieres desactivar el usuario ',
  askDuplicateUser: '¿Quieres duplicar el usuario ',
  deactivate:'Desactivar',
  duplicate:'Duplicar',
  cancel:'Cancelar',
  savedProjectGrouper:'Successfully saved project grouper!',
  savedProjectError:'Unable to update project bundler!',
  removedProjectGrouper:'Project grouper successfully removed!'
};
