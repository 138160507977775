<template>
  <div class="flex space-x-4 h-full">
    <div
      class="w-[33rem] max-w-[360px] min-w-[360px] rounded space-y-3 relative h-full border border-grey200"
    >
      <div class="flex justify-between p-2 border-b border-grey200">
        <h2 class="uppercase font-bold text-secondary">Inbox</h2>
        <div class="flex items-center space-x-1">
          <q-btn
            flat
            dense
            size="xs"
            @click="previousPage"
            icon="chevron_left"
            color="primary"
          />
          <span class="text-xs text-dark"
            >{{ store.currentRow }}-{{ store.ultimateRow }} de
            {{ store.totalRows }}</span
          >

          <q-btn
            flat
            dense
            size="xs"
            @click="nextPage"
            icon="chevron_right"
            color="primary"
          />
        </div>
      </div>

      <div class="px-2 h-[calc(100%_-_20px)]">
        <!-- CARD CARD CARD -->
        <div
          class="overflow-y-auto mb-7 h-full max-h-[92%]"
          id="ContainerCards"
        >
          <div
            v-for="(item, index) in mailList"
            :key="index.id"
            class="group relative p-2 odd:bg-white even:bg-grey100 rounded mt-2 mr-1 mx-auto cursor-pointer hover:bg-grey200"
            @click="getMailItem(item)"
          >
            <!--  -->
            <div class="flex w-full justify-start items-start">
              <div class="pr-2 cursor-pointer max-w-[3.5rem] max-h-[3rem]">
                <img
                  class="rounded-full"
                  src="https://img.freepik.com/fotos-gratis/mulher-asiatica-posando-e-olhando-para-a-camera_23-2148255359.jpg?w=826&t=st=1665073641~exp=1665074241~hmac=0d7ce0327ea75b3de0948f7e7f4f39f68d64400cafbb8dffc278cd8480bd0a35"
                  alt=""
                />
              </div>
              <div class="flex flex-col justify-center w-full">
                <p class="text-sm">Nome Sobrenome</p>
                <span class="text-xs text-blue400">{{ item.subject }}</span>
              </div>
              <div class="flex items-center">
                <div
                  v-for="(item, index) in item.mailRecips"
                  :key="index.id"
                  class="absolute top-[10px] right-[30px] flex w-full items-center justify-end space-x-2"
                >
                  <span class="text-xs text-grey500">{{
                    getData(item.sentOn)
                  }}</span>
                </div>
                <q-btn flat dense size="sm" icon="bookmark" color="primary" />
              </div>
            </div>
            <div class="w-full pt-1">
              <p class="text-grey500 text-xs">
                Lorem ipsum dolor sit, amet consectetur adipisicing. Lorem ipsum
                dolor sit, amet consectetur adipisicing.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ContentEmailContainer />
  </div>
</template>

<script setup>
import { onMounted, computed } from "vue";
import ContentEmailContainer from "./contentEmailComponent.vue";
import { useMailStore } from "@/store/products/mailStore";

const store = useMailStore();

onMounted(async () => {
  await store.getMail();
});

const mailList = computed(() => {
  return store.mail;
});

const getData = (data) => {
  //// console.log(data);
  const date = new Date(data);
  function formatDoubleDate(dt) {
    return dt <= 9 && dt > 0 ? "0" + dt : dt;
  }
  let dateFormated = data
    ? formatDoubleDate(date.getDate()) +
      "/" +
      formatDoubleDate(date.getMonth() + 1) +
      "/" +
      date.getFullYear()
    : "";
  return data !== {} ? dateFormated : "";
};

const nextPage = () => {
  store.countPage += 1;
  store.currentRow += store.currentRow < 19 ? 19 : 20;
  store.ultimateRow += 20;
  store.getMail();
};
const previousPage = () => {
  if (store.countPage === 1) {
    return;
  }
  store.countPage -= 1;
  store.currentRow -= store.currentRow === 20 ? 19 : 20;
  store.ultimateRow -= 20;
  store.getMail();
};

const getMailItem = (item) => {
  store.currentItem = item;
};
</script>
