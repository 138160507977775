export default [
  {
    id: 1,
    message: "success",
    data: {
      id: 999,
      title: "Títuto do Formulário",
      subtitle: "Subtitulo do Formulário",

      children: [
        {
          type: "section",
          children: [
            {
              type: "col",
              children: [
                {
                  type: "PP",
                  ptype: "D",
                  compType: "",
                  shortcuts: true,
                  style: {
                    inline: { marginBottom: 10, flexBasis: "49%" },
                    classes: {},
                  },
                },
                {
                  typedaCris: "pp-number", // {'pp-text', 'email', }
                  type: "PP", // <- Tipo do Componente PP = Property
                  ptype: "T", //{T, N, D} <--Property Type
                  //compType: 'email' {cpf/cnpj/textarea/etc}
                  label: "Cód. da Empresa",
                  labelPos: "left", // {'top/left/right'}
                  labelAlign: "right",
                  required: true,
                  hint: "dica dica dica",
                  prefix: "",
                  suffix: "",
                  placeholder: "",
                  animatedLabel: true,
                  width: 100,
                  style: {
                    inline: { flexBasis: "49%" },
                    classes: {},
                  },
                },
                {
                  type: "PP",
                  ptype: "T", // {N, D}
                  compType: "", //{cpf/cnpj/textarea/etc}
                  label: "Nome da Empresa",
                  labelPos: "left",
                  labelAlign: "right",
                  required: false,
                  hint: "dica dica dica",
                  prefix: "$",
                  suffix: "",
                  placeholder: "",
                  animatedLabel: false,
                  style: {
                    inline: { marginTop: 5, marginBottom: 5, width: "49%" },
                    classes: {},
                  },
                },
                {
                  // {'pp-text', 'email', }
                  type: "PP", // <- Tipo do Componente PP = Property
                  ptype: "DD", //{T, N, D} <--Property Type
                  //compType: 'email' {cpf/cnpj/textarea/etc}
                  label: "Selecione uma opção",
                  optionsId: 2,
                  labelPos: "left", // {'top/left/right'}
                  labelAlign: "right",
                  required: true,
                  hint: "dica dica dica",
                  prefix: "",
                  suffix: "",
                  placeholder: "",
                  animatedLabel: true,
                  style: {
                    inline: { marginTop: 5, width: "49%" },
                    classes: {},
                  },
                },
                {
                  type: "PP",
                  ptype: "F",
                  compType: "",
                  title: "",
                  disable: false,
                  width: "",
                  hint: "Arquivos JPEG",
                  style: {
                    inline: { marginTop: 5, marginBottom: 5, width: "33%" },
                    classes: {},
                  },
                },

                {
                  // {'pp-text', 'email', }
                  type: "PP", // <- Tipo do Componente PP = Property
                  ptype: "N", //{T, N, D} <--Property Type
                  //compType: 'email' {cpf/cnpj/textarea/etc}
                  label: "Digite um numero",
                  labelPos: "left", // {'top/left/right'}
                  labelAlign: "right",
                  required: true,
                  hint: "dica dica dica",
                  prefix: "",
                  suffix: "",
                  placeholder: "",
                  animatedLabel: true,
                  style: {
                    inline: { marginTop: 5, marginBottom: 5, width: "33%" },
                    classes: {},
                  },
                },
                {
                  // {'pp-text', 'email', }
                  type: "PP", // <- Tipo do Componente PP = Property
                  ptype: "TA", //{T, N, D} <--Property Type
                  //compType: 'email' {cpf/cnpj/textarea/etc}
                  label: "Digite um texto",
                  labelPos: "left", // {'top/left/right'}
                  labelAlign: "right",
                  required: true,
                  // style:{
                  //   inline:{

                  //   },
                  //   classes:{
                  //     ghjdsajfh: ''
                  //   }
                  // },
                  hint: "dica dica dica",
                  prefix: "",
                  suffix: "",
                  placeholder: "",
                  animatedLabel: true,
                  style: {
                    inline: { marginTop: 5, marginBottom: 5, width: "33%" },
                    classes: {},
                  },
                },
                {
                  type: "PP",
                  ptype: "C", // {N, D}
                  compType: "", //{cpf/cnpj/textarea/etc}
                  title: "Salvar para todos",
                  textLeft: false,
                  disable: false,
                  style: {
                    inline: {
                      marginTop: 5,
                      marginBottom: 5,
                      width: "100%",
                    },
                    classes: {},
                  },
                },
                {
                  type: "PP",
                  ptype: "CT",
                  compType: "",
                  title: "Exibir em todos",
                  trueValue: true,
                  falseValue: false,
                  indeterminateValue: "",
                  textLeft: false,
                  disable: false,
                  style: {
                    inline: { marginTop: 5, marginBottom: 5, width: "100%" },
                    classes: {},
                  },
                },
              ],
            },
            {
              type: "col",
              children: [
                {
                  type: "EL",
                  etype: "B",
                  title: "Salvar",
                  style: {
                    inline: {
                      width: 200,
                      marginLeft: "auto",
                      backgroundColor:'purple',
                      color:'white'
                    },
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: 2,
    message: "success",
    data: {
      id: 999,
      title: "Adicionar novo condominio",
      subtitle: "O condominio precisa ser adicionado no container adicionado",

      children: [
        {
          type: "section",
          children: [
            {
              type: "col",
              children: [
                {
                  type: "PP", // <- Tipo do Componente PP = Property
                  ptype: "T", //{T, N, D} <--Property Type
                  //compType: 'email' {cpf/cnpj/textarea/etc}
                  label: "Condominio",
                  labelPos: "left", // {'top/left/right'}
                  labelAlign: "right",
                  required: true,
                  hint: "Digite o nome do Condominio",
                  prefix: "",
                  suffix: "",
                  placeholder: "",
                  animatedLabel: true,
                  width: 100,
                  style: {
                    inline: { flexBasis: "49%" },
                    classes: {},
                  },
                },
                {
                  // {'pp-text', 'email', }
                  type: "PP", // <- Tipo do Componente PP = Property
                  ptype: "S", // DD DropDown
                  //compType: 'email' {cpf/cnpj/textarea/etc}
                  label: "Status",
                  optionsId: 3,
                  defaultOption:{id:1,label:"Adicionado"},
                  labelPos: "left", // {'top/left/right'}
                  labelAlign: "right",
                  required: true,
                  hint: "Status de Analise",
                  prefix: "",
                  suffix: "",
                  placeholder: "",
                  animatedLabel: true,
                  style: {
                    inline: { marginTop: 5, width: "49%" },
                    classes: {},
                  },
                },
                {
                  type: "PP",
                  ptype: "T", // {N, D}
                  compType: "", //{cpf/cnpj/textarea/etc}
                  label: "Funcionario",
                  labelPos: "left",
                  labelAlign: "right",
                  required: false,
                  hint: "Nome do funcionario",
                  prefix: "$",
                  suffix: "",
                  placeholder: "",
                  animatedLabel: false,
                  style: {
                    inline: { marginTop: 5, marginBottom: 5, width: "49%" },
                    classes: {},
                  },
                },
                {
                  type: "PP",
                  ptype: "D",
                  compType: "",
                  shortcuts: true,
                  label:'Data de entrega',
                  style: {
                    inline: { marginBottom: 10, flexBasis: "49%" },
                    classes: {},
                  },
                },
                {
                  type: "PP",
                  ptype: "D",
                  compType: "",
                  shortcuts: true,
                  label:'Prazo para registro',
                  style: {
                    inline: { marginBottom: 10, flexBasis: "49%" },
                    classes: {},
                  },
                },
                {
                  // {'pp-text', 'email', }
                  type: "PP", // <- Tipo do Componente PP = Property
                  ptype: "S", // DD DropDown
                  //compType: 'email' {cpf/cnpj/textarea/etc}
                  label: "Status da Analise",
                  optionsId: 4,
                  defaultOption:{id:1,label:"Recebido"},
                  labelPos: "left", // {'top/left/right'}
                  labelAlign: "right",
                  required: true,
                  hint: "Status de Analise",
                  prefix: "",
                  suffix: "",
                  placeholder: "",
                  animatedLabel: true,
                  style: {
                    inline: { marginTop: 5, width: "49%" },
                    classes: {},
                  },
                },
                {
                  typedaCris: "pp-number", // {'pp-text', 'email', }
                  type: "PP", // <- Tipo do Componente PP = Property
                  ptype: "T", //{T, N, D} <--Property Type
                  //compType: 'email' {cpf/cnpj/textarea/etc}
                  label: "Analista",
                  labelPos: "left", // {'top/left/right'}
                  labelAlign: "right",
                  required: true,
                  hint: "Nome da Analista",
                  prefix: "",
                  suffix: "",
                  placeholder: "",
                  animatedLabel: true,
                  width: 100,
                  style: {
                    inline: { flexBasis: "49%" },
                    classes: {},
                  },
                },
                {
                  type: "PP",
                  ptype: "D",
                  compType: "",
                  shortcuts: true,
                  label:'Data da Resposta',
                  style: {
                    inline: { marginBottom: 10, flexBasis: "49%" },
                    classes: {},
                  },
                },
                {
                  type: "PP",
                  ptype: "TM", // Texto com mascara
                  compType: "", //{cpf/cnpj/textarea/etc}
                  label: "CNPJ",
                  maskType:'cnpj',
                  labelPos: "left",
                  labelAlign: "right",
                  required: false,
                  hint: "CNPJ da Empresa",
                  prefix: "",
                  suffix: "",
                  placeholder: "",
                  animatedLabel: false,
                  style: {
                    inline: { marginTop: 5, marginBottom: 5, width: "49%" },
                    classes: {},
                  },
                },
               
               
              ],
            },
            {
              type: "col",
              children: [
                {
                  type: "EL",
                  etype: "B",
                  title: "Salvar",
                  action:'save',
                  style: {
                    inline: {
                      width: 200,
                      marginLeft: "auto",
                      backgroundColor:'purple',
                      color:'white'
                    },
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  },
];
