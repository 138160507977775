export default function checkDataChange(defaultValue, newValue, exclude = null) {
  const dataChanged = {};
  // console.log(defaultValue)
  Object.entries(newValue).forEach(([keyNewValue, valueNewValue]) => {
    // console.table({[keyNewValue]:valueNewValue})
    if(exclude === null){
        if (
            Object.entries(defaultValue).some(
              ([keyDefaultValue, valueDefaultValue]) =>
                keyDefaultValue === keyNewValue && valueDefaultValue !== valueNewValue
            )
          ) {
            dataChanged[keyNewValue] = valueNewValue;
          }
    }else {
        if (
            Object.entries(defaultValue).some(
              ([keyDefaultValue, valueDefaultValue]) =>
                keyDefaultValue === keyNewValue && valueDefaultValue !== valueNewValue
            ) && keyNewValue !== exclude
          ) {
            dataChanged[keyNewValue] = valueNewValue;
          }
    }
  });
  return {
    changed:Object.keys(dataChanged).length > 0,
    dataChanged
  };
}
