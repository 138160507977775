import Notify from 'quasar/src/plugins/Notify.js';;

export const showPositiveNotify = (message) => {
  Notify.create({
    type: "positive",
    color: "green",
    message: message,
    html: true,
    progress: true,
    position: "top-right",
  });
};

export const showNegativeNotify = (message) => {
  Notify.create({
    type: "negative",
    message: message,
    html: true,
    progress: true,
    position: "top-right",
  });
};
