<template>
  <grid-layout
    class="grid_layout"
    ref="gridlayout"
    v-model:layout="componentsArray"
    :col-num="12"
    :row-height="50"
    :is-draggable="false"
    :is-resizable="false"
    :vertical-compact="true"
    :use-css-transforms="true"
  >
    <grid-item
      v-for="(item, i) in components"
      :key="item.i"
      :x="item.data.x"
      :y="item.data.y"
      :w="item.data.w"
      :h="item.data.h"
      :i="item.data.i"
      ref="items"
      :id="`item_${i}`"
      class="flex flex-col items-center justify-center group"
    >
      <component
        :is="item.component"
        v-bind="getAllProperties(item)"
      ></component> </grid-item
  ></grid-layout>
  <!-- </div> -->
</template>

<script setup>
import {
  defineProps,
  defineAsyncComponent,
  getCurrentInstance,
  ref,
} from "vue";
// eslint-disable-next-line
import { GridLayout, GridItem } from "vue3-grid-layout";
import ErrorComponent from "./Cmps/ErrorComponent.vue";
import LoadingComponent from "./Cmps/LoadingComponent.vue";

const props = defineProps(["data", "headerHeight"]);
const compError = ref("");
// console.log(props.data)
/**
 * Função responsavel por enviar as props corretas para cada componente
 * @param {object} current
 */

// eslint-disable-next-line
function getAllProperties(current) {
  const instance = getCurrentInstance();
  const infoComponent = instance.parent.data;
  let props = {
    currentProps: infoComponent === undefined ? compError.value : current.data,
  };
  return props;
}

/**
 * Função responsável por montar o nome do componente para importação
 * @param {object} child
 */
function setNameComponent(child) {
  if (Object.keys(child).length === 0) {
    return "Error";
  }
  if (child.type === "widget") {
    if (child.subtype.includes("-")) {
      return (
        "widget/" +
        child.subtype
          .split("-")
          .map((c) => c[0].toUpperCase() + c.substr(1))
          .join()
          .replace(/,/g, "")
      );
    } else {
      return (
        "widget/" + child.subtype[0].toUpperCase() + child.subtype.substr(1)
      );
    }
  } else if (child.ptype) {
    return "PP/" + child.ptype[0].toUpperCase() + child.ptype.substr(1);
  } else {
    if (child.type.includes("-")) {
      return child.type
        .split("-")
        .map((c) => c[0].toUpperCase() + c.substr(1))
        .join()
        .replace(/,/g, "");
    } else {
      return child.type[0].toUpperCase() + child.type.substr(1);
    }
  }
}

/**
 * Função responsável por importar os componentes
 */
function componentsImport() {
  //Interar sob os item da props com os children
  return props.data.children && props.data.children.length > 0
    ? props.data.children.map((child) => {
        compError.value = child; // Pega os dados do filho para enviar para o componente de erro se preciso
        //Monta um novo objeto com o componente importado para exibir
        return {
          data: { ...child },
          //Chamar os componentes
          component: defineAsyncComponent({
            loader: () => import(`./Cmps/${setNameComponent(child)}Dash.vue`),
            delay: 200,
            loadingComponent: LoadingComponent,
            errorComponent: ErrorComponent,
            timeout: 3000,
          }),
        };
      })
    : null;
}
const components = componentsImport();
const componentsArray = ref([components[0].data]);
</script>

<style></style>
