<template>
  <div class="h-full">
    <!-- MAIN -->
    <div class="flex flex-col items-center">
      <div class="flex flex-col bg-white w-full min-w-[630px] py-3 space-y-6">
        <!-- INTRODUÇÃO -->
        <div class="flex xs:flex-col xs:space-y-5 lg:space-y-0 justify-between">
          <div class="flex-col space-y-3">
            <div class="flex flex-col space-y-1">
              <span class="text-grey900 text-sm font-semibold uppercase"
                >Informações básicas</span
              >
              <span class="text-sm text-grey500"
                >Selecione uma imagem para a página de login.</span
              >
            </div>

            <!-- SELECT IMAGE -->
            <div class="flex space-x-4 flex-row w-full">
              <InputQuasarComponent
                class="w-full"
                label="Nome"
                icon="person"
                v-model="currentUserName"
                :rules="nameRules"
              />
              <InputQuasarComponent
                class="w-full"
                label="Cargo"
                icon="assignment_ind"
                v-model="currentUserOffice"
              />
            </div>
          </div>
        </div>
        <hr class="w-full text-grey300" />

        <div
          class="flex xs:flex-col lg:flex-row xs:space-y-5 lg:space-y-0 justify-between"
        >
          <div class="flex flex-col space-y-1">
            <span class="text-grey900 text-sm font-semibold uppercase"
              >Imagem</span
            >
            <span class="text-sm text-grey500"
              >Selecione uma imagem para a página de login.</span
            >
          </div>

          <!-- SELECT IMAGE -->
          <div class="flex relative mr-7">
            <q-avatar
              size="100px"
              font-size="52px"
              color="secondary"
              text-color="white"
            >
              <img v-if="currentImage" :src="currentImage" />
              <span v-else>{{ getInitialLetters(currentUserName) }}</span>
            </q-avatar>

            <div
              @click="showModal = true"
              class="bg-blue500 rounded-full w-7 h-7 flex items-center justify-center absolute ml-[4.55rem] -mt-[-3.80rem] cursor-pointer"
            >
              <i class="fas fa-camera-alt text-white text-sm"></i>
            </div>
          </div>
        </div>
        <hr class="w-full text-grey300" />
      </div>
      <!-- DROPDOWN CHANGE IMAGE -->
      <changeImageComponent
        v-model="showModal"
        @changeImage="updateImage"
        @saveImage="$emit('saveImage')"
        :user="currentUser"
      />
      <!-- STATUS DE TRABALHO -->
      <div
        class="flex xs:flex-col lg:flex-row justify-between bg-white w-full min-w-[630px] space-y-3"
      >
        <!-- INTRODUÇÃO -->
        <div class="flex flex-col space-y-1">
          <span class="text-grey900 text-sm font-semibold uppercase"
            >Status de trabalho</span
          >
          <span class="text-sm text-grey500"
            >Selecione uma imagem para a página de login.</span
          >
        </div>

        <div class="flex flex-col w-full mx-10 space-y-7">
          <SelectComponent
            label="Status"
            :options="workStatus"
            optionLabel="label"
            optionValue="value"
            v-model="currentUserOfficeStatus"
          />

          <div
            v-if="currentUserOfficeStatus.value === 'ferias'"
            class="flex space-x-3 w-full"
          >
            <div class="space-y-1 w-full">
              <q-item-label class="text-xs font-medium uppercase"
                >Período:</q-item-label
              >

              <q-input
                outlined
                dense
                :model-value="`${dateRange.from} até ${dateRange.to}`"
                disable
              >
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy
                      cover
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date
                        v-model="dateRange"
                        range
                        mask="DD-MM-YYYY"
                        minimal
                        dense
                      >
                        <div class="row items-center justify-end">
                          <q-btn
                            v-close-popup
                            label="Fechar"
                            color="primary"
                            flat
                          />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
            </div>
            <div class="space-y-1 w-full">
              <q-item-label class="text-xs font-medium uppercase"
                >Substituto no período:</q-item-label
              >
              <SelectComponent
                label="Substituto"
                :options="users"
                optionLabel="name"
                optionValue="id"
                v-model="substitute"
                disable
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, defineProps } from "vue";
import { useRoute } from "vue-router";
import moment from "moment";
import { useUsers } from "@/store/securityAndSettings/usersStore";
import { useLoginStore } from "@/store/loginStore";
import { getInitialLetters } from "@/utils/useUtils";
import "v-calendar/dist/style.css";
import changeImageComponent from "./changeImageComponent.vue";
import InputQuasarComponent from "../../../../global/componentsVue/inputComponent/inputQuasarComponent.vue";
import SelectComponent from "../../../../global/componentsVue/selectComponent/selectObjectComponent.vue";

defineProps(["saveImage"]);
const route = useRoute();
const store = useUsers();
const loginStore = useLoginStore();
const showModal = ref(false);
const workStatus = ref([
  { label: "No Escritorio", value: "escritorio" },
  { label: "Ausente", value: "ausente" },
  { label: "Home Office", value: "homeOffice" },
  { label: "Férias", value: "ferias" },
]);
const currentUserOfficeStatus = ref({
  label: "Home Office",
  value: "homeOffice",
});
const dateRange = ref({ from: moment().format("L"), to: moment().format("L") });
const substitute = ref(null);

const nameRules = ref([
  (val) => (val && val.length > 0) || "Campo Obrigatório",
]);

const currentUser = computed(() => store.currentUser);

function createComputedProperty(key, defaultValue = "") {
  return computed({
    get() {
      return currentUser.value[key] ? currentUser.value[key] : defaultValue;
    },
    set(newValue) {
      currentUser.value[key] = newValue;
    },
  });
}

const currentUserName = createComputedProperty("name");
const currentUserOffice = createComputedProperty("office", "");
// const currentUserOfficeStatus = createComputedProperty(
//   "officeStatus",
//   "escritorio"
// );

const currentImage = computed(() => {
  return loginStore.profileImage;
});

const currentUserPhotoUrl = computed({
  get() {
    return currentUser.value.photourl;
  },
  set(newValue) {
    currentUser.value.photourl = newValue;
  },
});

const updateImage = (image) => {
  if (image === "emptyImage") {
    currentUserPhotoUrl.value = null;
    return;
  }
  currentUserPhotoUrl.value = image;
};

store.getUsers;
const users = computed(() => {
  return store.users.map((user) => ({
    id: user.id,
    name: user.name,
  }));
});

onMounted(async () => {
  await store.getUserById(route.params.id);
});
</script>
