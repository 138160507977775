import { defineStore } from 'pinia';

export const useGlobalComponents = defineStore('globalComponents', {
    state: () => ({
        confirmationDialog: {
            show: false,
            type: "delete", // Pode ser "action" ou "delete"
            title: "Confirmar ação",
            subtitle: "Você deseja confirmar a ação?",
            text: "A ação será confirmada.",
            actionFunction: "",
            closable: false,
            persistent: false,
        }
    }),
    actions: {
        setConfirmationDialog(setup) {
            this.confirmationDialog = {
                ...this.confirmationDialog,
                ...setup
            };
        },
    },
});