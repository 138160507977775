<template>
  <q-btn dense label="teste">
    <q-menu :max-width="maxWidth" :offset="offset">
      <div
        v-if="inputVisible"
        class="bg-white px-2 z-10 sticky top-0 flex items-center h-14"
      >
        <q-input
          v-model="inputValue"
          filled
          dense
          type="search"
          placeholder="Pesquisar"
          style="width: 100%"
          @update:model-value="handleSearch"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </div>

      <!-- ----------------------- SIMPLES -->
      <q-tree
        v-if="!multiple"
        :nodes="options"
        v-model:selected="selected"
        node-key="id"
        default-expand-all
        :filter="inputValue"
        class="bg-grey50"
      >
        <template v-slot:default-header="prop">
          <div class="row items-center w-full" @click="handleSelect(prop.node)">
            <div class="text-secondary">
              {{ prop.node.label }}
            </div>
          </div>
        </template>
      </q-tree>
      <!-- ----------------------- MULTISELECT -->
      <q-tree
        v-else
        :nodes="options"
        node-key="id"
        default-expand-all
        v-model:selected="selected"
        :filter="inputValue"
        class="bg-grey50"
      >
        <template v-slot:default-header="prop">
          <div
            class="row items-center space-x-2 w-full"
            @click="handleSelect(prop.node)"
          >
            <q-toggle
              v-if="prop.node.id !== 'root'"
              v-model="prop.node.selected"
              :val="prop.node.label"
              checked-icon="check"
              color="secondary"
              dense
            />
            <div class="text-primary">
              {{ prop.node.label }}
            </div>
          </div>
        </template>
      </q-tree>
    </q-menu>
  </q-btn>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed } from "vue";

const emits = defineEmits([
  "update:inputSearch",
  "update:modelValue",
  "search",
]);
const props = defineProps({
  options: Array,
  inputVisible: Boolean,
  inputSearch: String,
  multiple: Boolean,
  maxWidth: String,
  offset: Array,
  modelValue: {
    type: Array,
    default: () => [],
  },
});

const selected = ref(null);

const inputValue = computed({
  get() {
    return props.inputSearch;
  },
  set(value) {
    emits("update:inputSearch", value);
  },
});

const model = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emits("update:modelValue", newValue);
  },
});

const handleSelect = (item) => {
  if (props.multiple) {
    item.selected = !item.selected;
    let newItem = { ...item };
    if (newItem.children) delete newItem.children;

    if (item.selected) {
      model.value.push(newItem);
    } else {
      let index = model.value.findIndex((element) => element.id === newItem.id);
      if (index !== -1) {
        model.value.splice(index, 1);
      }
    }
  } else {
    if (model.value.length === 0) {
      model.value.push(item);
    } else {
      model.value[0] = item;
    }
  }
};

const handleSearch = (val) => {
  emits("search", val);
};
</script>
