import script from "./treeNodes.vue?vue&type=script&setup=true&lang=js"
export * from "./treeNodes.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTree from 'quasar/src/components/tree/QTree.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QMenu,QInput,QIcon,QTree,QToggle});
