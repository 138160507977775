import script from "./headerComponent.vue?vue&type=script&setup=true&lang=js"
export * from "./headerComponent.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QBreadcrumbs from 'quasar/src/components/breadcrumbs/QBreadcrumbs.js';
import QBreadcrumbsEl from 'quasar/src/components/breadcrumbs/QBreadcrumbsEl.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBreadcrumbs,QBreadcrumbsEl,QBtn});
