import script from "./profilePage.vue?vue&type=script&setup=true&lang=js"
export * from "./profilePage.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTabs,QTab,QTabPanels,QTabPanel});
