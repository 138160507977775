<template>
  <div class="space-y-3">
    <!-- <SelectTree
      label="HELP"
      v-model="selecteds"
      :options="options"
      :emitValue="true"
      :inputVisible="true"
    />
    <TreeNodes
      v-model="selecteds"
      v-model:inputSearch="search"
      :multiple="true"
      :options="simple"
      :inputVisible="true"
      :offset="[0, 10]"
      maxWidth="400px"
    />

    <p style="text-align: center; font-size: 16px" referenceId="1">Coluna 1</p>
    <p style="text-align: center; font-size: 16px" referenceId="2">Coluna 2</p>
    <p style="text-align: center; font-size: 16px" referenceId="3">Coluna 3</p>
    <q-btn
      flat
      dense
      label="atualizar valor"
      color="primary"
      @click="updateValue"
    /> -->

    <TableRowAndColSpan />
  </div>
</template>

<script setup>
import { ref } from "vue";
// eslint-disable-next-line
import SelectTree from "./SelectTree/selectTreeComponent.vue";
// eslint-disable-next-line
import TreeNodes from "./SelectTree_copy/treeNodes.vue";
import TableRowAndColSpan from "./tableRowAndColSpan.vue";

// import selectTreeQuasar from "./SelectTree_copy/selectTreeQuasar.vue";
// import TableForm from "./tableForm.vue";
// eslint-disable-next-line
const updateValue = () => {
  const paragrafos = document.querySelectorAll("p");
  paragrafos.forEach((paragrafo, index) => {
    if (paragrafo.hasAttribute("referenceid")) {
      console.log(paragrafo);
      // paragrafo.setAttribute("referenceid", "TEM");
      paragrafo.textContent = `MUDOU_${index}`;
    }
  });
};
// eslint-disable-next-line
const search = ref("");
// eslint-disable-next-line
const selecteds = ref(null);
// eslint-disable-next-line
const options = ref([
  {
    id: 1,
    title: "Entidade_1",
    type: "entity",
    selected: false,
    children: [
      {
        id: 2,
        title: "Prop_2",
        type: "props",
        selected: false,
        children: [
          {
            id: 3,
            title: "Subrop_3",
            type: "props",
            selected: false,
          },
        ],
      },
      {
        id: 4,
        title: "Prop_4",
        type: "props",
        selected: false,
      },
      {
        id: 5,
        title: "Prop_5",
        type: "props",
        selected: false,
      },
    ],
  },
  {
    id: 6,
    title: "Entidade_6",
    type: "entity",
    selected: false,
    children: [
      {
        id: 7,
        title: "Prop_7",
        type: "props",
        selected: false,
        children: [
          {
            id: 8,
            title: "Subrop_8",
            type: "props",
            selected: false,
            children: [
              {
                id: 9,
                title: "Subrop_9",
                type: "props",
                selected: false,
              },
            ],
          },
        ],
      },
      {
        id: 10,
        title: "Prop_10",
        type: "props",
        selected: false,
      },
    ],
  },

  {
    id: 11,
    title: "Entidade_11",
    type: "entity",
    selected: false,
    children: [],
  },
]);
// eslint-disable-next-line
const simple = ref([
  {
    id: "root",
    title: "Propriedades",
    label: "Propriedades",
    children: [
      {
        id: "BE9_PP116",
        title: "Atualizado Em",
        label: "Atualizado Em",
        type: "M",
        selected: false,
      },
      {
        id: "BE9_PP117",
        title: "Atualizado Por",
        label: "Atualizado Por",
        selected: false,
        type: "U",
      },
      {
        id: "BE9_PP118",
        title: "Criado Em",
        label: "Criado Em",
        selected: false,
        type: "M",
      },
      {
        id: "BE9_PP119",
        title: "Criado Por",
        label: "Criado Por",
        selected: false,
        type: "U",
      },
      {
        id: "BE9_PP120",
        title: "Id",
        label: "Id",
        selected: false,
        type: "N",
      },
      {
        id: "BE9_PP121",
        title: "Cooperativa",
        label: "Cooperativa",
        type: "E",
        selected: false,
        relatedBF: 1,
        children: [
          {
            id: "BE9_PP121_PP6",
            title: "Codigo",
            label: "Codigo",
            selected: false,
            type: "N",
          },
          {
            id: "BE9_PP121_PP2",
            title: "Atualizado Por",
            label: "Atualizado Por",
            selected: false,
            type: "U",
          },
          {
            id: "BE9_PP121_PP4",
            title: "Criado Por",
            label: "Criado Por",
            selected: false,
            type: "U",
          },
          {
            id: "BE9_PP121_PP370",
            title: "Mastersaf",
            label: "Mastersaf",
            selected: false,
            type: "T",
          },
          {
            id: "BE9_PP121_PP5",
            title: "Atualizado Em",
            label: "Atualizado Em",
            selected: false,
            type: "M",
          },
          {
            id: "BE9_PP121_PP3",
            title: "Criado Em",
            label: "Criado Em",
            selected: false,
            type: "M",
          },
          {
            id: "BE9_PP121_PP7",
            title: "Nome",
            label: "Nome",
            selected: false,
            type: "T",
          },
          {
            id: "BE9_PP121_PP1",
            title: "Id",
            label: "Id",
            selected: false,
            type: "N",
          },
          {
            id: "BE9_PP121_PP9628",
            title: "Centro de Lucro/Custo",
            label: "Centro de Lucro/Custo",
            selected: false,
            type: "T",
          },
        ],
      },
      {
        id: "BE9_PP122",
        title: "Nome",
        label: "Nome",
        selected: false,
        type: "T",
      },
      {
        id: "BE9_PP123",
        title: "Cod",
        label: "Cod",
        selected: false,
        type: "T",
      },
    ],
  },
]);
</script>
