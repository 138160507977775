<template>
  <div>
    <q-breadcrumbs
      active-color="grey"
      class="dark font-medium capitalize text-[1rem]"
    >
      <template v-slot:separator>
        <q-icon size="1em" name="chevron_right" color="lightGreen" class="mx-3" />
      </template>

      <template v-for="(item, index) in breadcrumbList" :key="index">
        <q-breadcrumbs-el
          v-if="item.name === 'Tarefas'"
          @click="$router.go(-1)"
          label="Atividades"
          class="cursor-pointer"
        />
        <q-breadcrumbs-el
          @click="routeTo(index)"
          :label="item.name"
          :class="{ 'cursor-pointer': index < breadcrumbList.length - 1 }"
        />
      </template>
    </q-breadcrumbs>
  </div>
</template>

<script>
export default {
  name: "breadcrumbComponent",
  data() {
    return {
      breadcrumbList: [],
    };
  },

  mounted() {
    this.updateList();
    // //// console.log(this.breadcrumbList);
  },
  watch: {
    $route() {
      this.updateList();
    },
  },
  methods: {
    routeTo(pRouteTo) {
      if (this.breadcrumbList[pRouteTo].link)
        this.$router.push(this.breadcrumbList[pRouteTo].link);
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
  },
};
</script>
