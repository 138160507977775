import axios from "axios";
import { useLoginStore } from "@/store/loginStore";
//update to qas

const URL_API_LOCAL = process.env.VUE_APP_API_LOCAL_URL;
console.log("TOKEN", localStorage.getItem("@vueweb:accessToken"));
// NEW VERSION CONTEXT EUROFARMA
const getContext = () => {
  let context = window.location.hostname;
  if (context.indexOf("localhost") >= 0) {
    if (URL_API_LOCAL) return URL_API_LOCAL + "/api";
    else
      return `https://${process.env.VUE_APP_TENANT_NAME}.eztools.${process.env.VUE_APP_TENANT_DOMAIN}/api`;
  } else {
    let newContext = context.includes("-new")
      ? context.replace("-new", "")
      : context;
    return `https://${newContext}/api`;
  }
};

let URL_CTX_DEV = getContext();

export const baseURL = URL_CTX_DEV;

// VERSÃO CORRETA
export const apiDev = axios.create({
  baseURL: URL_CTX_DEV,
  // headers: { Authorization: `Bearer ${token}` },
});

apiDev.interceptors.request.use((config) => {
  // Altera as chamadas Admin e handler, quando estiver rodando o back em localhost
  if (URL_API_LOCAL) {
    if (config.url.startsWith("/adm"))
      config.baseURL = "http://localhost:7001/api";
    if (config.url.startsWith("/handler"))
      config.baseURL = "http://localhost:7002/api";
  }
  try {
    const token = localStorage.getItem("@vueweb:accessToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}` || "";
    }
    return config;
  } catch (err) {
    console.log(err);
  }
});

apiDev.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      useLoginStore().showPopupLogin = true;
    }
    return Promise.reject(error);
  }
);

export const setAuthHeader = (tokenHeaders) => {
  if (tokenHeaders) {
    apiDev.defaults.headers.common["Authorization"] =
      `Bearer ${tokenHeaders}` || "";
  } else {
    delete apiDev.defaults.headers.Authorization;
  }
};
