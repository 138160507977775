import { defineStore } from "pinia";
import { apiDev } from "@/services/api";
import { useUsers } from "../../securityAndSettings/usersStore";
import { useRoute } from "vue-router";
import { useProjects } from "@/store/products/projects";

export const useProjectFilters = defineStore("projectFilters", {
  state() {
    const route = useRoute();
    const storeUsers = useUsers();
    const store = useProjects();
    return {
      route,
      store,
      storeUsers,
      usersList: [],
      tagList: [],
      tagDontExist: false,
      prioritiesList: [],
      tagSearch: "",
      allStatus: [],
      userSearch: "",
      colorsList: [
        "#38BDF8",
        "#0284C7",
        "#1066A9",
        "#8B5CF6",
        "#5b2c6f",
        "#F472B6",
        "#F87171",
        "#fb5812",
        "#F51739",
        "#9A7D0A",
        "#A04000",
        "#148f77",
        "#0B5345 ",
        "#94A3B8",
        "#334155",
        "#1F2937",
      ],
      statusList: [],
      statusColors: [
        {
          id: 7,
          color: "black",
          status: "validation",
        },
        {
          id: 2,
          color: "orange",
          status: "start",
        },
        {
          id: 3,
          color: "green",
          status: "progress",
        },
        {
          id: 4,
          color: "red",
          status: "blocked",
        },
        {
          id: 5,
          color: "grey",
          status: "concluded",
        },
        {
          id: 6,
          color: "black",
          status: "canceled",
        },
        {
          id: 7,
          color: "black",
          status: "backlog",
        },
      ],
      storiesSprintOptions: [],
      projectsSprintOptions: [],
    };
  },
  getters: {
    filterTag: (state) => {
      const filteredTags = state.tagList.filter((element) => {
        return element.title
          .toLowerCase()
          .includes(state.tagSearch.toLowerCase());
      });
      if (filteredTags.length === 0 && state.tagSearch.length > 0) {
        state.tagDontExist = true;
      }

      return filteredTags;
    },
    priorities: (state) => {
      return state.prioritiesList;
    },
    filterUser: (state) => {
      return state.usersList.filter((element) => {
        return element.name
          .toLowerCase()
          .includes(state.userSearch.toLowerCase());
      });
    },
  },
  actions: {
    async getTags() {
      try {
        const response = await apiDev.get("/handler/v2/agile/tags");
        const data = response.data.data;
        data.forEach((element) => {
          element["status"] = false;
        });
        // console.log("TAGLIST", this.tagList);
        this.tagList = data;
      } catch (error) {
        console.log("TAGS error: ", error);
        this.tagList = [];
      }
    },

    async getTagById(id) {
      const res = await apiDev
        .get(`/handler/v2/agile/tags/${id}`)
        .then((res) => res.data)
        .catch((err) => err);
      if (res.success) return res.data;
      else return { error: true };
    },
    async createTag(data) {
      // console.log("ENTRADA", data);
      if (this.tagDontExist) {
        try {
          //eslint-disable-next-line
          const response = await apiDev.post("/handler/v2/agile/tags", data);
          // console.log(response);
          this.tagList.unshift(data);
        } catch (error) {
          console.log("CREATE TAG error: ", error);
        }
      }
      this.tagDontExist = false;
    },
    async updatedTag(id, data) {
      try {
        const response = await apiDev.put(`/handler/v2/agile/tags/${id}`, data);
        if (response.data.success) {
          this.getTags();
          // if (!this.route.path.includes("tasks")) {
          //   this.store.getActivities(this.route.params.projectId);
          // } else {
          //   this.store.getTasks(this.route.params.activityId);
          // }
        }
      } catch (error) {
        console.log("TAG update error: ", error);
      }
    },

    async deleteTag(id) {
      console.log(id);
      try {
        const response = await apiDev.delete(`/handler/v2/agile/tags/${id}`);
        console.log(response);
        const index = this.tagList.findIndex((item) => item.id === id);
        if (index !== -1) {
          this.tagList.splice(index, 1);
        }
      } catch (error) {
        console.log("CREATE TAG error: ", error);
      }
    },

    async getPriorities() {
      try {
        const response = await apiDev.get("/handler/v2/agile/priorities");
        const data = response.data.data;

        data.forEach((element) => {
          element["status"] = false;
        });

        for (let priority of data) {
          if (priority.id === 1) {
            priority.icon = "fas fa-chevron-double-up";
            priority.color = "#ff0000";
          }
          else if (priority.id === 2) {
            priority.icon = "fas fa-chevron-up";
            priority.color = "#ff6c3d";
          }
          else if (priority.id === 3) {
            priority.icon = "fas fa-equals";
            priority.color = "#3dbbff";
          }
          else if (priority.id === 4) {
            priority.icon = "fas fa-chevron-down";
            priority.color = "#3e3e3e";
          }
        }

        this.prioritiesList = data;
      } catch (error) {
        console.log("PRIORITIES error: ", error);
        this.prioritiesList = [];
      }
    },


    getPrioritiesIcons(id) {
      if (id === 1) {
        return "fas fa-chevron-double-up";
      }
      else if (id === 2) {
        return "fas fa-chevron-up";
      }
      else if (id === 3) {
        return "fas fa-equals";
      }
      else if (id === 4) {
        return "fas fa-chevron-down";
      }
      else {
        return "fas fa-chevron-up"
      }
    },

    getPrioritiesColors(id) {
      if (id === 1) {
        return "#ff0000";
      }
      else if (id === 2) {
        return "#ff6c3d";
      }
      else if (id === 3) {
        return "#3dbbff";
      }
      else if (id === 4) {
        return "#3e3e3e";
      }
      else {
        return "#000000";
      }
    },

    async getPriorityById(id) {
      const res = await apiDev
        .get(`/handler/v2/agile/priorities/${id}`)
        .then((res) => res.data)
        .catch((err) => err);
      if (res.success) return res.data;
      else return { error: true };
    },
    async getUsers() {
      this.usersList = await this.storeUsers.getUsers;
    },
    getInitialLetters(username) {
      const names = username.split(" ");
      if (names.length >= 2)
        return names[0].split("")[0] + names[1].split("")[0];
      else return names[0].split("")[0];
    },
    getRandomColor() {
      if (!Array.isArray(this.colorsList) || this.colorsList.length === 0) {
        return null;
      }

      const randomIndex = Math.floor(Math.random() * this.colorsList.length);
      return this.colorsList[randomIndex];
    },
    async getStatus() {
      const res = await apiDev
        .get(`/handler/v2/agile/statusLanes`)
        .then((res) => res.data)
        .catch((error) => {
          console.log("Erro chamada status: ", error);
          return error;
        });

      if (res.success) {
        this.statusList = res.data.map((status) => ({
          ...status,
          name: status.title,
        }));
      }
    },
    //eslint-disable-next-line
    async getProjectsAndStoriesForFilter(boardId) {
      console.log(boardId);
      const res = await apiDev
        .get(
          "/handler/v2/agile/boards/stories?q_boardId=" +
          boardId +
          "&groupBy=storyId"
        )
        .then((res) => res.data)
        .catch((error) => {
          console.log("Erro chamada status: ", error);
          return error;
        });

      console.log(res);
      if (res.success) {
        if (res.data) {
          for (let lane of res.data) {
            if (lane.items) {
              for (let story of lane.items) {
                if (!this.storiesSprintOptions.some((st) => st.id === story.id))
                  this.storiesSprintOptions.push({
                    id: story.id,
                    title: story.title,
                    status: false,
                  });
                if (
                  !this.projectsSprintOptions.some(
                    (project) => project.id === story.project.id
                  )
                )
                  this.projectsSprintOptions.push({
                    id: story.project.id,
                    title: story.project.title,
                    status: false,
                  });
              }
            }
          }
        }
      }
    },
    async getAllStatus() {
      const res = await apiDev
        .get(`/handler/v2/agile/status`)
        .then((res) => res.data)
        .catch((error) => {
          console.log("Erro chamada status: ", error);
          return error;
        });

      // console.log(res);

      if (res.success) {
        this.allStatus = res.data;
      }
    },
  },
});
