"use strict";

export function leftPad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}

export function round(num, decimal) {
    return (Math.round(num * Math.pow(10, decimal)) / Math.pow(10, decimal))
}
