<template>
  <q-dialog
    v-model="store.showPopupLogin"
    full-width
    class="dialog"
    persistent
    rounded
  >
    <div class="bg-grey100 z-20 shadow-lg">
      <!-- CORPO DO MODAL -->
      <div class="overflow-auto w-full pb-3 px-5 bg-grey100">
        <header>
          <div class="flex flex-col items-center">
            <q-img
              :src="homeConfig.imageLogin"
              spinner-color="grey-5"
              fit="contain"
              style="width: 20%; height: 96px; max-height: 96px; margin: 16px 0"
            />

            <h2 class="text-sm font-bold uppercase text-grey900">
              Login Expirado
            </h2>
            <p class="text-sm mb-3">
              <span>Faça o Login para continuar usando a aplicação.</span>
            </p>
          </div>
        </header>
        <main class="mb-3">
          <div class="space-y-3">
            <InputQuasarComponent
              label="Usuário"
              v-model="dataLogin.username"
              icon="person"
            />
            <InputQuasarComponent
              label="Senha"
              v-model="dataLogin.password"
              icon="password"
              type="password"
              @keyup.enter="postUser"
            />
            <q-btn
              @click="postUser"
              dense
              color="primary"
              :label="$t('login.buttonEnter')"
              style="width: 100%"
            />
          </div>
        </main>
      </div>
    </div>
  </q-dialog>
</template>

<script setup>
import { ref } from "vue";
import { useLoginStore } from "@/store/loginStore";
import { useHomeConfig } from "../../store/homeConfigStore.js";
import InputQuasarComponent from "../../../../global/componentsVue/inputComponent/inputQuasarComponent.vue";

const homeConfig = useHomeConfig();
const store = useLoginStore();

const dataLogin = ref({
  username: "",
  password: "",
});

const postUser = () => {
  //// console.log("TESTE");
  store.loginPopup(dataLogin.value);
};
</script>

<style scoped>
.dialog .q-dialog__inner--fullwidth > div {
  width: 425px !important;
  max-width: 425px !important;
  min-width: 425px !important;
}
</style>
