<template>
  <div class="w-[100vw] h-[100vh] flex flex-col items-center justify-center relative">
    <div class="absolute top-0 left-0 m-[40px] max-w-[300px]">
      <img class="w-full max-h-[110px]" :src="logo" />
    </div>
    <div id="login-table" class="z-50 max-w-[450px] w-full">
      <div class="bg-white">
        <div id="title" class="text-center">
          <p class="text-[40px] font-bold mb-[5px]">
            {{ $t("login.welcome") }}
          </p>
          <p class="text-[1.375rem] text-grey">
            {{ $t("login.loginToContinue") }}
          </p>
        </div>
        <div id="inputs" class="mt-[50px]">
          <div class="email">
            <p class="text-[1.375rem]">{{ $t("login.typeUser") }}</p>
            <input
              v-model="dataLogin.username"
              class="h-[56px] w-full border border-[#D2D2D2] p-3 pl-[20px] rounded-[10px]"
              type="email"
              :placeholder="$t('login.user')"
            />
          </div>
          <div class="password mt-[40px]">
            <p class="text-[1.375rem]">{{ $t("login.typePassword") }}</p>
            <input
              v-model="dataLogin.password"
              class="h-[56px] w-full border border-[#D2D2D2] p-3 pl-[20px] rounded-[10px]"
              type="password"
              :placeholder="$t('login.password')"
            />
          </div>
        </div>
        <div
          id="buttons"
          class="mt-[35px] flex flex-col items-center justify-center"
        >
          <q-btn
            unelevated
            rounded
            no-caps
            @click="postUser"
            :loading="store.loading"
            :style="`background: ${config?.actionColor}`"
            :class="`hover:brightness-110 transition-all text-[1.375rem] text-white max-w-[240px] w-full h-[56px]`"
            >{{ $t("login.buttonEnter") }}
          </q-btn>
          <p class="mt-[35px] text-[0.75rem]">
            {{ $t("login.dontKnowUs") }}
            <span :style="`color: ${config?.actionColor}`" :class="`cursor-pointer underline`">{{
              $t("login.accessOurWebsite")
            }}</span>
          </p>
          <div class="mt-[25px]">
            <q-btn color="grey" class="mr-[10px]" outline rounded no-caps
              >Learning
              <q-icon size="xs" class="ml-[10px]" name="open_in_new" />
            </q-btn>
            <q-btn color="grey" outline rounded no-caps
              >{{ languages[language]?.label }}
              <q-img
                class="ml-[10px]"
                width="32px"
                height="32px"
                :src="languages[language]?.flag"
              ></q-img>
              <q-menu v-model="showLanguageMenu" class="rounded-[10px]">
                <q-list style="min-width: 270px">
                  <q-item
                    clickable
                    class="flex items-center"
                    v-for="language of languages"
                    :key="language.key"
                    @click="selectLanguage(language.key)"
                  >
                    <q-item-section>{{ language.label }}</q-item-section>
                    <q-img
                      width="32px"
                      height="32px"
                      :src="language.flag"
                    ></q-img>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>
          </div>
        </div>
      </div>
    </div>
    <div id="backgrounds">
      <div
        class="w-[25%] h-[60%] rounded-tr-full absolute bottom-0 left-0"
        :style="`background: ${config.backgroundColor}`"
      ></div>
      <div
        class="w-[25%] h-[60%] rounded-bl-full absolute top-0 right-0"
        :style="`background: ${config.backgroundColor}`"
      ></div>
    </div>
  </div>
</template>

<script>
import brFlag from "@/assets/images/ptbr_flag.png";
import esFlag from "@/assets/images/es_flag.png";
import enFlag from "@/assets/images/en_flag.png";
import { useLoginStore } from "@/store/loginStore";

export default {
  props: {
    config: { type: Object, default: () => {} },
    language: { type: String, default: "pt" },
    logo: {}
  },
  data() {
    const store = useLoginStore();
    return {
      store,
      dataLogin: {
        username: "",
        password: "",
      },
      brFlag,
      enFlag,
      esFlag,
      showLanguageMenu: false,
      languages: {
        pt: {
          flag: brFlag,
          key: "pt",
          label: "Português",
        },
        en: {
          flag: enFlag,
          key: "en",
          label: "English",
        },
        es: {
          flag: esFlag,
          key: "es",
          label: "Español",
        },
      },
    };
  },
  methods: {
    selectLanguage(language) {
      this.$emit("selectLanguage", language);
      this.showLanguageMenu = false;
    },
    async postUser() {
      await this.store.loginUser(this.dataLogin);
    },
  },
};
</script>

<style>
</style>