<template>
  <div class="w-85 h-full rounded">
    <div
      :class="`border border-grey200 flex justify-${
        expandEmail ? 'start' : 'end'
      } items-center h-10 rounded-t rounder-l px-2`"
    >
      <div
        class="w-full flex justify-between items-center h-full"
        v-if="expandEmail"
      >
        <div class="space-x-2">
          <q-btn
            dense
            color="secondary"
            flat
            icon="arrow_back"
            size="sm"
            @click="changeExpandEmail(null)"
          />
          <q-btn flat dense color="dark" icon="inventory_2" size="sm" disable />
          <q-btn flat dense color="dark" icon="inbox" size="sm" disable />
        </div>

        <div>
          <q-btn
            flat
            dense
            color="dark"
            icon="chevron_left"
            size="sm"
            disable
          />
          <q-btn
            flat
            dense
            color="dark"
            icon="chevron_right"
            size="sm"
            disable
          />
        </div>
        <q-btn
          flat
          dense
          color="dark"
          icon="fal fa-ellipsis-h"
          size="sm"
          disable
        />
      </div>

      <div v-else class="flex w-full justify-between items-center">
        <span class="bg-red px-2 py-1 rounded text-xs text-white"
          >20 emails não lidos</span
        >
        <div class="space-x-1">
          <q-btn
            flat
            dense
            size="xs"
            @click="previousPage"
            icon="chevron_left"
            color="primary"
          />
          <span class="text-xs text-dark"
            >{{ store.currentRow }}-{{ store.ultimateRow }} de
            {{ store.totalRows }}</span
          >
          <q-btn
            flat
            dense
            size="xs"
            @click="nextPage"
            icon="chevron_right"
            color="primary"
          />
        </div>
      </div>
    </div>
    <div class="pt-2 h-full">
      <div
        v-if="!expandEmail"
        class="overflow-y-auto"
        style="max-height: 95%"
        id="ContainerCards"
      >
        <div
          v-for="(item, index) in store.mail"
          :key="index.id"
          class="flex justify-between items-center px-1 rounded w-full h-[40px] text-sm odd:bg-grey100 even:bg-white hover:bg-grey200"
        >
          <div class="flex justify-around items-center space-x-3">
            <q-btn flat dense size="xs" icon="bookmark" color="primary" />
            <img
              class="rounded-full w-[30px] h-[30px]"
              src="https://img.freepik.com/fotos-gratis/mulher-asiatica-posando-e-olhando-para-a-camera_23-2148255359.jpg?w=826&t=st=1665073641~exp=1665074241~hmac=0d7ce0327ea75b3de0948f7e7f4f39f68d64400cafbb8dffc278cd8480bd0a35"
              alt=""
            />
            <p @click="changeExpandEmail(item)" class="cursor-pointer text-sm">
              {{ item.subject }}
            </p>
          </div>
          <!-- <div>
          
          </div> -->
          <div
            class="flex justify-around items-center w-[120px] space-x-2 pr-3 text-sm"
          >
            <p>
              {{ getData(item.mailRecips[0].sentOn) }}
            </p>
            <button v-show="item.mailRecips.length > 0">
              <q-icon name="calendar_today" size="xs" />
            </button>
          </div>
        </div>
      </div>
      <div v-else class="h-[calc(100%_-_40px)]">
        <ContentEmailComponent :header="false" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import ContentEmailComponent from "./contentEmailComponent.vue";
import { useMailStore } from "@/store/products/mailStore";

const store = useMailStore();

const nextPage = () => {
  store.countPage += 1;
  store.currentRow += store.currentRow < 19 ? 19 : 20;
  store.ultimateRow += 20;
  store.getMail();
};
const previousPage = () => {
  if (store.countPage === 1) {
    return;
  }
  store.countPage -= 1;
  store.currentRow -= store.currentRow === 20 ? 19 : 20;
  store.ultimateRow -= 20;
  store.getMail();
};

const getData = (data) => {
  //// console.log(data);
  const date = new Date(data);
  function formatDoubleDate(dt) {
    return dt <= 9 && dt > 0 ? "0" + dt : dt;
  }
  let dateFormated = data
    ? formatDoubleDate(date.getDate()) +
      "/" +
      formatDoubleDate(date.getMonth() + 1) +
      "/" +
      date.getFullYear()
    : "";
  return data !== {} ? dateFormated : "";
};

const expandEmail = ref(false);
//eslint-disable-next-line
function changeExpandEmail(item) {
  // //// console.log(index);
  expandEmail.value = !expandEmail.value;
  store.currentItem = item;
}
</script>

<style lang="scss" scoped></style>
