export const TablesPT = {
  //BUTTON
  buttonPublish: "Publicar",
  buttonSave: "Salvar",
  buttonUser: "Usuário",

  //INPUT
  inputName: "Nome",
  inputEmail: "Email",
  inputLogin: "Login",
  inputPassword: "Senha",
  inputPasswordConfirm: "Confirme a Senha",
  //SELECT
  selectManager: "Gerente",
  selectOrganizationalUnit: "Unidade Organizacional",
  selectAuthenticationType: "Tipo de Autênticação",
  //TITLE MAINMENU
  userTitle: "Usuários",
  accessGroupTitle: "Grupo de Acesso",
  userPersonalData: "Dados Pessoais",
  userAccessData: "Dados de Acesso",
  information: "Informações",
  header: "Cabeçalho",
  dataSource: "Fonte de Dados",
  columns: "Colunas",
  settings: "Configurações",
  notificationRequiredFields: "Os campos marcado com ** são obrigatórios",
  dropdownOptionsDuplicate: "Duplicar",
  dropdownOptionsDeactivate: "Desativar",
  dropdownOptionsDelete: "Deletar",

  isActive: "Ativo",
  userAndPass: "Usuário e Senha",
};

export const TablesEN = {
  //BUTTON
  buttonPublish: "Publish",
  buttonSave: "Save",
  buttonUser: "User",
  //INPUT
  inputName: "Name",
  inputEmail: "Email",
  inputLogin: "Login",
  inputPassword: "Password",
  inputPasswordConfirm: "Confirm the password",
  //SELECT
  selectManager: "Manager",
  selectOrganizationalUnit: "Organizational Unit",
  selectAuthenticationType: "Authentication Type",
  //TITLE E MAINMENU
  userTitle: "Users",
  accessGroupTitle: "Access Group ",
  userPersonalData: "Personal Data",
  userAccessData: "Access Data",
  information: "Information",
  header: "Header",
  dataSource: "Data Source",
  columns: "Columns",
  settings: "Settings",
  notificationRequiredFields: "Fields marked with ** are required",
  dropdownOptionsDuplicate: "Duplicate",
  dropdownOptionsDeactivate: "Deactivate",
  dropdownOptionsDelete: "Delete",
  isActive: "Active",
  userAndPass: "User and Password",
};

export const TablesES = {
  //BUTTON
  buttonPublish: "Publicar",
  buttonSave: "Ahorrar",
  buttonUser: "Usuario",
  //INPUT
  inputName: "Nombre",
  inputEmail: "Email",
  inputLogin: "Login",
  inputPassword: "Contraseña",
  inputPasswordConfirm: "Confirme la contraseña",
  //SELECT
  selectManager: "Gerente",
  selectOrganizationalUnit: "Unidad de Organización",
  selectAuthenticationType: "Tipo de Autenticación",
  //TITLE E MAINMENU
  userTitle: "Usuarios",
  accessGroupTitle: "Grupo de Acceso",
  userPersonalData: "Datos Personales",
  userAccessData: "Datos de Acesso",
  information: "Información",
  header: "Cabezazo",
  dataSource: "Fuente de Dados",
  columns: "Columnas",
  settings: "Ajustes",
  notificationRequiredFields: "Campos marcados con ** son obligatorio",
  dropdownOptionsDuplicate: "Duplicar",
  dropdownOptionsDeactivate: "Desactivar",
  dropdownOptionsDelete: "Eliminar",
  isActive: "Ativo",
  userAndPass: "Nombre de usuario y contraseña",
};
