import { apiDev } from "@/services/api";
import moment from "moment";
export default {
  /********************* TAREFAS ****************************/
  /**
   * Traz as tarefas em projetos
   * @param {Number} activityId Id da atividade
   */
  async getTasks(activityId, board = false) {
    this.tasksLoading = true;

    let query = "";
    if (activityId !== "all") query = "?q_storyId=" + activityId;

    if (this.mountQuery() !== null) {
      query =
        query.length === 0
          ? query + "?filters=" + this.mountQuery()
          : query + "&filters=" + this.mountQuery();
    }

    this.currentActivity["lanes"] = board
      ? await this.getBoardLanesPerGroupBy(query)
      : await this.getLanesPerGroupBy(query, board);

    query =
      query.length === 0
        ? query + "?q_groupBy=" + this.groupBy
        : query + "&q_groupBy=" + this.groupBy;

    //Busca as atividades do projeto
    const resData = await this.getApi("get", "/handler/v2/agile/tasks" + query);
    const newdata = await this.addTagsAndUsersInTasks(resData.data);
    this.kanbanAnListMount("currentActivity", newdata);
    this.mountDataToGantt("currentActivity");

    this.tasksLoading = false;
  },

  async getTasksBoard(
    boardId,
    sprintId,
    listOptions = false,
    deleteStatusId = false
  ) {
    if (
      sprintId !== "inative" &&
      this.currentBoard.activeSprintId == sprintId
    ) {
      let query = "?q_boardId=" + boardId;

      if (
        this.boardFilters.where &&
        this.boardFilters.where.statusId &&
        deleteStatusId
      ) {
        delete this.boardFilters.where.statusId;
      }
      if (!this.boardFilters.where) {
        this.boardFilters["where"] = {
          taskSprintId: parseInt(sprintId),
        };
      } else {
        this.boardFilters.where["taskSprintId"] = sprintId;
      }

      console.log(this.boardFilters);

      query = query + "&filters=" + this.mountQueryBoards();

      query = query + "&groupBy=storyId"; // verificar esse agrupador

      const res = await this.getApi(
        "get",
        "/handler/v2/agile/boards/tasks" + query
      );
      if (listOptions) {
        return res;
      }
      const data = await this.addTagsAndUsersInTasks(res.data);
      this.boardKanbanAnListMount("currentBoard", data);
      this.boardLanesTasksDefault = [...this.currentBoard.lanes];
    }
  },

  /**
   * Atualiza a posição do card
   * @param {object} objMoved
   */
  async updateLaneOfTask({ laneId, cardMoved, isOtherLane }) {
    console.log({ laneId, cardMoved, isOtherLane });
    let result;

    const newcard = await this.getTaskById(cardMoved.id);
    if (newcard.tagsObj) newcard.tags = newcard.tagsObj;
    if (newcard.tagsObj) newcard.users = newcard.usersObj;
    // Atualiza no banco de dados
    if (isOtherLane) {
      //eslint-disable-next-line
      result = await this.getApi(
        "put",
        `/handler/v2/agile/tasks/${cardMoved.id}`,
        {
          [this.groupBy]: laneId,
          updatedAt: moment().format("YYYY-MM-DDTHH:MM:mm"),
          updatedById: this.storeLogin.userId,
        }
      );

      if (result.success && result.data.updated > 0) {
        return { error: false };
      } else {
        // console.log("Error move card: ", result);
        return { error: true };
      }
    }
    return { error: false };
  },
  async getTaskById(id) {
    const res = await this.getApi("get", "/handler/v2/agile/tasks/" + id);
    return res;
  },

  /**Deleta a tarefa
   *
   * @param {Number} taskId
   */
  async deleteTask(taskId) {
    await apiDev
      .delete(`/handler/v2/agile/tasks/${taskId}`)
      .then((response) => {
        console.log(response);
        this.getTasks(this.route.params.activityId);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  /**
   * Move a tarefa de container
   * @param laneId ID do container(lane)
   * @param task A tarefa que sera inserida
   * @param addedIndex ID do card que sera inserido
   * @param isOtherLane Boolen, só sera inserido se for TRUE - container diferente do atual
   */
  //eslint-disable-next-line
  async addTaskLane(laneId, task, addedIndex, isOtherLane) {
    let result;
    // Atualiza no banco de dados
    if (isOtherLane)
      //eslint-disable-next-line
      result = await this.getApi(
        "put",
        `/handler/v2/agile/lanes/${laneId}/tasks?taskId=${task.id}`
      );

    if (Array.isArray(result) && result[0] > 0) {
      // Atualiza no card
      this.currentActivity.lanes = this.currentActivity.lanes.map((lane) => {
        if (lane.id === laneId) {
          lane.tasks.splice(addedIndex, 0, task);
        }
        return lane;
      });
    } else {
      // console.log("Error move card: ", result);
    }
  },
  /**
   * Cria uma tarefa
   * @param {number, string} projectId
   * @param {number, string} laneId
   * @param {object} card
   * @returns
   */
  //eslint-disable-next-line
  async insertTask(laneId, card) {
    // // console.log(this.currentActivity);
    const statusId =
      this.groupBy !== "statusId" ? this.storeFilters.statusList[0].id : null;
    return await this.insertCard(
      "currentActivity",
      {
        projectId: this.currentActivity.projectId,
        storyId: this.currentActivity.id,
      },
      "tasks",
      "taskId",
      laneId,
      card,
      statusId
    );
  },

  async insertTaskBacklogOrSprint(
    laneId,
    projectId,
    storyId,
    card,
    containerType
  ) {
    const statusId =
      this.groupBy !== "statusId" ? this.storeFilters.statusList[0].id : null;
    return await this.insertCard(
      "currentActivity",
      {
        projectId: projectId,
        storyId: storyId,
      },
      "tasks",
      "taskId",
      laneId,
      card,
      statusId,
      containerType
    );
  },

  /**
   * Insere uma tarefa na atividade
   * @param {Number} projectId
   * @param {Number} storyId
   * @param {Object} card
   * @returns
   */
  //eslint-disable-next-line
  async insertTaskOfActivity(projectId, storyId, card) {
    const statusId = this.storeFilters.statusList[0].id;
    return await this.insertCard(
      "currentProject",
      {
        projectId: projectId,
        storyId: storyId,
      },
      "tasks",
      "taskId",
      storyId,
      card,
      statusId
    );
  },

  /**
   * Atualiza a tarefa
   * @param {Number} id
   * @param {Object} card
   * @returns
   */
  //eslint-disable-next-line
  async updateTaskCard(id, card) {
    const res = await this.updateCard("tasks", id, card);
    return res;
  },

  /**
   * Move a tarefa entre backlog e sprint
   * @param {Object} param0
   * @returns
   */

  async updateLaneOfTaskBoard({ cardMoved, lane }, sprintId) {
    console.log({ cardMoved, lane });
    const statusTitle = lane === "sprint" ? "start" : "backlog";
    const status = this.storeFilters.statusList.find(
      (st) => st.title === statusTitle
    );

    // console.log(statusTitle);
    // console.log(lane);
    const spId = lane === "sprint" ? sprintId : null;

    // console.log(sprintId);
    // console.log(cardMoved.id);

    const res = await this.updateCard("tasks", cardMoved.id, {
      taskSprintId: spId,
      statusId: status.id,
    });
    if (res.success) {
      const card = await this.getTaskById(cardMoved.id);
      if (card.success) {
        return card.data;
      }
      return res;
    }
    return res;
  },
};
