import { defineStore } from "pinia";
import { apiDev, baseURL } from "../services/api";
import setCssVar from 'quasar/src/utils/set-css-var.js';
import logoImage from "@/assets/images/logoPurple.png";
import logoName from "@/assets/images/default-logo-name.png";
import logoIcon from "@/assets/images/default-icon-logo.png";
import { showPositiveNotify, showNegativeNotify } from "@/utils/useNotify";
import axios from "axios"
import i18next from "i18next";


export const useHomeConfig = defineStore("homeConfig", {
  state: () => ({
    hostname: location.hostname,
    href: location.href.replace("/login", ""),
    buttonStatus: false,
    images: [],
    config: [],
    imageLogin: "",
    imageIconMenu: "",
    imageNameMenu: "",
    homeLoading: true,
    homeUserLoading: false,
    loading: false,
    menuColor: "#1A2B3B",
    actionColor: "#26BCB3",
    secondaryColor: "#4F3277",
    backupColors: {},
    backupLayout: {},
    hasSavedConfig: true,
    loginLayout: {
      selectedLayout: 2,
      backgroundColor: "linear-gradient(to top right, #a843b8 0%, #7116c9 100%);",
      actionColor: "#A843B8",
      coverImage: "",
      coverText: "Make it easy."
    },
    languageSelected: ["pt", "es", "en"].includes(localStorage.getItem('i18nextLng'))
      ? localStorage.getItem('i18nextLng')
      : "pt",
  }),

  actions: {

    setColors(colorsCustom = null) {
      if (colorsCustom) {
        this.menuColor = colorsCustom.menuColor;
        this.actionColor = colorsCustom.actionColor;
        this.secondaryColor = colorsCustom.secondaryColor;
        setCssVar('primary', colorsCustom.actionColor)
        setCssVar('secondary', colorsCustom.secondaryColor)
        setCssVar('dark', colorsCustom.menuColor)
      } else {
        this.hasSavedConfig = false;
        setCssVar('primary', this.actionColor)
        setCssVar('secondary', this.secondaryColor)
        setCssVar('dark', this.menuColor)
      }
    },

    setLoginColors(colors) {
        this.loginLayout.backgroundColor = colors.backgroundColor;
        this.loginLayout.actionColor = colors.actionColor;
        this.hasSavedConfig = false;
    },

    resetColors() {
      console.log("ENTREI NO RESET")
      this.setColors(this.backupColors)
      this.loginLayout = this.backupLayout

      console.log(this.backupLayout)
    },

    async saveConfigs(data) {
      try {
        const colors = {
          menuColor: this.menuColor,
          actionColor: this.actionColor,
          secondaryColor: this.secondaryColor,
        };
        console.log("FormData conteúdo:");
        for (const pair of data.entries()) {
          console.log(pair[0], pair[1]);
        }
        data.append('colors', JSON.stringify(colors));
        data.append('loginLayout', JSON.stringify(this.loginLayout));

        this.homeLoading = true;
        const token = localStorage.getItem("@vueweb:accessToken");
        const response = await axios.post(`${baseURL}/adm/v2/config/appearance`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${token}`,
          },
        });

        if (response.data.data.LAYOUT_HOME_LOGO_LG) {
          this.imageNameMenu = 'data:image/png;base64,' + response.data.data.LAYOUT_HOME_LOGO_LG
        }
        if (response.data.data.LAYOUT_HOME_LOGO_XS) {
          this.imageIconMenu = 'data:image/png;base64,' + response.data.data.LAYOUT_HOME_LOGO_XS
        }
        if (response.data.data.LAYOUT_LOGIN_LOGO) {
          this.imageLogin = 'data:image/png;base64,' + response.data.data.LAYOUT_LOGIN_LOGO
        }

        this.homeLoading = false;
        this.hasSavedConfig = true;
        showPositiveNotify(i18next.t("settings.successSaveConfig"));
      } catch (error) {
        console.error("Erro ao salvar imagens:", error);
        this.homeLoading = false;
        showNegativeNotify(i18next.t("settings.errorSaveConfig"));
      }
    },

    async getImages() {
      this.loading = true;
      try {
        const token = localStorage.getItem("@vueweb:accessToken");
        const response = await axios.get(`${baseURL}/adm/v2/config/appearance`, {
          headers: {
            "Authorization": `Bearer ${token}`,
          },
        })
        let data = response.data
        if (data?.data?.LAYOUT_HOME_LOGO_LG) {
          this.imageNameMenu = 'data:image/png;base64,' + data.data.LAYOUT_HOME_LOGO_LG
        }
        if (data?.data?.LAYOUT_HOME_LOGO_XS) {
          this.imageIconMenu = 'data:image/png;base64,' + data.data.LAYOUT_HOME_LOGO_XS
        }
        if (data?.data?.LAYOUT_LOGIN_LOGO) {
          this.imageLogin = 'data:image/png;base64,' + data.data.LAYOUT_LOGIN_LOGO
        }

        this.loading = false;
      } catch (error) {
        console.error("Erro ao pegar imagens:", error);
        this.loading = false;
      }
    },

    async getLoginHome() {
      this.homeLoading = true;
      await apiDev.get("/adm/v2/pub/home").then((response) => {
        let data = response.data.data;

        let loginLayout = {
          selectedLayout: data?.loginLayout?.selectedLayout || this.loginLayout.selectedLayout,
          backgroundColor: data?.loginLayout?.backgroundColor || this.loginLayout.backgroundColor,
          actionColor: data?.loginLayout?.actionColor || this.loginLayout.actionColor,
          coverImage: data?.loginLayout?.coverImage || this.loginLayout.coverImage,
          coverText: data?.loginLayout?.coverText || this.loginLayout.coverText,
        };

        this.backupLayout = { ...loginLayout };
        this.loginLayout = loginLayout

        this.imageLogin = data?.LAYOUT_LOGIN_LOGO
          ? 'data:image/png;base64,' + data.LAYOUT_LOGIN_LOGO
          : logoImage;

      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.homeLoading = false;
      });
    },

    async getUserHome() {
      this.homeUserLoading = true;
      await apiDev.get("/adm/v2/user/home").then((response) => {
        let data = response.data.data;

        let colors = {
          menuColor: data?.colors?.menuColor ? data.colors?.menuColor : "#1A2B3B",
          actionColor: data?.colors?.actionColor ? data.colors?.actionColor : "#26BCB3",
          secondaryColor: data?.colors?.secondaryColor ? data.colors?.secondaryColor : "#4F3277",
        }

        this.backupColors = colors;
        this.setColors(colors);
        this.hasSavedConfig = true;

        this.imageIconMenu = data?.LAYOUT_HOME_LOGO_XS
          ? 'data:image/png;base64,' + data.LAYOUT_HOME_LOGO_XS
          : logoIcon;

        this.imageNameMenu = data?.LAYOUT_HOME_LOGO_LG
          ? 'data:image/png;base64,' + data.LAYOUT_HOME_LOGO_LG
          : logoName;

      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.homeUserLoading = false;
      });
    },
  },
});
