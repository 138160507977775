<template>
  <div class="w-[100%] rounded border border-grey200 h-full">
    <!-- Header vizualização do email -->
    <div
      v-if="header"
      class="flex h-[40px] px-4 w-full justify-between items-center rounded-t border-b border-grey200"
    >
      <div class="flex justify-center items-center space-x-3">
        <q-btn flat dense color="dark" icon="inventory_2" size="sm" disable />
        <q-btn flat dense color="dark" icon="inbox" size="sm" disable />
      </div>
      <div class="flex justify-center items-center">
        <q-btn flat dense color="dark" icon="chevron_left" size="sm" disable />
        <q-btn flat dense color="dark" icon="chevron_right" size="sm" disable />
      </div>
      <div class="flex justify-center items-center">
        <q-btn
          flat
          dense
          color="dark"
          icon="fal fa-ellipsis-h"
          size="sm"
          disable
        />
      </div>
    </div>
    <!-- CAIXA DO EMAIL -->

    <div
      v-if="mailBoxEmpty"
      class="flex w-full justify-between py-3 px-7 shadow-lg"
    >
      <div class="flex">
        <div class="w-[4rem] pr-2 cursor-pointer">
          <img
            class="rounded-full w-[6rem] h-[3.5rem]"
            src="https://img.freepik.com/fotos-gratis/mulher-asiatica-posando-e-olhando-para-a-camera_23-2148255359.jpg?w=826&t=st=1665073641~exp=1665074241~hmac=0d7ce0327ea75b3de0948f7e7f4f39f68d64400cafbb8dffc278cd8480bd0a35"
            alt=""
          />
        </div>
        <div class="flex flex-col justify-center">
          <div class="flex items-center flex-row space-x-2">
            <p class="text-sm">Nome Sobrenome</p>
            <q-icon name="email" size="xs" />
          </div>
          <p class="text-sm text-blue">{{ store.currentItem.subject }}</p>
        </div>
      </div>

      <div class="flex flex-col justify-center items-end text-center">
        <p
          v-for="(item, index) in store.currentItem.mailRecips"
          :key="index.id"
          class="text-sm"
        >
          {{ item.address }}
        </p>
        <span in class="text-xs text-grey500">{{
          getData(store.currentItem.mailRecips[0].sentOn)
        }}</span>
      </div>
    </div>
    <div v-else class="flex item-center justify-center h-full">
      <!-- MAIL BOX EMPTY -->
      <figure class="w-full h-full flex flex-col items-center justify-center">
        <q-img
          src="https://cdni.iconscout.com/illustration/premium/thumb/lost-in-space-3428213-2902555.png"
          spinner-color="white"
          style="height: 200px; max-width: 200px"
        />
        <blockquote
          class="text-2xl font-semibold italic text-center text-slate-900"
        >
          Não há nada aqui. Selecione um item para visualizar.
        </blockquote>
      </figure>
    </div>
    <div class="w-full text-sm mt-4 h-[calc(100%_-_136px)]">
      <!-- ASSUNTO DO EMAIL -->
      <div v-html="store.currentItem?.body" class="px-4"></div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, computed } from "vue";
import { useMailStore } from "@/store/products/mailStore";

const store = useMailStore();

const props = defineProps({
  header: {
    type: Boolean,
    default: true,
  },
});

const getData = (data) => {
  //// console.log(data);
  const date = new Date(data);
  function formatDoubleDate(dt) {
    return dt <= 9 && dt > 0 ? "0" + dt : dt;
  }
  let dateFormated = data
    ? formatDoubleDate(date.getDate()) +
      "/" +
      formatDoubleDate(date.getMonth() + 1) +
      "/" +
      date.getFullYear()
    : "";
  return data !== {} ? dateFormated : "";
};

// const mailBoxEmpty = null

const mailBoxEmpty = computed(() => {
  if (store.currentItem === null) {
    return false;
  }
  return Object.keys(store.currentItem)?.length === 0 ? false : true;
});

const header = ref(props.header);
</script>
