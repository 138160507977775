export const WidgetsPT = {
    icon:'icone',
    button:'botão',
    property:'propriedade',
    new_column:'Nova Coluna',
    choose_property:'Escolha a propriedade',
    ColumnCreatedWithSuccess:'Coluna criada com sucesso!'
  };
  
  export const WidgetsEN = {
    icon:'icon',
    button:'button',
    property:'property',
    new_column:'Nueva Columna',
    choose_property:'Elige la propriedad',
    ColumnCreatedWithSuccess:'¡Columna creada con éxito!'
  };
  
  export const WidgetsES = {
    icon:'icono',
    button:'botón',
    property:'propiedad',
    new_column:'New Column',
    choose_property:'Choose the property',
    ColumnCreatedWithSuccess:'Column created successfully!'
  };
  