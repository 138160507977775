<template>
  <q-select
    dense
    outlined
    options-dense
    multiple
    color="blue"
    label-color="grey"
    transition-show="jump-up"
    transition-hide="jump-down"
    :input-style="{ fontWeight: '600' }"
    v-model="model"
    :options="[{ title: '' }]"
    :option-value="optionValue"
    :option-label="optionLabel"
    :emit-value="emitValue"
    map-options
    behavior="dialog"
  >
    <!-- --------------------------SELECIONADOS -->
    <template #selected>
      <!-- <div v-if="selectAllOptions">
        <span>Todos selecionados</span>
      </div> -->
      <div
        v-if="`${model && model?.length > 0}`"
        class="flex flex-nowrap gap-1"
      >
        <div>
          <span>{{ model?.length > 0 ? `${model?.length} ` : "" }}</span>
          <span v-show="model && model.length >= 1">
            {{
              model && model.length <= 1
                ? "item selecionado"
                : "itens selecionados"
            }}</span
          >
          <q-tooltip class="bg-secondary text-body2">
            <span v-for="(item, index) in model" :key="index" class="pr-2">
              {{
                index === model.length - 1 ? `${item.title}.` : `${item.title},`
              }}
            </span>
          </q-tooltip>
        </div>
      </div>
    </template>
    <!-- --------------------------INPUT SEARCH -->
    <template #before-options>
      <div
        v-if="inputVisible"
        class="bg-white px-2 z-10 sticky top-0 flex items-center h-14"
      >
        <q-input
          v-model="inputValue"
          filled
          dense
          type="search"
          placeholder="Pesquisar"
          style="width: 100%"
          @update:model-value="handleSearch"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input></div
    ></template>
    <!-- --------------------------OPÇÕES -->
    <template v-slot:after-options>
      <div class="p-3">
        <TreeNode
          :nodes="options"
          :spacing="0"
          :selecteds="modelValue"
          @selectItem="handleSelect"
        />
      </div>
    </template>
    <!-- --------------------------NÃO APAGAR ESTE SLOT -->
    <template v-slot:option="scope">
      <span class="invisible">
        {{ scope.opt.title }}
      </span>
    </template>
  </q-select>
</template>

<script setup>
import { defineEmits, defineProps, computed } from "vue";
import TreeNode from "./treeNode.vue";
const props = defineProps({
  options: Array,
  modelValue: {
    type: Array,
    default: () => [],
  },
  optionValue: String,
  emitValue: String,
  inputVisible: Boolean,
  inputSearch: String,
});

const emits = defineEmits(["update:modelValue", "update:inputSearch"]);

const model = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emits("update:modelValue", newValue);
  },
});

const inputValue = computed({
  get() {
    return props.inputSearch;
  },
  set(value) {
    emits("update:inputSearch", value);
  },
});

const handleSelect = (item) => {
  item.selected = !item.selected;
  let newItem = { ...item };
  if (newItem.children) delete newItem.children;

  if (item.selected) {
    model.value.push(newItem);
  } else {
    let index = model.value.findIndex((element) => element.id === newItem.id);
    if (index !== -1) {
      model.value.splice(index, 1);
    }
  }
};
</script>
