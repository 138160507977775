<template>
  <q-table
    ref="myTable"
    :rows="rows"
    :columns="columns"
    row-key="id"
    :filter="filter"
    :loading="loading"
    separator="cell"
    class="m-3"
  >
    <template v-slot:header="props">
      <q-tr class="uppercase bg-blue100 text-primary">
        <q-th colspan="2">Group 1</q-th>
        <q-th colspan="1">Group 2</q-th>
        <q-th colspan="2">Group 2</q-th>
      </q-tr>
      <q-tr :props="props">
        <q-th
          v-for="col in props.cols"
          :key="col.name"
          :props="props"
          class="uppercase text-primary font-bold"
        >
          {{ col.label }}
        </q-th>
      </q-tr>
    </template>

    <template v-slot:body-cell="props">
      <q-td :props="props" v-if="props.value" :rowspan="props.value.rowspan">
        {{ props.value === "object" ? props.value.title : props.value }}
      </q-td>
    </template>
  </q-table>
</template>

<script setup>
const rows = [
  {
    id: 1,
    name: { title: "John", rowspan: 2 },
    email: "jhon@email.com",
    age: 6,
  },
  {
    id: 2,
    name: null,
    email: "jhon_2@email.com",
    age: 5,
  },
  {
    id: 3,
    name: { title: "Mary", rowspan: 3 },
    email: "mary@email.com",
    age: 6,
  },
  {
    id: 4,
    name: null,
    email: "mary_2@email.com",
    age: 5,
  },
  {
    id: 5,
    name: null,
    email: "mary_3@email.com",
    age: 5,
  },
];

const columns = [
  { label: "id", field: "id", name: "id" },
  { label: "name", field: "name", name: "name" },
  { label: "email", field: "email", name: "email" },
  { label: "age", field: "age", name: "age" },
];
</script>
