// import axios from "axios";
// let number = 0;
const apiTable = async () => {
  return [
    {
      id: 1,
      version: null,
      data: [
        {
          id: 2,
          codigo: "Resultado Global",
          title: "",
          children: [
            {
              id: 2,
              codigo: "1",
              title: "METICORTEN",
              children: [
                {
                  id: 3,
                  codigo: "7.891.142.158.161",
                  title: "METICORTEN COMP 20 MG 1X10",
                  vendauni: "518.600",
                  fatfabrica: 6850187,
                  repasse1: 1519372,
                },
                {
                  id: 4,
                  codigo: "7.891.142.158.130",
                  title: "METICORTEN COMP 5MG 1X20",
                  vendauni: "311.400",
                  fatfabrica: 2818949,
                  repasse1: 625243,
                },
              ],
            },
          ],
        },
        {
          id: 6,
          codigo: "Segunda Linha",
          title: "",
          children: [
            {
              id: 2,
              codigo: "1",
              title: "CHILDREN 2",
              children: [
                {
                  id: 3,
                  codigo: "7.891.142.158.161",
                  title: "METICORTEN COMP 20 MG 1X10",
                  vendauni: "518.600",
                  fatfabrica: 6850187,
                  repasse1: 1519372,
                },
                {
                  id: 4,
                  codigo: "456.969.6985",
                  title: "CHILDREN 3",
                  children: [
                    {
                      id: 5,
                      codigo: "123456789",
                      title: "FIRST CHILD",
                    },
                    {
                      id: 6,
                      codigo: "1234567810",
                      title: "SECOND CHILD",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: "teste",
      success: true,
      data: {
        rowsPerPage: 10,
        totalRows: 7,
        page: 1,
      },
      rows: [
        {
          id: 1,
          condominio: "Maria Cruz",
          status_registro: "adicionado",
          funcionario: "Francisca Santos",
          data_de_entrada: "05/11/2022",
          prazo_para_registro: "01/02/2022",
          status_analise: "adicionado",
          analista: "",
          data_resposta: "",
          CNPJ: "12365148000125",
          estado: "Sao Paulo",
          cidade: "São Paulo",
          number: 1,
        },
        {
          id: 2,
          condominio: "Adamaceno Petroleo",
          status_registro: "aguardando aprovação",
          funcionario: "Gabriela Ferreira de Lima",
          data_de_entrada: "09/11/2022",
          prazo_para_registro: "01/01/2022",
          status_analise: "recebido",
          analista: "Joaquim Santos Ferreira",
          data_resposta: "",
          CNPJ: "12365148000125",
          estado: "Paraná",
          cidade: "Curitiba",
          number: 2,
        },
        {
          id: 3,
          condominio: "Carolina do Norte",
          status_registro: "aprovado",
          funcionario: "Liane Almeida Prado",
          data_de_entrada: "09/11/2022",
          prazo_para_registro: "01/01/2022",
          status_analise: "aprovado",
          analista: "Joaquim Santos Ferreira",
          data_resposta: "25/11/2022",
          CNPJ: "12365148000125",
          estado: "Santa Catarina",
          cidade: "Florianopolis",
          number: 1,
        },
        {
          id: 4,
          condominio: "Custodio Filho",
          status_registro: "reprovado",
          funcionario: "Suiane Ferreira",
          data_de_entrada: "23/11/2022",
          prazo_para_registro: "30/02/2022",
          status_analise: "reprovado",
          analista: "Joaquim Santos Ferreira",
          data_resposta: "29/11/2022",
          CNPJ: "12365148000125",
          estado: "Rio Grande do Sul",
          cidade: "Porto Alegre",
          number: 1,
        },
        {
          id: 5,
          condominio: "Alameda dos Anjos",
          status_registro: "enviado",
          funcionario: "Tatiane Pedroso",
          data_de_entrada: "23/11/2022",
          prazo_para_registro: "30/02/2022",
          status_analise: "consultando dados",
          analista: "Joaquim Santos Ferreira",
          data_resposta: "",
          CNPJ: "12365148000125",
          estado: "Distrito Federal",
          cidade: "Brasilia",
          number: 1,
        },
        {
          id: 6,
          condominio: "Porto Seguro",
          status_registro: "enviado",
          funcionario: "Liane Almeida Prado",
          data_de_entrada: "10/11/2022",
          prazo_para_registro: "30/02/2022",
          status_analise: "analisando proposta",
          analista: "Joaquim Santos Ferreira",
          data_resposta: "",
          CNPJ: "12365148000125",
          estado: "Santa Cararina",
          cidade: "Blumenau",
          number: 1,
        },
        {
          id: 7,
          condominio: "Serafi,",
          status_registro: "enviado",
          funcionario: "Gabriela Ferreira de Lima",
          data_de_entrada: "02/11/2022",
          prazo_para_registro: "30/01/2022",
          status_analise: "buncando informações",
          analista: "Joaquim Santos Ferreira",
          data_resposta: "",
          CNPJ: "12365148000125",
          estado: "Paraná",
          cidade: "Porecatu",
          number: 3,
        },
        {
          id: 7,
          condominio: "Serafi,",
          status_registro: "enviado",
          funcionario: "Gabriela Ferreira de Lima",
          data_de_entrada: "02/11/2022",
          prazo_para_registro: "30/01/2022",
          status_analise: "buncando informações",
          analista: "Joaquim Santos Ferreira",
          data_resposta: "",
          CNPJ: "12365148000125",
          estado: "Paraná",
          cidade: "Curitiba",
          number: 3,
        },
      ],
    },
    {
      id: 3,
      jsondata: [
        { id1: 1, espec: "CLM", full: 22000 },
        { id1: 2, espec: "CLM-PA", full: 23000 },
        { id1: 3, espec: "OTO/PNE", full: 5000 },
        { id1: 4, espec: "ORTO", full: 12000 },
        { id1: 5, espec: "PSI", full: 9500 },
      ],
    },
    {
      id: 5,
      success: true,
      data: {
        rowsPerPage: 10,
        totalRows: 7,
        page: 1,
      },
      rows: [
        {
          id: 1,
          email: "email@email.com",
          name: "Francisca Santos",
        },
        {
          id: 2,
          email: "email@email.com",
          name: "Gabriela Ferreira de Lima",
        },
        {
          id: 3,
          email: "email@email.com",
          name: "Liane Almeida Prado",
        },
        {
          id: 4,
          email: "Custodio Filho",
          name: "Suiane Ferreira",
        },
        {
          id: 5,
          email: "Alameda dos Anjos",
          name: "Tatiane Pedroso",
        },
        {
          id: 6,
          email: "email@email.com",
          name: "Liane Almeida Prado",
        },
        {
          id: 7,
          email: "email@email.com",
          name: "Gabriela Ferreira de Lima",
        },
      ],
    },
    // {
    //   id: 6,
    //   success: true,
    //   data: {
    //     rowsPerPage: 10,
    //     totalRows: 7,
    //     page: 1,
    //   },
    //   rows: await axios
    //     .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
    //     .then((res) =>
    //       res.data.sort((a, b) => {
    //         if (a.nome < b.nome) return -1;
    //         if (a.nome > b.nome) return 1;
    //         return 0;
    //       })
    //     ),
    // },
    // {
    //   id: 41,
    //   success: true,
    //   data: {
    //     rowsPerPage: 10,
    //     totalRows: 7,
    //     page: 1,
    //   },
    //   rows: await axios
    //     .get(
    //       "https://servicodados.ibge.gov.br/api/v1/localidades/estados/PR/distritos"
    //     )
    //     .then((res) =>
    //       res.data
    //         .sort((a, b) => {
    //           if (a.nome < b.nome) return -1;
    //           if (a.nome > b.nome) return 1;
    //           return 0;
    //         })
    //         .map((item) => ({ ...item, number: number++ }))
    //     ),
    // },
    {
      id: 410690205,
      success: true,
      data: {
        rowsPerPage: 10,
        totalRows: 7,
        page: 1,
      },
      rows: [
        { id: 1, number: 1 },
        { id: 2, number: 2 },
        { id: 3, number: 3 },
        { id: 4, number: 4 },
        { id: 5, number: 5 },
        { id: 6, number: 6 },
        { id: 7, number: 7 },
        { id: 8, number: 8 },
        { id: 9, number: 9 },
      ],
    },
    // {
    //   id: 43,
    //   success: true,
    //   data: {
    //     rowsPerPage: 10,
    //     totalRows: 7,
    //     page: 1,
    //   },
    //   rows: await axios
    //     .get(
    //       "https://servicodados.ibge.gov.br/api/v1/localidades/estados/RS/distritos"
    //     )
    //     .then((res) =>
    //       res.data.sort((a, b) => {
    //         if (a.nome < b.nome) return -1;
    //         if (a.nome > b.nome) return 1;
    //         return 0;
    //       })
    //     ),
    // },
    // {
    //   id: 42,
    //   success: true,
    //   data: {
    //     rowsPerPage: 10,
    //     totalRows: 7,
    //     page: 1,
    //   },
    //   rows: await axios
    //     .get(
    //       "https://servicodados.ibge.gov.br/api/v1/localidades/estados/SC/distritos"
    //     )
    //     .then((res) =>
    //       res.data.sort((a, b) => {
    //         if (a.nome < b.nome) return -1;
    //         if (a.nome > b.nome) return 1;
    //         return 0;
    //       })
    //     ),
    // },
    // {
    //   id: 53,
    //   success: true,
    //   data: {
    //     rowsPerPage: 10,
    //     totalRows: 7,
    //     page: 1,
    //   },
    //   rows: await axios
    //     .get(
    //       "https://servicodados.ibge.gov.br/api/v1/localidades/estados/DF/distritos"
    //     )
    //     .then((res) =>
    //       res.data.sort((a, b) => {
    //         if (a.nome < b.nome) return -1;
    //         if (a.nome > b.nome) return 1;
    //         return 0;
    //       })
    //     ),
    // },
  ];
};
export default apiTable();
