<template>
  <q-dialog v-model="dialog.show" :persistent="dialog.persistent" style="z-index: 999999;">
    <q-card class="p-8 flex flex-col items-center justify-center text-center min-w-[400px]">
      <q-card-section class="flex flex-col items-center justify-center">
        <div
          :class="`${dialog.type === 'action' ? 'bg-grey100' : 'bg-warningGrey'} rounded-full w-[50px] h-[50px] flex items-center justify-center`"
        >
          <q-icon
            size="35px"
            :name="`${dialog.type === 'action' ? 'o_info' : 'o_warning'}`"
            :color="`${dialog.type === 'action' ? 'primary' : 'red'}`"
          />
        </div>
        <div class="font-bold text-[1.375rem] mt-[10px]">
          {{ dialog.title }}
        </div>
      </q-card-section>

      <q-card-section class="mb-[10px] pt-0">
        <p class="text-[1.125rem] font-semibold">
          {{ dialog.subtitle }}
        </p>
        <p class="text-[1rem]">
          {{ dialog.text }}
        </p>
      </q-card-section>

      <q-card-actions align="center" class="w-full">
        <q-btn
          unelevated
          no-caps
          class="w-[50%] h-[50px] text-[1rem] font-medium rounded-[10px]"
          label="Não, cancelar"
          color="grey"
          outline
          v-close-popup
        />
        <q-btn
          unelevated
          no-caps
          text-color="white"
          :class="`${dialog.type === 'action' ? 'bg-primary' : 'bg-warningRed'} w-[50%] h-[50px] text-[1rem] font-medium rounded-[10px] text-nowrap text-ellipsis overflow-hidden`"
          :label="`${dialog.type === 'action' ? 'Sim, confirmar' : 'Sim, deletar'}`"
          @click="dialog.actionFunction"
          v-close-popup
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { useGlobalComponents } from '@/store/globalComponents';

export default {
  name: "confirmationDialog",
  data() {
    const store = useGlobalComponents();
    return {
      alert: false,
      store,
    };
  },
  computed: {
    dialog() {
      return this.store.confirmationDialog;
    },
  },
};
</script>

<style>
</style>