<template>
  <div class="w-full flex h-screen overflow-hidden">
    <div>      
      <MenuSidebarComponent />
      <MenuMobileComponent />
    </div>
    <div class="overflow-hidden h-full w-full">
      <HeaderComponent />
      <router-view :key="$route.path" />
    </div>
  </div>
</template>

<script>
import MenuSidebarComponent from "@/components/globalWeb/menuSidebarComponent.vue";
import MenuMobileComponent from "@/components/globalWeb/menuMobileComponent.vue";
import HeaderComponent from "@/components/globalWeb/headerComponent.vue";
import { useLoginStore } from "@/store/loginStore";
import { useHomeConfig } from "@/store/homeConfigStore.js";


export default {
  name: "HomePage",
  data() {
    const loginStore = useLoginStore();
    const homeConfig = useHomeConfig();
    return {
      dynamicWidth: window.innerWidth,
      loginStore,
      homeConfig,
    };
  },
  components: {
    MenuSidebarComponent,
    MenuMobileComponent,
    HeaderComponent,
  },
  methods: {
    atualizarLarguraPagina() {
      this.dynamicWidth = window.innerWidth;
    },
  },
  computed: {
    isSideBarOpen() {
      return (this.dynamicWidth <= 768 )
    },
  },
  mounted() {
    this.loginStore.profileImage =
      localStorage.getItem("@vueweb:imageProfile") !== null &&
      localStorage.getItem("@vueweb:imageProfile") !== "undefined"
        ? localStorage.getItem("@vueweb:imageProfile")
        : null;
    window.addEventListener("resize", this.atualizarLarguraPagina);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.atualizarLarguraPagina);
  },
  async created() {
    await this.homeConfig.getUserHome();
  }
};
</script>