import { defineStore } from "pinia";
import { apiDev } from "@/services/api";
import i18next from "i18next";
import router from "@/routes/router";
import { showPositiveNotify, showNegativeNotify } from "@/utils/useNotify";
const token = localStorage.getItem("@vueweb:accessToken");
import { useStorage } from "@vueuse/core";
import { useUsers } from "@/store/securityAndSettings/usersStore";
import { useMenuSidebarStore } from "./menuStore";
// import { setAuthHeader } from "@/services/api";

export const useLoginStore = defineStore("login", {
  state: () => ({
    authenticated: token ? true : false,
    showPopupLogin: false,
    userId: useStorage("@vueweb:user", null),
    username: useStorage("@vueweb:username", null),
    profileImage: localStorage.getItem("@vueweb:imageProfile"),
    loggedUser: useStorage("@vueweb:loggedUser") || null,
    loading: false,
    userStore: useUsers(),
    useMenuStore: useMenuSidebarStore(),
  }),

  actions: {
    async setAccessToken(res, dataLogin) {
      // //// console.log(dataLogin);
      const { accessToken } = res;
      localStorage.setItem("@vueweb:accessToken", accessToken);
      localStorage.setItem((this.username = dataLogin.username));
      this.authenticated = true;
      router.push("/");
      showPositiveNotify(i18next.t("login.sucessMessage"));
    },

    async loginUser(dataLogin) {
      this.loading = true;
      await apiDev
        .post(`/adm/v2/sec/login`, dataLogin)
        .then(async (response) => {
          const { accessToken, userId } = response.data;
          if (accessToken) {
            localStorage.setItem("@vueweb:accessToken", accessToken);
            localStorage.setItem("@vueweb:user", userId);

            this.username = dataLogin.username;
            this.userId = userId;
            this.authenticated = true;

            showPositiveNotify(i18next.t("login.sucessMessage"));
            if (userId) {
              let res = await this.userStore.getUserById(userId);
              localStorage.setItem("@vueweb:loggedUser", res.name);
              if (res.photourl) {
                localStorage.setItem("@vueweb:imageProfile", res.photourl);
                this.profileImage = res.photourl;
              }
            }
            router.push("/");
          }
          else {
            showNegativeNotify(i18next.t("login.errorMessage"));
          }
        })
        //eslint-disable-next-line
        .catch((error) => {
          console.log(error);
          showNegativeNotify(i18next.t("login.errorMessage"));
        });

      this.loading = false;
    },

    async loginPopup(dataLogin) {
      await apiDev
        .post(`/adm/v2/sec/login`, dataLogin)
        .then(async (response) => {
          const { accessToken, userId } = response.data;
          localStorage.removeItem("@vueweb:accessToken");
          localStorage.removeItem("@vueweb:user");
          localStorage.setItem("@vueweb:accessToken", accessToken);
          localStorage.setItem("@vueweb:user", userId);
          this.useMenuStore.getMenu();
          this.username = dataLogin.username;
          this.userId = userId;
          this.authenticated = true;
          this.showPopupLogin = false;
          showPositiveNotify(i18next.t("login.sucessMessage"));
          if (userId) {
            let res = await this.userStore.getUserById(userId);
            localStorage.removeItem("@vueweb:loggedUser");
            localStorage.setItem("@vueweb:loggedUser", res.name);
            if (res.photourl) {
              localStorage.removeItem("@vueweb:imageProfile");
              localStorage.setItem("@vueweb:imageProfile", res.photourl);
              this.profileImage = res.photourl;
            }
          }
        })
        //eslint-disable-next-line
        .catch((error) => {
          //// console.log(error);
          showNegativeNotify(i18next.t("login.errorMessage"));
        });
    },

    async findUser(user) {
      await apiDev
        .post("/api//adm/auth/forgotpwd", { username: user })
        //eslint-disable-next-line
        .then((response) => {
          //// console.log(response);
          showPositiveNotify(
            "Email enviado com sucesso! Não esqueça de verificar também sua caixa de spam."
          );
        })
        //eslint-disable-next-line
        .catch((error) => {
          //// console.log(error);
          showNegativeNotify("Usuário incorreto");
        });
    },
  },
});
