import { defineStore } from "pinia";
import { apiDev } from "@/services/api";
import moment from "moment";

export const useUsers = defineStore("users", {
  state: () => ({
    isCreate: false,
    currentTab: "personalData",
    defaultUser: null,
    currentUser: {},
    loadingTable: false,
    newUser: {
      name: "",
      email: "",
      manager: {},
      managerId: null,
      companyId: null,
      login: "",
      authType: null,
      password: "",
      havePassword: true,
      isChangePwd: "N",
      confirm_password: null,
    },
    changePwd: "N",
    users: [],
    active: "Y",
    filter: { isActive: "Y" },
    page: 1,
    perPage: 50,
    rows: [],
    order: null,
    pagination: {
      sortBy: "desc",
      descending: false,
      page: 1,
      rowsPerPage: 50,
    },
  }),
  getters: {
    getUserById(state) {
      return async (id) => {
        const res = await apiDev
          .get("/adm/v2/sec/user/" + id)
          .then((res) => res.data)
          .catch((err) => err);
        state.currentUser = res.data;
        // //// console.log(res);
        return state.currentUser;
      };
    },
    getUserByLogin(state) {
      return async (data) => {
        const res = await apiDev
          .post("/adm/v2/sec/user/" + data.param, data.query)
          .then((res) => res.data)
          .catch((err) => err);
        state.currentUser = res.data;
        return state.currentUser;
      };
    },
    async getUsers(state) {
      await this.getAll();
      return state.users;
    },
  },
  actions: {
    setActive(value) {
      this.active = value;
    },
    async setFilter(param = null, filter = null) {
      let filters = this.filter;
      // //// console.log(filters);
      filters = {
        ...filters,
        [param]: filter,
      };
      this.filter = filters;
      await this.getAll();
    },
    async setPagination(pagination) {
      this.pagination = pagination;
      await this.getAll();
    },
    async setOrder(sortBy, order) {
      this.order = sortBy + "_" + order;
      this.getAll();
    },
    setRows() {
      function setMomentInDate(row) {
        row["createdAt"] = moment(row.createdA).format("DD/MM/YYYY HH:mm");
        row["updatedAt"] = moment(row.updateAt).format("DD/MM/YYYY HH:mm");
        return row;
      }
      this.rows = this.users.map((row) => setMomentInDate(row)).reverse();
    },
    async getAll() {
      this.loadingTable = true;
      let queries = `page=${this.pagination.page}&perPage=${this.pagination.rowsPerPage}`;

      if (this.filter !== null) {
        for (let [key, value] of Object.entries(this.filter)) {
          if (value !== null) queries = queries + `&${key}=${value}`;
        }
      }
      if (this.order !== null) {
        queries = queries + `&order=${this.order}`;
      }
      const res = await apiDev
        .get(`/adm/v2/sec/user?${queries}`)
        .then((res) => res.data);

      res.data.rows.forEach(async (element) => {
        const user = await this.getOneUser(element.id);
        if (user.photourl) {
          element["photourl"] = user.photourl;
        }
        element["status"] = false;
      });

      this.users = res.data.rows;

      // console.log("ALL USERS", res.data.rows);
      this.pagination["rowsPerPage"] = res.data.perPage;
      this.pagination["page"] = res.data.page;
      this.pagination["rowsNumber"] = res.data.totalRows;

      this.setRows();
      this.loadingTable = false;
    },
    async getOneUser(id) {
      const res = await apiDev
        .get("/adm/v2/sec/user/" + id)
        .then((res) => res.data)
        .catch((err) => err);
      return res.data;
    },
    async updateUser(id, data) {
      const res = await apiDev
        .put("/adm/v2/sec/user/" + id, data)
        .then((res) => res.data)
        .catch((err) => err);

      return res;
    },
    async createUser(data) {
      const res = await apiDev
        .post("/adm/v2/sec/user", data)
        .then((res) => res.data)
        .catch((err) => err);
      if (!res.data?.error) {
        this.getAll();
      }
      return res.data;
    },

    async deleteUser(id) {
      const res = await apiDev
        .delete("/adm/v2/sec/user/" + id)
        .then((res) => res.data)
        .catch((err) => err);
      if (res.data.success) this.getAll();
      return res;
    },
  },
});
