<template>
  <div>
    <SkelletonHome v-if="homeConfig.homeUserLoading" />
    <router-view v-show="!homeConfig.homeUserLoading" />
  </div>
</template>

<script>
import SkelletonHome from './components/home/SkelletonHome.vue';
import { useHomeConfig } from "./store/homeConfigStore.js";

export default {
  name: "App",
  components: {
    SkelletonHome
  },

  data() {
    const homeConfig = useHomeConfig();
    return {
      homeConfig,
    };
  },

  async created() {
    await this.homeConfig.getLoginHome();
    // this.homeConfig.handleImages();
  },
};
</script>

<style>
*::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

*::-webkit-scrollbar-track {
  background-color: rgb(216, 216, 216);
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(145, 144, 144);
  border-radius: 10px;
}

.sidebar {
  z-index: 99999;
}
</style>