const ptBundles = {
    labels: {
        user: 'Usuário',
        password: 'Senha'
    },
    login: {
        typeUser: 'Digite Seu Usuário',
        typePassword: 'Digite Sua Senha',
    },
    cmds: {
        WEBOPENBRO: 'Abrir Browser',
        WEBFINDELE: 'Encontrar Elemento'
    }
};

export default ptBundles;