<template>
  <header class="px-[2rem] py-2 w-full bg-white">
    <div v-if="isTable" class="flex items-center justify-between mb-2">
      <h2 class="text-xl font-semibold text-grey900">Visões</h2>
      <div class="flex space-x-2">
        <q-btn
          unelevated
          dense
          flat
          icon="refresh"
          label="Atualizar"
          color="accent"
          @click="refreshTable"
        />
        <q-btn
          unelevated
          dense
          flat
          icon="add"
          label="Visão"
          color="primary"
          @click="dialogNewView = true"
        />

        <q-btn
          unelevated
          dense
          flat
          icon="file_upload"
          label="Publicar"
          color="accent"
          v-if="store.selecteds.length > 0"
          @click="dialogPublish = true"
        />

        <q-dialog v-model="dialogPublish" persistent>
          <q-card class="q-pa-lg" style="max-width: 600px; width: 100%;">
            <q-card-section>
              <div class="text-h5">Publicar Visão</div>
              <div class="text-body2 text-grey-7 q-mt-sm">
                {{
                  store.selecteds.length > 0
                    ? `${store.selecteds.length} visões selecionadas.`
                    : "Nenhuma visão selecionada."
                }}
              </div>
            </q-card-section>

            <q-separator />

            <q-card-section>
              <q-editor
                v-model="commentPostView"
                :content-style="{ height: '150px' }"
                placeholder="Escreva seu comentário..."
                :toolbar="[]"
                minimal
                class="bg-grey-1"
              />
              <q-item-label caption class="q-mt-sm text-grey-7">
                Adicione um comentário para a publicação.
              </q-item-label>
            </q-card-section>

            <q-card-actions align="right">
              <q-btn flat label="Cancelar" color="negative" @click="dialogPublish = false" />
              <q-btn
                flat
                label="Confirmar"
                :color="!commentPostView ? 'grey-5' : 'primary'"
                :disable="!commentPostView || loadingPublish"
                :loading="loadingPublish"
                @click="publish"
                class="relative"
              />
            </q-card-actions>
          </q-card>
        </q-dialog>

        <!-- Diálogo de Seleção de Tipo de Visão -->
        <q-dialog v-model="dialogNewView" persistent>
          <q-card class="q-pa-lg" style="max-width: 600px; width: 100%;">
            <q-card-section>
              <div class="text-h5">Selecione o Tipo de Visão</div>
            </q-card-section>
            <q-separator />
            <q-list>
              <q-item
                v-for="item in viewsTypes"
                :key="item.id"
                clickable
                @click="createNewView(item)"
              >
                <q-item-section avatar>
                  <q-avatar
                    color="primary"
                    text-color="white"
                    :icon="item.icon"
                  />
                </q-item-section>
                <q-item-section>
                  <q-item-label class="uppercase text-sm">{{ item.title }}</q-item-label>
                  <q-item-label caption>{{ item.description }}</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-card>
        </q-dialog>
      </div>
    </div>

    <div v-else class="flex items-center justify-between">
      <div class="flex items-center space-x-2">
        <q-btn
          unelevated
          flat
          dense
          icon="arrow_back"
          color="primary"
          @click="backToTable"
        />
        <span class="text-primary uppercase font-bold text-sm">
          Visões / {{ store.isCreate ? "Criar Visão" : "Editar Visão" }}
        </span>
      </div>
    </div>

    <div v-if="isTable" class="flex items-center justify-between mt-4">
      <div class="flex items-center space-x-2 w-2/3">
        <InputQuasarComponent
          v-model="textSearch"
          label="Pesquise uma palavra chave."
          type="text"
          color="light-blue"
          icon="search"
          style="width: 50%"
        />
      </div>
    </div>
  </header>

  <router-view :key="$route.path"></router-view>
</template>

<script setup>
import { ref, computed } from "vue";
import { useViewsStore } from "@/store/registration/viewsStore";
import { useRoute, useRouter } from "vue-router";
import InputQuasarComponent from "../../../../../global/componentsVue/inputComponent/inputQuasarComponent.vue";
import { showNegativeNotify, showPositiveNotify } from "@/utils/useNotify";

const route = useRoute();
const router = useRouter();
const store = useViewsStore();

const dialogPublish = ref(false);
const dialogNewView = ref(false);
const commentPostView = ref("");

const textSearch = computed({
  get() {
    return store.textSearch;
  },
  set(newValue) {
    store.textSearch = newValue;
  },
});

const isTable = computed(() => route.name === "view-table");

const loadingPublish = computed(() => store.loadingPublish);

const viewsTypes = [
  {
    id: 1,
    title: "Widget Único",
    icon: "view_list",
    type: "U",
    description: "Uma visão com um único widget configurável.",
  },
  {
    id: 2,
    title: "Criar Layout",
    icon: "grid_view",
    type: "C",
    description: "Um layout configurável com vários widgets.",
  },
];

const createNewView = async (item) => {
  const newTitle = `Visão ${item.type === "U" ? "simples" : "layout"} ${
    store.pagination.rowsNumber + 1
  }`;

  const newView = {
    title: newTitle,
    headTitle: "",
    menu: { title: "", moduleId: null, module: null },
    layout: [],
    layoutType: item.type,
  };

  const res = await store.createView(newView);
  if (res.success) {
    store.isCreate = true;
    router.push(`/view-admin/layout/${res.data.dashId}/${item.type}`);
    showPositiveNotify(`A Visão ${newTitle} foi criada com sucesso!`);
    dialogNewView.value = false;
    store.getAllViews();
  } else {
    showNegativeNotify(
      "Não foi possível criar a visão. Tente novamente mais tarde."
    );
  }
};

const backToTable = () => {
  router.push("/view-admin");
};

const publish = async () => {
  if (!commentPostView.value) {
    showNegativeNotify("O campo comentário é obrigatório para a publicação.");
    return;
  }

  if (store.selecteds.length > 0) {
    const comment = { comment: commentPostView.value };
    for (const element of store.selecteds) {
      const result = await store.publishView(element.id, comment);
      if (result.success) {
        showPositiveNotify("A visão foi publicada com sucesso!");
        store.selecteds = [];
        dialogPublish.value = false;
      } else {
        showNegativeNotify(
          "Houve um erro na publicação. Tente novamente mais tarde."
        );
      }
    }
  } else {
    showNegativeNotify("Nenhuma visão foi selecionada para publicação.");
  }
};

const refreshTable = () => {
  store.pagination = {
    sortBy: "title",
    descending: false,
    page: 1,
    rowsPerPage: 50,
    rowsNumber: store.pagination.rowsNumber,
  };
  store.getAllViews();
};
</script>
