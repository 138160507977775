import script from "./modalNewProject.vue?vue&type=script&setup=true&lang=js"
export * from "./modalNewProject.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QDate from 'quasar/src/components/date/QDate.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QToolbar,QToolbarTitle,QBtn,QItemLabel,QMenu,QDate});qInstall(script, 'directives', {ClosePopup});
