import script from "./headerView.vue?vue&type=script&setup=true&lang=js"
export * from "./headerView.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QEditor from 'quasar/src/components/editor/QEditor.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QDialog,QCard,QCardSection,QSeparator,QEditor,QItemLabel,QCardActions,QList,QItem,QItemSection,QAvatar});
