import { apiDev } from "@/services/api";
import moment from "moment";

export default {
  /********************* ATIVIDADES ****************************/

  async getActivitiesById(projectId) {
    const res = await apiDev
      .get(`/handler/v2/agile/stories?q_projId=${projectId}`)
      .then((response) => response.data)
      .catch((error) => error);

    return res;
  },

  /**
   * Busca todas as Atividades relacionana ao currentProject, que foi obtida em getProjectById
   */
  async getActivities(projectId) {
    this.activiyLoading = true;

    let query = "";
    if (projectId !== "all") query = "?q_projId=" + projectId;
    if (this.mountQuery() !== null) {
      query =
        query.length === 0
          ? query + "?filters=" + this.mountQuery()
          : query + "&filters=" + this.mountQuery();
    }

    //Busca as Lanes do Projeto

    let resData = { data: [] };

    if (this.groupLanes === "stories") {
      this.currentProject["lanes"] = [];
      let stories = await this.getLanesPerGroupBy(query);
      stories.forEach((story) => {
        story.items.forEach((item) => {
          let newItem = { ...item };
          this.currentProject["lanes"].push({
            id: newItem.id,
            title: newItem.title,
            color: "#5D9DDD",
          });
          resData.data.push({
            ...item,
            storyId: item.id,
            items: item.tasks.filter((it) => it.title !== null),
          });
        });
      });
    } else {
      this.currentProject["lanes"] = await this.getLanesPerGroupBy(query);
      // // console.log(this.currentProject["lanes"]);
      query =
        query.length === 0
          ? query + "?q_groupBy=" + this.groupBy
          : query + "&q_groupBy=" + this.groupBy;

      // // console.log(this.currentProject);
      //Busca as atividades do projeto
      resData = await this.getApi("get", "/handler/v2/agile/stories" + query);
    }

    const newdata = await this.addTagsAndUsersInTasks(resData.data);
    // // console.log(newdata);
    this.kanbanAnListMount("currentProject", newdata);
    this.mountDataToGantt("currentProject");
    this.activiyLoading = false;
  },

  /**____________________________________________ */
  // não esta sendo usado
  /**
   * Busca todas as Atividades relacionana ao currentBoard, que foi obtida em getBoardById
   */
  async getBoardActivities(boardId, sprintId) {
    this.activiyLoading = true;

    let query = "";
    if (boardId !== "all") query = "?q_boardId=" + boardId;

    if (this.mountQuery() !== null) {
      query =
        query.length === 0
          ? query + "?filters=" + this.mountQuery()
          : query + "&filters=" + this.mountQuery();
    }

    //Busca as Lanes do Projeto

    let resData = { data: [] };

    if (this.groupLanes === "stories") {
      this.currentBoard["lanes"] = [];
      let stories = await this.getBoardLanesPerGroupBy(query);
      stories.forEach((story) => {
        story.items.forEach((item) => {
          let newItem = { ...item };
          this.currentBoard["lanes"].push({
            id: newItem.id,
            title: newItem.title,
            color: "#5D9DDD",
          });
          resData.data.push({
            ...item,
            storyId: item.id,
            items: item.tasks.filter((it) => it.title !== null),
          });
        });
      });
    } else {
      const newlanes = await this.getBoardLanesPerGroupBy(query);
      this.currentBoard["lanes"] = [...newlanes].filter(
        (lane) => !lane.backlog && !lane.sprint
      );

      query =
        query.length === 0
          ? query + "?q_groupBy=" + this.groupBy
          : query + "&q_groupBy=" + this.groupBy;

      // // console.log(this.currentProject);
      //Busca as atividades do projeto
      resData = await this.getApi(
        "get",
        "/handler/v2/agile/boards/stories" + query
      );
    }
    if (this.currentBoard.activeSprintId) {
      const dataWidthChildrenData = await this.addTagsAndUsersInTasks(
        resData.data
      );
      const newdata = this.filterTaskBySprint(dataWidthChildrenData, sprintId);
      // console.log(newdata);

      //Montagem das lanes e lista
      this.kanbanAnListMount("currentBoard", newdata);
      // console.log(this.currentBoard.lanes);
    }

    this.activiyLoading = false;
  },
  /**
   * realiza o filtro para não exibir o status de backlog
   * @param {Array} arr
   * @returns
   */
  filterTaskBySprint(arr, sprintId) {
    return arr.map((story) => {
      let newstory = { ...story };
      if (story.statusId && story.statusId === 13349) {
        newstory["statusId"] = 1;
      }
      newstory["items"] = story.items.filter((item) => {
        let newitem = { ...item };
        newitem["tasks"] = item.tasks.filter(
          (task) => task.taskSprintId == sprintId
        );
        if (newitem.tasks.length > 0) return newitem;
      });
      return newstory;
    });
  },
  /**____________________________________________ */

  /**
   * Atualiza a posição do card
   * @param {object} objMoved
   */
  async updateLaneOfActivity({ laneId, cardMoved, isOtherLane }) {
    let storiesOrTaskKey = this.groupLanes === "stories" ? "tasks" : "stories";
    let result;
    let newstory = await this.findDataById(cardMoved.id, storiesOrTaskKey);

    if (newstory.tagsObj) newstory.tags = newstory.tagsObj;
    if (newstory.usersObj) newstory.users = newstory.usersObj;

    // Atualiza no banco de dados
    if (isOtherLane) {
      //eslint-disable-next-line
      result = await this.getApi(
        "put",
        `/handler/v2/agile/${storiesOrTaskKey}/${cardMoved.id}`,
        {
          [this.groupBy]: laneId,
          updatedAt: moment().format("YYYY-MM-DDTHH:MM:mm"),
          updatedById: this.storeLogin.userId,
        }
      );

      if (result.success && result.data.updated > 0) {
        return { error: false };
      } else {
        // console.log("Error move card: ", result);
        return { error: true };
      }
    }
    return { error: false };
  },

  /**NÂO ESTA SENDO USADO */
  //eslint-disable-next-line
  async updateLaneOfActivityBoard({ laneId, cardMoved, isOtherLane }) {
    // result = await this.getApi(
    //   "put",
    //   `/handler/v2/agile/${storiesOrTaskKey}/${cardMoved.id}`,
    //   {
    //     [this.groupBy]: laneId,
    //     updatedAt: moment().format("YYYY-MM-DDTHH:MM:mm"),
    //     updatedById: this.storeLogin.userId,
    //   }
    // );

    // if (result.success && result.data.updated > 0) {
    //   return { error: false };
    // } else {
    //   // console.log("Error move card: ", result);
    //   return { error: true };
    // }
    return { error: false };
  },

  /**
   * Busca a atividade pelo seu ID e adiciona em currentActivity
   * @param {string} params Id da atividade obtida dos parametros da rota no componente
   * É possivel obter, companyId e projectId, basta incluir no objeto do parametro desse metodo
   */
  async getActivityById(activityId) {
    const data = await this.findDataById(activityId, "stories");
    // console.log(data);
    this.currentActivity = { ...data };

    this.currentActivity["startDate"] = this.currentActivity.createdAt;

    // // console.log(this.currentActivity);
  },
  /**
   * Remove a atividade do container atual
   * Usado sómente no front
   * @param  laneId  ID do container(lane)
   * @param removedIndex ID do card a ser removido
   */
  async removeActivityLane(laneId, removedIndex) {
    this.currentProject.lanes = this.currentProject.lanes.map((lane) => {
      if (lane.id === laneId) {
        lane.tasks.splice(removedIndex, 1);
      }
      return lane;
    });
  },

  /**
   * Move a atividade de container
   * @param laneId ID do container(lane)
   * @param activity A atividade que sera inserida
   * @param addedIndex ID do card que sera inserido
   * @param isOtherLane Boolean, só sera inserido se for TRUE - container diferente do atual
   */
  //eslint-disable-next-line
  async addActivityLane(laneId, task, addedIndex, isOtherLane) {
    let result;
    // Atualiza no banco de dados
    if (isOtherLane) {
      //eslint-disable-next-line
      result = await this.getApi(
        "put",
        `/handler/v2/agile/lanes/${laneId}/stores?storeId=${task.id}`
      );
    }

    // console.log(result);
    if (Array.isArray(result) && result[0] > 0) {
      // Atualiza no card
      //eslint-disable-next-line
      const newstore = await this.findDataById(task.id, "stories");
      // console.log(newstore);
      this.currentProject.lanes = this.currentProject.lanes.map((lane) => {
        if (lane.id === laneId) {
          lane.tasks.splice(addedIndex, 0, task);
        }
        return lane;
      });
    } else {
      // console.log("Error move card: ", result);
    }
  },

  /**
   * Cria uma atividade
   * @param {number, string} projectId
   * @param {number, string} laneId
   * @param {object} card
   * @returns
   */
  //eslint-disable-next-line
  async insertActivity(laneId, card) {
    const statusId =
      this.groupBy !== "statusId" ? this.storeFilters.statusList[0].id : null;

    return await this.insertCard(
      "currentProject",
      { projectId: this.currentProject.id },
      "stories",
      "storyId",
      laneId,
      card,
      statusId
    );
  },

  async deleteActivity(taskId) {
    const result = await this.getApi(
      "delete",
      `/handler/v2/agile/stories/${taskId}`
    );

    if (result.success) {
      this.getActivities(this.route.params.projectId);
    }
  },
};
