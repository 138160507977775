import { apiDev } from "@/services/api";
import { getInitialLetters } from "@/utils/useUtils";

export default {
  async getChecklist(id) {
    this.checklistLoading = true;
    const res = await this.getApi(
      "get",
      "/handler/v2/agile/checklist?parentId=" + id
    );
    // console.log(res);
    if (res.success) {
      let newdata = [];
      for (let item of res.data) {
        let newitem = { ...item };
        const user = await this.storeUsers.getUserById(item.updated);
        if (user.photourl) {
          newitem["photourl"] = user.photourl;
        } else {
          newitem["avatar"] = getInitialLetters(user.name);
          newitem["name"] = user.name;
        }
        newdata.push(newitem);
      }
      this.checklist = newdata.reverse();
    }
    this.checklistLoading = false;
  },
  async addItem(item, type = "T") {
    this.loadingChecklistItem = true;
    const query = "?parentType=" + type;
    const res = await this.getApi(
      "post",
      "/handler/v2/agile/checklist" + query,
      item
    );

    if (res.success) {
      const itemList = await this.getItemById(res.data.itemId);

      const user = await this.storeUsers.getUserById(item.updatedById);
      if (user.photourl) {
        itemList["photourl"] = user.photourl;
      } else {
        itemList["avatar"] = getInitialLetters(user.name);
        itemList["name"] = user.name;
      }

      this.checklistLoading = false;
      this.loadingChecklistItem = false;
      return { success: true, item: itemList };
    } else {
      this.loadingChecklistItem = false;
      return res;
    }
  },
  async updateItem(id, item, type = "T") {
    const query = "?parentType=" + type;
    const res = await this.getApi(
      "put",
      "/handler/v2/agile/checklist/" + id + query,
      item
    );
    if (res.success) {
      // Pega o item que receberá o update
      let itemList = await this.getItemById(id);
      // ---------------
      // Faz um cópia do item recebido na função
      let newitem = { ...item };
      // Encontra o user que fez o update
      const user = await this.storeUsers.getUserById(item.updatedById);
      // chama a função addAvatar
      if (user.photourl) {
        newitem["photourl"] = user.photourl;
      } else {
        newitem["avatar"] = getInitialLetters(user.name);
        newitem["name"] = user.name;
      }

      // const itemIndex = this.checklist.findIndex((i) => {
      //   if (i !== null && i.id === res.data.updated) return i;
      // });
      // this.checklist[itemIndex] = res.item;
      // addChecklist.value = false;
      // ---------------
      this.checklistLoading = false;
      return {
        success: true,
        item: itemList,
      };
    }
    return res;
  },
  async getItemById(id) {
    const res = await this.getApi("get", "/handler/v2/agile/checklist/" + id);
    if (res.success) {
      return res.data;
    }
  },

  async deleteChecklistItem(id, parentId, type = "T") {
    const query = `?parentType=${type}&parentId=${parentId}`;
    return await apiDev
      .delete(`/handler/v2/agile/checklist/${id}${query}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.error(err);
        throw err;
      });
  },
};
