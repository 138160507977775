import script from "./menuMobileComponent.vue?vue&type=script&setup=true&lang=js"
export * from "./menuMobileComponent.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QScrollArea,QExpansionItem,QItemSection,QAvatar,QCard,QItem,QItemLabel});qInstall(script, 'directives', {Ripple});
