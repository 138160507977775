<template>
  <!-- DIV CARDS -->
  <div class="flex flex-col px-[2rem] h-full">
    <!-- Header Lateral -->
    <header class="flex items-center justify-between relative h-16 space-x-2">
      <div class="flex items-center space-x-2 w-full">
        <q-breadcrumbs
          dense
          class="uppercase text-xl text-grey900 font-semibold"
        >
          <q-breadcrumbs-el label="Email" />
        </q-breadcrumbs>
        <InputQuasarComponentVue
          v-model="value"
          label="Pesquisar Email"
          type="text"
          color="light-blue"
          icon="email"
          class="w-full"
          disable
        />
      </div>
      <div class="flex space-x-2">
        <q-btn flat dense color="accent" icon="filter_alt" />
        <q-btn
          dense
          color="accent"
          text-color="white"
          :icon="isListContainer ? 'space_dashboard' : 'view_list'"
          @click="() => (isListContainer = !isListContainer)"
          unelevated
        />
      </div>
    </header>

    <div class="relative h-[calc(100%_-_130px)]">
      <Transition appear v-if="isListContainer">
        <ListEmailComponent />
      </Transition>
      <Transition appear v-else>
        <BoxEmailComponent />
      </Transition>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import { useMailStore } from "@/store/products/mailStore";
import InputQuasarComponentVue from "../../../../../global/componentsVue/inputComponent/inputQuasarComponent.vue";

import BoxEmailComponent from "@/components/email/boxEmailComponent.vue";
import ListEmailComponent from "@/components/email/listEmailComponent.vue";
const isListContainer = ref(false);

const store = useMailStore();

onMounted(() => {
  store.getMail();
});
</script>
