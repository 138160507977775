//eslint-disable-next-line

export default {
  /********************* MONTAGEM DOS DADOS ****************************/

  /**
   * Responsável por buscar dados pelo Id através do médoto passado por parametro.
   * @param {object|number|string} data // dado recebido em carda card (priorityId, tags, users ...)
   * @param {string} arr // nome do array em data, que sera feito o find
   * @param {string} store // a store onde esta o array para fazer o find
   * @returns
   */
  getItemDataToCard(data, arr, store = null) {
    let newvalues = [];
    newvalues = this[store][arr].find(
      (item) => parseInt(item.id) === parseInt(data)
    );
    return newvalues;
  },
  /**
   * Responsavel por adicionar os objetos de usuario, tags e prioridade em cada card
   * @param {array} arr Resposta da chamada de atividades ou tarefas
   * @returns {array}
   */
  async addTagsAndUsersInTasks(arr) {
    // console.log(arr);
    let newarr = [];
    // console.log(arr);
    if (arr) {
      for (let data of arr) {
        let newdata = { ...data };
        newdata.items = [];
        for (let item of data.items) {
          //criar nova variavel
          let newitem = { ...item };

          //Se for agrupado por status, pega o status da lane, se não já tem no card.
          if (data.statusId) newitem["statusId"] = data.statusId;
          //TAGS
          if (item.tagsObj) newitem["tags"] = item.tagsObj;
          //USERS
          if (item.usersObj) newitem["users"] = item.usersObj;

          //Head
          if (item.headObj) newitem["head"] = item.headObj;

          //Prioridade
          if (item.storyPriority) newitem["priority"] = item.storyPriority;
          if (item.taskPriority) newitem["priority"] = item.taskPriority;

          newitem["status"] = this.getItemDataToCard(
            newitem.statusId,
            "statusList",
            "storeFilters"
          );
          if (item.tasks) {
            let newtasks = [];
            for (let task of item.tasks) {
              let newtask = { ...task };
              //TAGS
              if (task.tagsObj) newtask.tags = task.tagsObj;
              //USERS
              if (task.usersObj) newtask.users = task.usersObj;
              //Head
              if (task.headObj) newitem.head = newtask.headObj;

              newtask["priority"] = this.getItemDataToCard(
                task.priorityId,
                "priorities",
                "storeFilters"
              );
              newtask["status"] = this.getItemDataToCard(
                task.statusId,
                "statusList",
                "storeFilters"
              );
              newtasks.push(newtask);
            }
            newitem.tasks = newtasks;
          }

          //empurrar para o array items em newdata.
          newdata.items.push(newitem);
        }
        newarr.push(newdata);
      }
    }
    return newarr;
  },
  /**
   * Monta os dados para a lista e para o kanban
   * @param {string} currentDataKey a chave o objeto atual com as lanes para serem atualizadas - ex: 'currentProject'
   * @param {array} data Array com os dados que serão distribuidos nas lanes
   * @param {string} key A chave onde serão armazenado os dados em cada lane, ex: 'activities'
   */
  kanbanAnListMount(currentDataKey, data) {
    // // console.log(data)

    this[currentDataKey].lanes = this[currentDataKey].lanes.map((lane) => {
      if (data.length > 0) {
        data.forEach((item) => {
          // // console.log(item);
          if (item[this.groupBy] === lane.id) {
            lane["tasks"] = item.items.filter((it) => it !== null);
          }
        });
      } else {
        lane["tasks"] = [];
      }
      return lane;
    });
  },

  boardKanbanAnListMount(currentDataKey, data) {
    // // console.log(data)

    this[currentDataKey].lanes = this[currentDataKey].lanes.map((lane) => {
      lane["tasks"] = [];
      if (data.length > 0) {
        data.forEach((item) => {
          // // console.log(item);
          if (item.statusId === lane.id) {
            lane["tasks"] = item.items.filter((it) => it !== null);
          }
        });
      } else {
        lane["tasks"] = [];
      }
      return lane;
    });
  },
  /**
   * Monta uma listagem para ser exibida no gantt, PROVAVELMENTE ESSES DADOS VIRÃO NA API E ESSE METODO SERA DESATIVADO
   * @param {string} currentDataKey A chave objeto atual onde vai ser adicionado a listagem do gantt , ex: 'currentProject'
   * @param {object} data Os dados que serão adicionados na listagem do gantt
   */
  mountDataToGantt(currentDataKey) {
    this[currentDataKey]["gantt"] = [];
    //eslint-disable-next-line
    this[currentDataKey].lanes.forEach((lane) => {
      if (lane.tasks && lane.tasks.length > 0) {
        lane.tasks.forEach((item, index) => {
          this[currentDataKey]["gantt"].push({
            startDate: item.startDate,
            endDate: item.endDate,
            ganttBarConfig: {
              id: item.id,
              label: item.title,
              style: {
                // background: this[currentDataKey].lanes.find(lane => lane.id == laneId).color,
                background:
                  currentDataKey === "currentProject" ? "#5C9BA9" : "#9CA3AF",
                color: "white",
                opacity: "0.75",
              },
              entityType: item.entityType,
            },
          });
          if (item.tasks && item.tasks.length > 0) {
            // LINHA FILHA
            this[currentDataKey]["gantt"][index] = {
              ...this[currentDataKey]["gantt"][index],
              children: item.tasks.map((subitem) => ({
                startDate: subitem.startDate,
                endDate: subitem.endDate,
                ganttBarConfig: {
                  id: subitem.id,
                  label: subitem.title,
                  style: {
                    // background: this[currentObject].lanes.find(lane => lane.id == subitem.laneId).color,
                    background: "#9CA3AF",
                    color: "white",
                    opacity: "0.75",
                  },
                  entityType: subitem.entityType,
                },
              })),
            };
          }
        });
      }
    });
  },

  /**
   * Adiciona filhos a lista do gantt, PROVAVELMENTE ESSES FILHOS VIRÃO NA API E ESSE METODO SERA DESATIVADO
   * @param {object} currentObject o objeto atual onde vai ser adicionado os filhos do gantt - exemplo currentProject
   * @param {object} data Os dados que serão adicionados como filhos
   */
  getChildrenToGantt(currentObject, data, keySubitem) {
    this[currentObject].gantt = this[currentObject].gantt.map((item) => {
      // let activityTaks = getAllTasksByActivityId(activity.ganttBarConfig.id);

      if (typeof data === "object") {
        item["children"] = [];

        data.forEach((subitem) => {
          if (
            parseInt(item.ganttBarConfig.id) === parseInt(subitem[keySubitem])
          ) {
            item.children.push({
              startDate: subitem.startDate,
              endDate: subitem.endDate,
              ganttBarConfig: {
                id: subitem.id,
                label: subitem.title,
                style: {
                  // background: this[currentObject].lanes.find(lane => lane.id == subitem.laneId).color,
                  background:
                    keySubitem === "activityId" ? "#5C9BA9" : "#9CA3AF",
                  color: "white",
                  opacity: "0.75",
                },
              },
            });
          }
        });
      }
      return item;
    });
  },

  /**
   * Responsavel de separar os dados em lanes conforme agrupador
   * @param {string} query
   * @returns
   */
  async getLanesPerGroupBy(query) {
    // // console.log(`/handler/v2/agile/${this.groupLanes}${query}`);
    const res = await this.getApi(
      "get",
      `/handler/v2/agile/${this.groupLanes}${query}`
    );
    if (this.groupLanes === "statusLanes") {
      let newarray = [];
      newarray[0] = res.data.find((item) => item.title === "backlog");
      newarray[1] = res.data.find((item) => item.title === "start");
      newarray[2] = res.data.find((item) => item.title === "progress");
      newarray[3] = res.data.find((item) => item.title === "validation");
      newarray[4] = res.data.find((item) => item.title === "blocked");
      newarray[5] = res.data.find((item) => item.title === "concluded");

      return newarray.map((item) => {
        let newitem = { ...item };
        newitem["tasks"] = [];
        if (item?.fillColor) {
          newitem["color"] = item?.fillColor;
        }
        return newitem;
      });
    } else {
      return res.data.map((item) => {
        let newitem = { ...item };
        newitem["tasks"] = [];
        if (item.fillColor) {
          newitem["color"] = item.fillColor;
        }
        return newitem;
      });
    }
  },

  /**
   * Responsavel de separar os dados em lanes conforme agrupador
   * @param {string} query
   * @returns
   */
  async getBoardLanesPerGroupBy(query) {
    // console.log(query);
    // // console.log(`/handler/v2/agile/${this.groupLanes}${query}`);
    let endpoint =
      this.groupLanes === "stories"
        ? "boards/" + this.groupLanes
        : this.groupLanes;
    // console.log(query);
    let res = await this.getApi("get", `/handler/v2/agile/${endpoint}${query}`);

    // console.log(res);
    // if (this.groupLanes === "statusLanes") {
    let newarray = [];
    newarray[0] = res.data.find((item) => item.title === "start");
    newarray[1] = res.data.find((item) => item.title === "progress");
    newarray[2] = res.data.find((item) => item.title === "validation");
    newarray[3] = res.data.find((item) => item.title === "blocked");
    newarray[4] = res.data.find((item) => item.title === "concluded");

    return newarray.map((item) => {
      let newitem = { ...item };
      newitem["tasks"] = [];
      if (item.fillColor) {
        newitem["color"] = item.fillColor;
      }
      return newitem;
    });
    // } else {
    //   console.log(res)
    //   return res.data.map((item) => {
    //     let newitem = { ...item };
    //     newitem["tasks"] = [];
    //     if (item.fillColor) {
    //       newitem["color"] = item.fillColor;
    //     }
    //     return newitem;
    //   });
    // }
  },

  /**
   * Adiciona o item no array de gantt , kanban ou lista
   * @param {string} key
   * @param {string} arrayKey
   * @param {object} card
   * @param {number,string} id
   */
  //eslint-disable-next-line
  addCardInData(key, arrayKey, card, id = null) {
    if (id !== null) {
      let index = this[key][arrayKey].findIndex((value) => value.id === id);
      this[key][arrayKey][index].tasks.push(card);
    } else this[key][arrayKey].push(card);
  },

  addCardInDataBacklog(key, arrayKey, card, container) {
    if (arrayKey !== "gantt" && container === "backlog") {
      this.currentBoard.backlog.tasks.unshift(card);
      return;
    }
    // if (arrayKey !== "gantt" && container === "sprint") {
    //   console.log("SPRINT");
    //   this.currentBoard.sprint.tasks.unshift(card);
    //   console.log("CURRENTBOARD", this.currentBoard.sprint.tasks);
    // }
  },
  /**
   * Monta os dados de Backlog
   * @param {Object} data
   * @returns
   */
  getBacklogOrSprintData(data) {
    let newitem = { ...data };
    let dataStatus = {};
    if (this.groupBy === "statusId") {
      dataStatus = this.storeFilters.statusList.find(
        (status) => data && status.id === data.statusId
      );
    }
    if (this.groupBy === "storyId") {
      dataStatus = data;
      newitem["items"] = data.tasks;
    }

    newitem = { ...newitem, ...dataStatus };
    return newitem;
  },
};
