<template>
     <header  :class="`px-[2rem] py-2 w-full bg-white ${isTable ? 'h-[120px]' : 'h-[36px] bg-grey100'}`"
     >
    <div v-if="isTable" class="flex items-center justify-between mb-2">
      <h2 class="text-xl font-semibold text-grey900">Grupo de Acesso</h2>
      <div class="flex space-x-2 ml-auto">

        <q-btn
            unelevated
            dense
            flat
            icon="refresh"
            label="Atualizar"
            color="accent"
            @click="onTableClick"
            ></q-btn>
            <q-btn
              flat
              dense
              unelevated
              color="primary"
              icon="add"
              label="grupo"
              @click="goToNewGroupAccess = true"
            ></q-btn>
           
            <q-btn
              v-if="!isTable"
              flat
              dense
              unelevated
              color="primary"
              label="Salvar"
              icon="save"
            ></q-btn>
          </div>
          <div>
              <q-breadcrumbs-el
                v-if="!isTable"
                :label="`${isCreate ? 'Criar grupo' : 'Editar grupo'}`"
              />
         
          </div>
        </div>
        <div v-if="isTable" class="flex items-center justify-between mt-4">
      <div class="flex items-center space-x-2 w-2/3">
        <InputQuasarComponent
            v-model="textSearch"
            label="Pesquise uma palavra chave e aperte enter para confirmar."
            type="text"
            color="light-blue"
            icon="search"
            style="width: 50%"
          />
        </div>
        </div>

        <div>
         
        </div>
    
    </header>
  </template>
  
  <script setup>
  import { ref, computed } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import { useAccessGroup } from "@/store/securityAndSettings/accessGroupStore.js";
  import InputQuasarComponent from "../../../../../global/componentsVue/inputComponent/inputQuasarComponent.vue";

  
  const route = useRoute();
  const router = useRouter();
  const store = useAccessGroup();
  
  const textSearch = ref("");
  
  const isTable = computed(() => {
    return route.name === "table-admin-grupoacesso";
  });
  
  const isCreate = computed(() => {
    return store.isCreate;
  });
  
  const goToNewGroupAccess = () => {
    router.push("admin-grupoacesso/create");
  };

  const refreshTable = () => {
  store.pagination = {
    sortBy: "title",
    descending: false,
    page: 1,
    rowsPerPage: 50,
    rowsNumber: store.pagination.rowsNumber,
};
store.createRows();
};
const onTableClick = () => {
  refreshTable();
};
  </script>
  