const enBundles = {
    labels: {
        user: 'User',
        password: 'Password',
    },
    cmds: {
        WEBOPENBRO: 'Abrir Browser',
        WEBFINDELE: 'Encontrar Elemento'
    }
};

export default enBundles;