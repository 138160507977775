import { apiDev } from "@/services/api";
import { getInitialLetters } from "@/utils/useUtils";

export default {
  async getComments(id) {
    this.loadingComments = true;
    const res = await this.getApi(
      "get",
      "/handler/v2/agile/comments?parentId=" + id
    );
    if (res.success) {
      let newdata = [];
      for (let item of res.data) {
        let newitem = { ...item };

        const user = await this.storeUsers.getUserById(item.created);

        if (user.photourl) {
          newitem["photourl"] = user.photourl;
        } else {
          newitem["avatar"] = getInitialLetters(user.name);
          newitem["name"] = user.name;
        }
        newdata.push(newitem);
      }
      this.comments = newdata.reverse();
    }
    this.loadingComments = false;
  },

  async addComment(comment, type = "T") {
    const query = "?parentType=" + type;
    const res = await this.getApi(
      "post",
      "/handler/v2/agile/comments" + query,
      comment
    );

    if (res.success) {
      const comment = await this.getCommentById(res.data.commentId);
      this.loadingComments = false;
      return { success: true, comment };
    } else {
      return res;
    }
  },
  async updateComment(id, comment, type = "T") {
    const query = "?parentType=" + type;

    comment["updatedById"] = Number(this.storeLogin.userId);

    const res = await this.getApi(
      "put",
      "/handler/v2/agile/comments/" + id + query,
      comment
    );

    if (res.success) {
      let comment = await this.getCommentById(id);
      this.loadingComments = false;
      return {
        success: true,
        comment,
      };
    }
    return res;
  },

  async getCommentById(id) {
    const res = await this.getApi("get", "/handler/v2/agile/comments/" + id);

    if (res.success) {
      let newcomment = { ...res.data };

      const user = await this.storeUsers.getUserById(newcomment.created);
      if (user.photourl) {
        newcomment["photourl"] = user.photourl;
      } else {
        newcomment["avatar"] = getInitialLetters(user.name);
        newcomment["name"] = user.name;
      }

      return newcomment;
    }
  },

  async deleteComment(id, parentId, type) {
    const query = `?parentType=${type}&parentId=${parentId}`;
    return await apiDev
      .delete(`/handler/v2/agile/comments/${id}${query}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.error(err);
        throw err;
      });
  },
};
