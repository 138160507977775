const esBundles = {
    labels: {
        user: 'Usuario',
        password: 'Contraseña'
    },
    login: {
        typeUser: 'Escriba su nombre de usuario',
        typePassword: 'Escriba su contraseña',
    },
    cmds: {
        WEBOPENBRO: 'Abrir Browser',
        WEBFINDELE: 'Encontrar Elemento'
    }
};

export default esBundles;