import { defineStore } from "pinia";
import { apiDev } from "@/services/api";

export const useMailStore = defineStore("mail", {
  state: () => ({
    mail: [],
    countPage: 1,
    totalRows: 0,
    currentRow: 1,
    ultimateRow: 20,
    currentItem: {},
  }),

  actions: {
    async getMail() {
      await apiDev
        .get(`/adm/v2/mail/?page=${this.countPage}`)
        .then((response) => {
          this.mail = response.data.data.rows;
          console.log(this.mail);
          this.totalRows = response.data.data.totalRows;
        })
        //eslint-disable-next-line
        .catch((error) => {
          console.log("MAIL", error);
        });
    },
  },
});
