<template>
  <ul :style="nodeMargin">
    <div v-for="(item, index) in nodes" :key="index">
      <div
        class="flex bg-orange200 cursor-pointer shadow-sm hover:bg-orange300 hover:shadow-md my-2"
        @click.stop="$emit('selectItem', item)"
      >
        <q-item-section avatar>
          <q-toggle
            v-model="item.selected"
            :val="item.title"
            checked-icon="check"
            color="secondary"
            unchecked-icon="clear"
          />
        </q-item-section>
        <q-item-section>
          <q-item-label
            class="q-ml-md text-tiny capitalize text-grey700"
            :class="{ 'text-primary': item.selected }"
            >{{ item.title }}</q-item-label
          >
        </q-item-section>
        <q-btn
          v-if="item.children && item.children.length > 0"
          flat
          dense
          icon="expand_circle_down"
          color="secondary"
          @click.stop="() => (showChildNodes = !showChildNodes)"
          :class="`${showChildNodes ? '' : 'rotate-180'}`"
        />
      </div>
      <q-slide-transition>
        <TreeNode
          v-if="item.children && showChildNodes"
          :nodes="item.children"
          :spacing="spacing + 15"
          @selectItem="handleSelect"
        />
      </q-slide-transition>
    </div>
  </ul>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref } from "vue";

const props = defineProps({
  nodes: Array,
  spacing: Number,
  selecteds: Array,
});

const emits = defineEmits(["update:selecteds", "selectItem"]);

const showChildNodes = ref(true);
const handleSelect = (item) => {
  emits("selectItem", item);
};

const nodeMargin = computed(() => {
  return `margin-left: ${Number(props.spacing)}px`;
});
</script>
