export const TableData = {
  page: 1,
  perPage: 50,
  pageRows: 50,
  totalRows: 60,
  rows: [
    {
      id: 1,
      name: "Mary",
      // name: { data: "Mary", rowspan: 3 },
      age: 18,
      email: "email@email.com",
      rowspan: 1,
    },
    { id: 2, name: "Anne", age: 18, email: "email@email.com" },
    {
      id: 3,
      name: "Carol",
      age: 18,
      email: "email@email.com",
    },
    {
      id: 4,
      name: "John",
      // name: { data: "John", rowspan: 4 },
      age: 18,
      email: "email@email.com",
    },
    { id: 5, name: "Marco", age: 18, email: "email@email.com" },
    { id: 6, name: "Nick", age: 18, email: "email@email.com" },
    {
      id: 7,
      name: "Charlie",
      age: 18,
      email: "email@email.com",
    },
  ],

  cols: [
    {
      id: 68,
      order: 0,
      align: "left",
      data: "C68",
      type: "PP",
      label: "name",
      title: "name",
      sortable: true,
    },
    {
      id: 69,
      order: 1,
      align: "left",
      data: "C68",
      type: "PP",
      label: "age",
      title: "age",
      sortable: true,
    },
    {
      id: 69,
      order: 2,
      align: "left",
      data: "C68",
      type: "PP",
      label: "email",
      title: "email",
      sortable: false,
    },
  ],
};
