export default [
  // EXEMPLO DE UMA DASH UNICA COM OS NOVOS FILTROS
  {
    "id": 5,
    "title": "Cad. Agencia",
    "version": null,
    "children": [
      {
        "type": "header",
        "title": "Agência",
        "description": "",
        "children": [
          {
            "id": 92,
            "type": "button",
            "label": "",
            "icon": "fa fa-sync",
            "action": {
              "id": 72,
              "command": "refresh"
            },
            "styles": {
              "classes": {
                "bgColor": "ez-primary",
                "color": "ezc-white"
              }
            }
          },
          {
            "id": 5,
            "type": "button",
            "label": "Agência",
            "icon": "fa fa-plus",
            "action": {
              "id": 10,
              "command": "ADD_ENT",
              "idBF": 4,
              "vdUK": 5
            },
            "styles": {
              "classes": {
                "bgColor": "ez-primary",
                "color": "ezc-white"
              }
            }
          },
          {
            "id": 115,
            "type": "button",
            "label": "Gerar Tarefas",
            "icon": "",
            "action": {
              "id": 92,
              "command": "ADD_ENT",
              "idBF": 27,
              "vdUK": 353
            },
            "styles": {
              "classes": {
                "bgColor": "ez-info",
                "color": "ezc-white"
              }
            }
          },
          {
            "id": 93,
            "type": "button",
            "label": "Exportar",
            "icon": "fa fa-file-excel",
            "action": {
              "id": 73,
              "command": "NONE"
            },
            "styles": {
              "classes": {
                "bgColor": "ez-success",
                "color": "ezc-white"
              }
            }
          },
          {
            "id": 100,
            "type": "excel",
            "styles": {
              "classes": {
                "bgColor": "ez-success",
                "color": "ezc-white"
              }
            }
          }
        ]
      },
      {
        "type": "filters",
        "children": [
          {
            "id": 113,
            "type": "select",
            "placeholder": "Munic.",
            "action": {
              "referenceId": 113
            },
            "styles": {
              "classes": {}
            }
          },
          {
            "id": 108,
            "type": "select",
            "parent": 113,
            "parentRequired": true,
            "placeholder": "Coop",
            "action": {
              "referenceId": 108
            },
            "styles": {
              "classes": {}
            }
          },
          {
            "id": 109,
            "type": "select",
            "parent": 108,
            "parentRequired": true,
            "placeholder": "Tipo Decl.",
            "action": {
              "referenceId": 109
            },
            "styles": {
              "classes": {}
            }
          },
          {
            "id": 173,
            "type": "select",
            "parent": 109,
            "parentRequired": true,
            "placeholder": "Sistema",
            "action": {
              "referenceId": 173
            },
            "styles": {
              "classes": {}
            }
          },
          {
            "id": 98,
            "type": "select",
            "parent": 113,
            "parentRequired": true,
            "placeholder": "Coopa",
            "action": {
              "referenceId": 108
            },
            "styles": {
              "classes": {}
            }
          },
          {
            "id": 110,
            "type": "select",
            "parent": 108,
            "parentRequired": true,
            "placeholder": "Decla.",
            "action": {
              "referenceId": 109
            },
            "styles": {
              "classes": {}
            }
          },
          {
            "id": 112,
            "type": "select",
            "parent": 109,
            "parentRequired": true,
            "placeholder": "Sistemas",
            "action": {
              "referenceId": 173
            },
            "styles": {
              "classes": {}
            }
          },
          {
            "id": 119,
            "type": "select",
            "parent": 109,
            "parentRequired": true,
            "placeholder": "Casas",
            "action": {
              "referenceId": 173
            },
            "styles": {
              "classes": {}
            }
          },
          {
            "id": 102,
            "type": "select",
            "parent": 109,
            "parentRequired": true,
            "placeholder": "SBBB",
            "action": {
              "referenceId": 173
            },
            "styles": {
              "classes": {}
            }
          },
          {
            "id": 92,
            "type": "select",
            "parent": 109,
            "parentRequired": true,
            "placeholder": "AAA",
            "action": {
              "referenceId": 173
            },
            "styles": {
              "classes": {}
            }
          },
          {
            "id": 99,
            "type": "select",
            "parent": 109,
            "parentRequired": true,
            "placeholder": "Teste",
            "action": {
              "referenceId": 173
            },
            "styles": {
              "classes": {}
            }
          },
        ]
      },
      {
        "type": "body",
        "title": "Cad. Agencia",
        "mode": "single",
        "children": [
          {
            "type": "widget",
            "subtype": "grid",
            "id": 5,
            "title": ".aCad. Agencia",
            "autoload": true,
            "x": null,
            "y": null,
            "w": null,
            "h": null,
            "cols": [
              {
                "id": 14,
                "order": 0,
                "data": "C14",
                "align": "center",
                "type": "icon",
                "sortable": false,
                "icon": "fa fa-edit",
                "action": {
                  "id": 9,
                  "command": "EDITENT",
                  "vdUK": 5
                }
              },
              {
                "id": 69,
                "order": 1,
                "data": "C69",
                "type": "property",
                "sortable": true
              },
              {
                "id": 55,
                "order": 2,
                "data": "C55",
                "type": "property",
                "sortable": true
              },
              {
                "id": 13,
                "order": 3,
                "data": "C13",
                "label": "Agência",
                "type": "property",
                "sortable": true
              },
              {
                "id": 15,
                "order": 4,
                "data": "C15",
                "label": "Coop.",
                "type": "property",
                "sortable": true
              },
              {
                "id": 12,
                "order": 5,
                "data": "C12",
                "label": "Município",
                "type": "property",
                "sortable": true
              },
              {
                "id": 342,
                "order": 6,
                "data": "C342",
                "type": "property",
                "sortable": true
              },
              {
                "id": 198,
                "order": 7,
                "data": "C198",
                "label": "Coop. Mas.",
                "type": "property",
                "sortable": true
              },
              {
                "id": 50,
                "order": 8,
                "data": "C50",
                "label": "Ag. Master",
                "type": "property",
                "sortable": true
              },
              {
                "id": 197,
                "order": 9,
                "data": "C197",
                "type": "property",
                "sortable": true
              },
              {
                "id": 196,
                "order": 10,
                "data": "C196",
                "label": "Insc. Munic.",
                "type": "property",
                "sortable": true
              },
              {
                "id": 187,
                "order": 11,
                "data": "C187",
                "type": "property",
                "sortable": true
              },
              {
                "id": 295,
                "order": 12,
                "data": "C295",
                "type": "property",
                "sortable": true
              },
              {
                "id": 296,
                "order": 13,
                "data": "C296",
                "type": "property",
                "sortable": true
              },
              {
                "id": 194,
                "order": 14,
                "data": "C194",
                "type": "property",
                "sortable": true
              },
              {
                "id": 195,
                "order": 15,
                "data": "C195",
                "label": "Sistema",
                "type": "property",
                "sortable": true
              },
              {
                "id": 189,
                "order": 16,
                "data": "C189",
                "label": "Tipo Decl.",
                "type": "property",
                "sortable": true
              },
              {
                "id": 229,
                "order": 17,
                "data": "C229",
                "label": "Cert. Dig.",
                "type": "property",
                "sortable": true
              },
              {
                "id": 230,
                "order": 18,
                "data": "C230",
                "type": "property",
                "sortable": true
              },
              {
                "id": 199,
                "order": 19,
                "data": "C199",
                "label": "Antecipa?",
                "type": "property",
                "sortable": true
              },
              {
                "id": 202,
                "order": 20,
                "data": "C202",
                "label": "Resp. Pagto",
                "type": "property",
                "sortable": true
              },
              {
                "id": 164,
                "order": 21,
                "data": "C164",
                "label": "Inativo?",
                "type": "property",
                "sortable": true
              },
              {
                "id": 172,
                "order": 22,
                "data": "C172",
                "label": "Unif.?",
                "type": "property",
                "sortable": true
              },
              {
                "id": 241,
                "order": 23,
                "data": "C241",
                "label": "CC/Lucro SUREG",
                "type": "property",
                "sortable": true
              },
              {
                "id": 313,
                "order": 24,
                "data": "C313",
                "type": "property",
                "sortable": true
              },
              {
                "id": 314,
                "order": 25,
                "data": "C314",
                "label": "Últ. Atualiz.",
                "type": "property",
                "sortable": true
              }
            ]
          }
        ]
      }
    ]
  },
  // *************************************************************
  {
    id: 999,
    title: "Painel de Pagamentos",
    children: [
      {
        type: "header",
        title: "Pagamentos",
      },
      {
        type: "body",
        title: "Teste",
        version: null,
        description: "Teste Description",
        children: [
          {
            id: 233,
            type: "section",
            title: "",
            description: "",
            styles: {
              inline: {},
              classes: {
                justify: "justify-start",
                border: "w-full",
              },
            },
            children: [
              {
                id: 233,
                type: "col",
                title: "",
                description: "",
                styles: {
                  inline: {},
                  classes: {
                    justify: "justify-start",
                    border: "w-full",
                  },
                },
                children: [
                  {
                    id: 422,
                    type: "widget",
                    subtype: "card",
                    title: "Título do card",
                    description: "1000",
                    icon: "thumb_up",
                    styles: {
                      classes: {
                        tailwindClass: "bg-blue text-white",
                      },
                    },
                  },
                ],
              },
              {
                id: 233,
                type: "col",
                title: "",
                description: "",
                styles: {
                  inline: {},
                  classes: {
                    justify: "justify-start",
                    border: "w-full",
                  },
                },
                children: [
                  {
                    id: 422,
                    type: "widget",
                    subtype: "card",
                    title: "Título do card",
                    description: "1000",
                    icon: "thumb_up",
                    styles: {
                      classes: {
                        tailwindClass: "bg-blue text-white",
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  // *************************************************************
  {
    id: 244,
    title: "XYZ",
    version: "v_grid",
    children: [
      {
        type: "header",
        title: "Dash Versão Layout Grid",
        description: "Description",
        children: [
          // {
          //   id: 23,
          //   type: "image",
          //   url: "https://i.postimg.cc/LsQWQg6N/default-icon-logo.png",
          //   styles: {
          //     inline: {
          //       width: "2.3rem",
          //       height: "2.4rem",
          //       marginRight: "10px",
          //     },
          //   },
          // },
          // {
          //   id: 23,
          //   type: "input",
          //   icon: "search",
          //   placeholder:
          //     "Pesquise por uma palavra chave e pressione enter para pesquisar",
          //   styles: {
          //     inline: {
          //       width: "100%",
          //     },
          //   },
          //   action: {
          //     command: "filter",
          //     //  referenceId: 5,
          //     //  attr: "name",
          //     //  property: "funcionario",
          //   },
          // },
          // {
          //   type: "button",
          //   icon: "fa-location-arrow",
          //   styles: {
          //     inline: {
          //       background: "lightgreen",
          //     },
          //   },
          //   label: "My button Main",
          //   action: {
          //     command: "save",
          //     referenceId: 22,
          //   },
          // },
          // {
          //   id: 420,
          //   type: "excel",
          //   label: "button excel",
          //   icon: "fa fa-file-excel",
          //   action: {
          //     id: 483,
          //     command: "excel",
          //   },
          //   styles: {
          //     inline: {
          //       background: "lightgreen",
          //     },
          //   },
          // },
        ],
      },
      {
        type: "body",
        mode: "single",
        title: "Dash Versão Layout Grid",
        description: "Description",
        children: [
          {
            id: 134,
            type: "widget",
            subtype: "cardCustom",
            title: "widget smallbox 97",
            x: 8,
            h: 3,
            y: 0,
            w: 4,
            i: "4",
            htmlContent:
              "CgogIDxkaXYKICAgIG5hbWU9IkluaXRhbCBDb250ZW50IgogICAgc3R5bGU9IgogICAgICBkaXNwbGF5OiBmbGV4OwogICAgICB1bmRlZmluZWQ6IGNvbHVtbjsKICAgICAgd2lkdGg6IDEwMCU7CiAgICAgIHBhZGRpbmctdG9wOiA1cHg7CiAgICAgIHBhZGRpbmctYm90dG9tOiA1cHg7CiAgICAgIHBhZGRpbmctcmlnaHQ6IDVweDsKICAgICAgcGFkZGluZy1sZWZ0OiA1cHg7CiAgICAiCiAgPgogICAgPHAgc3R5bGU9InRleHQtYWxpZ246IGNlbnRlcjsgZm9udC1zaXplOiAxNnB4Ij5Uw610dWxvPC9wPgogICAgPGRpdiBzdHlsZT0iZGlzcGxheTogZmxleDsgd2lkdGg6IDEwMCUiPgogICAgICA8ZGl2CiAgICAgICAgc3R5bGU9IgogICAgICAgICAgd2lkdGg6IDEwMCU7CiAgICAgICAgICB0ZXh0LWFsaWduOiBjZW50ZXI7CiAgICAgICAgICBkaXNwbGF5OiBmbGV4OwogICAgICAgICAgYWxpZ24taXRlbXM6IGNlbnRlcjsKICAgICAgICAgIHBhZGRpbmctdG9wOiA1cHg7CiAgICAgICAgICBwYWRkaW5nLWJvdHRvbTogNXB4OwogICAgICAgICAgcGFkZGluZy1yaWdodDogNXB4OwogICAgICAgICAgcGFkZGluZy1sZWZ0OiA1cHg7CiAgICAgICAgIgogICAgICA+CiAgICAgICAgPHAgc3R5bGU9InRleHQtYWxpZ246IGNlbnRlcjsgZm9udC1zaXplOiAxNnB4Ij5Db2x1bmEgMTwvcD4KICAgICAgPC9kaXY+CiAgICAgIDxkaXYKICAgICAgICBzdHlsZT0iCiAgICAgICAgICB3aWR0aDogMTAwJTsKICAgICAgICAgIHRleHQtYWxpZ246IGNlbnRlcjsKICAgICAgICAgIGRpc3BsYXk6IGZsZXg7CiAgICAgICAgICBhbGlnbi1pdGVtczogZW5kOwogICAgICAgICAgcGFkZGluZy10b3A6IDVweDsKICAgICAgICAgIHBhZGRpbmctYm90dG9tOiA1cHg7CiAgICAgICAgICBwYWRkaW5nLXJpZ2h0OiA1cHg7CiAgICAgICAgICBwYWRkaW5nLWxlZnQ6IDVweDsKICAgICAgICAiCiAgICAgID4KICAgICAgICA8cCBzdHlsZT0idGV4dC1hbGlnbjogY2VudGVyOyBmb250LXNpemU6IDE2cHgiPjIzNDU2PC9wPgogICAgICA8L2Rpdj4KICAgIDwvZGl2PgogIDwvZGl2PgoK",
            cols: [],
          },
          {
            id: 422,
            type: "widget",
            subtype: "card",
            title: "Título do card",
            description: "1000",
            icon: "thumb_up",
            autoload: true,
            h: 3,
            w: 4,
            x: 0,
            y: 0,
            i: "0",
            // dataId: id dos dados que serão exibidos
            styles: {
              classes: {
                tailwindClass: "bg-blue text-white",
              },
            },
          },
          {
            id: 422,
            type: "widget",
            subtype: "card",
            title: "Título do card",
            description: "1000",
            icon: "thumb_up",
            autoload: true,
            h: 5,
            y: 0,
            w: 3,
            x: 4,
            i: "3",
            // dataId: id dos dados que serão exibidos
            styles: {
              classes: {
                tailwindClass: "bg-blue text-white",
              },
            },
          },

          // {
          //   id: 424,
          //   type: "widget",
          //   subtype: "card",
          //   title: "Título do card",
          //   description: "1000",
          //   icon: "thumb_up",
          //   styles: {
          //     classes: {
          //       tailwindClass: "bg-blue text-white",
          //     },
          //   },
          // },
          // ----------------------------
          // {
          //   id: 233,
          //   type: "section",
          //   title: "",
          //   description: "",
          //   styles: {
          //     inline: {},
          //     classes: {
          //       justify: "justify-start",
          //       border: "w-full pt-[10px] bg-orange300",
          //     },
          //   },
          //   children: [
          //     {
          //       type: "widget",
          //       subtype: "grid",
          //       formId: 2,
          //       autoload: true,
          //       id: "2teste",
          //       cols: [
          //         {
          //           data: "edit",
          //           type: "icon",
          //           width: "10px",
          //           key: "icon-01",
          //           style: {
          //             inline: {
          //               width: 80,
          //               height: 20,
          //               fontSize: 9,
          //             },
          //             classes: {
          //               backgroundColor: "ezbtn-blue",
          //             },
          //           },
          //           action: "edit",
          //           align: "left",
          //           icon: "fa-play",
          //           color: "ezc-gainsboro",
          //           bgColor: "ez-roxo-bg",
          //           filed: "",
          //           sortable: false,
          //         },
          //         {
          //           data: "edit",
          //           type: "icon",
          //           width: "10px",
          //           key: "icon-01",
          //           style: {
          //             inline: {
          //               width: "20px",
          //               height: "20px",
          //               fontSize: 12,
          //             },
          //             classes: {
          //               color: "ezc-white",
          //             },
          //           },
          //           action: "edit",
          //           align: "left",
          //           icon: "fa-pen",
          //           color: "ezbc-danger",
          //           filed: "",
          //           sortable: false,
          //         },
          //         {
          //           data: "delete",
          //           type: "icon",
          //           width: "10px",
          //           key: "icon-02",

          //           style: {
          //             inline: {
          //               width: "20px",
          //               height: "20px",
          //               fontSize: 12,
          //             },
          //             classes: {
          //               color: "ezc-white",
          //             },
          //           },
          //           action: "delete",
          //           align: "center",
          //           icon: "fa-trash",
          //           color: "ezbc-danger",
          //           field: "",
          //           sortable: false,
          //         },
          //         {
          //           data: "id",
          //           required: true,
          //           type: "property",
          //           title: "id",
          //           align: "left",
          //           field: "id",
          //           sortable: true,
          //         },
          //         {
          //           data: "condominio",
          //           align: "center",
          //           type: "property",
          //           title: "Condominio",
          //           field: "condominio",
          //           sortable: true,
          //         },
          //         {
          //           data: "number",
          //           align: "center",
          //           type: "property",
          //           title: "Numero",
          //           field: "number",
          //           sortable: true,
          //         },
          //         {
          //           data: "funcionario",
          //           align: "center",
          //           type: "property",
          //           title: "Funcionario",
          //           field: "funcionario",
          //           sortable: true,
          //         },
          //         {
          //           data: "CNPJ",
          //           title: "CNPJ",
          //           type: "property",
          //           field: "CNPJ",
          //           sortable: true,
          //         },
          //       ],
          //     },
          //     {
          //       type: "widget",
          //       subtype: "grid",
          //       formId: 2,
          //       autoload: true,
          //       id: "2teste",
          //       cols: [
          //         {
          //           data: "edit",
          //           type: "icon",
          //           width: "10px",
          //           key: "icon-01",
          //           style: {
          //             inline: {
          //               width: 80,
          //               height: 20,
          //               fontSize: 9,
          //             },
          //             classes: {
          //               backgroundColor: "ezbtn-blue",
          //             },
          //           },
          //           action: "edit",
          //           align: "left",
          //           icon: "fa-play",
          //           color: "ezc-gainsboro",
          //           bgColor: "ez-roxo-bg",
          //           filed: "",
          //           sortable: false,
          //         },
          //         {
          //           data: "edit",
          //           type: "icon",
          //           width: "10px",
          //           key: "icon-01",
          //           style: {
          //             inline: {
          //               width: "20px",
          //               height: "20px",
          //               fontSize: 12,
          //             },
          //             classes: {
          //               color: "ezc-white",
          //             },
          //           },
          //           action: "edit",
          //           align: "left",
          //           icon: "fa-pen",
          //           color: "ezbc-danger",
          //           filed: "",
          //           sortable: false,
          //         },
          //         {
          //           data: "delete",
          //           type: "icon",
          //           width: "10px",
          //           key: "icon-02",

          //           style: {
          //             inline: {
          //               width: "20px",
          //               height: "20px",
          //               fontSize: 12,
          //             },
          //             classes: {
          //               color: "ezc-white",
          //             },
          //           },
          //           action: "delete",
          //           align: "center",
          //           icon: "fa-trash",
          //           color: "ezbc-danger",
          //           field: "",
          //           sortable: false,
          //         },
          //         {
          //           data: "id",
          //           required: true,
          //           type: "property",
          //           title: "id",
          //           align: "left",
          //           field: "id",
          //           sortable: true,
          //         },
          //         {
          //           data: "condominio",
          //           align: "center",
          //           type: "property",
          //           title: "Condominio",
          //           field: "condominio",
          //           sortable: true,
          //         },
          //         {
          //           data: "number",
          //           align: "center",
          //           type: "property",
          //           title: "Numero",
          //           field: "number",
          //           sortable: true,
          //         },
          //         {
          //           data: "funcionario",
          //           align: "center",
          //           type: "property",
          //           title: "Funcionario",
          //           field: "funcionario",
          //           sortable: true,
          //         },
          //         {
          //           data: "CNPJ",
          //           title: "CNPJ",
          //           type: "property",
          //           field: "CNPJ",
          //           sortable: true,
          //         },
          //       ],
          //     },
          //   ],
          // },
        ],
      },
    ],
  },
  // ***************************************************************************
  {
    id: 24,
    title: ".PM - Painel Médico N",
    version: null,
    children: [
      {
        type: "header",
        title: "",
        description: "",
        children: [
          {
            id: 233,
            type: "input",
            icon: "search",
            model: "",
            placeholder:
              "Pesquise por uma palavra chave e pressione enter para pesquisar",
            styles: {
              inline: {
                width: "100%",
              },
            },
            action: {
              command: "filter",
              //  referenceId: 5,
              //  attr: "name",
              //  property: "funcionario",
            },
          },
          {
            id: 234,
            type: "input",
            icon: "search",
            model: "abc",
            placeholder:
              "Pesquise por uma palavra chave e pressione enter para pesquisar",
            styles: {
              inline: {
                width: "100%",
              },
            },
            action: {
              command: "filter",
              //  referenceId: 5,
              //  attr: "name",
              //  property: "funcionario",
            },
          },
        ],
      },
      {
        type: "body",
        mode: "single",
        title: ".Processo - Obrigacoes Fiscais",
        children: [
          {
            id: "table_test",
            type: "widget",
            subtype: "grid",
            title: ".Processo - Obrigacoes Fiscais",
            autoload: true,
            // selection: null,
            cols: [
              {
                id: 1006,
                order: 0,
                data: "C1006",
                title: "Editar",
                label: "Editar",
                type: "icon",
                sortable: true,
              },
              {
                id: 564,
                order: 1,
                data: "C564",
                title: "COD CARGO",
                label: "COD CARGO",
                type: "property",
                sortable: true,
              },
              {
                id: 565,
                order: 2,
                data: "C565",
                title: "DESCRIÇÃO",
                label: "DESCRIÇÃO",
                type: "property",
                sortable: true,
              },
              {
                id: 1007,
                order: 3,
                data: "C1007",
                title: "Excluir",
                label: "Excluir",
                type: "icon",
                sortable: true,
              },
            ],
          },
        ],
      },
    ],
  },
];
