import { apiDev } from "../../services/api";
import formApi from "@/api-examples/formApi";
// import { useTesteGridLayout } from "./teste-create-layout";

import { defineStore } from "pinia";
// import axios from 'axios';
import dashApiExemple from "@/api-examples/dashApiExemple";
// eslint-disable-next-line
import { apiBackExample } from "@/api-examples/dashBackExample";
import apiTable from "@/api-examples/api-table";
export const useDash = defineStore("dash", {
  state: () => ({
    data: [],
    dataForm: {},
    options: [],
    currentId: null,
    headersHeight: {},
  }),
  actions: {
    setJsonData(data) {
      this.data["data"] = data;
    },
    //eslint-disable-next-line
    async getData(id) {
      const dataId = id;
      this.currentId = id;
      if (dataId.includes("teste")) {
        this.data = dashApiExemple.find(
          (dash) => dash.id == dataId.split("teste")[0]
        );
        return;
      }
      if (dataId === "23a") {
        this.data = dashApiExemple.find((api) => api.id == 24);

        return;
      } else if (dataId === "999") {
        this.data = apiBackExample.find((element) => element.id === 24);
        return;
      }

      const res = await apiDev
        .get(`/handler/v2/dash/load/${dataId}`)
        .then((res) => res.data)
        .catch((err) => err);
      // console.log(res);
      this.data = res.success ? res.data : res;
      // console.log("RESPONSE DA DASH", res.data);
    },
    async getForm(id) {
      //// console.log(id);
      const res = formApi.find((api) => api.id == id);
      this.dataForm = res.data;
    },
    async getOptions(id) {
      const data = (await apiTable).find((opt) => opt.id === id);
      this.options = data.options;
    },
  },
});
