<template>
  <div class="h-screen w-full">
    <ul class="flex flex-col items-center justify-center w-full">
      <li class="space-y-2 pb-3">
        <q-skeleton
          v-for="(item, index) in 11"
          :key="index.id"
          class="bg-blackGreen"
          type="rect"
          :width="`${sideBarOpened ? '218px' : '30px'}`"
          height="30px"
        />
      </li>
      <ul :class="`space-y-2 pt-7 ${1 !== 1 ? 'text-start pl-3' : 'text-center'}`">
        <q-skeleton
          v-for="(item, index) in 2"
          :key="index.id"
          class="bg-blackGreen"
          type="rect"
          :width="`${sideBarOpened ? '218px' : '30px'}`"
          height="30px"
        />
      </ul>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    sideBarOpened: { type: Boolean, default: true }
  },
  name: "MenuSkeletonComponent",
};
</script>
