import script from "./changeImageComponent.vue?vue&type=script&setup=true&lang=js"
export * from "./changeImageComponent.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QUploader from 'quasar/src/components/uploader/QUploader.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QToolbar,QBtn,QAvatar,QUploader,QSkeleton});qInstall(script, 'directives', {ClosePopup});
