<template>
  <component
    v-for="(current, index) in components"
    :key="index"
    :is="current.component"
    v-bind="getAllProperties(current)"
  >
    <!-- ESTILO DO CONTEÚDO DO MODAL EM CIMA -->
    <dash-children-components
      v-if="
        current.data.children &&
        current.data.children.length > 0 &&
        props.data.children !== null
      "
      :data="current.data"
    />
  </component>
</template>

<script setup>
import {
  defineProps,
  defineAsyncComponent,
  getCurrentInstance,
  ref,
} from "vue";
import ErrorComponent from "./Cmps/ErrorComponent.vue";
import LoadingComponent from "./Cmps/LoadingComponent.vue";

const props = defineProps(["data"]);
const compError = ref("");
// console.log(props.data)
/**
 * Função responsavel por enviar as props corretas para cada componente
 * @param {object} current
 */
function getAllProperties(current) {
  const instance = getCurrentInstance();
  const infoComponent = instance.parent.data;
  let props = {
    currentProps: infoComponent === undefined ? compError.value : current.data,
  };
  //Usar esse exemplo para passar props separadas no body e no header
  // if (instance.parent.data.body) {
  //     props = {
  //         ...props,
  //         actions: actions.value
  //     }
  // }
  return props;
}

/**
 * Função responsável por montar o nome do componente para importação
 * @param {object} child
 */
function setNameComponent(child) {
  if (Object.keys(child).length === 0) {
    return "Error";
  }
  if (child.type === "widget") {
    if (child.subtype.includes("-")) {
      return (
        "widget/" +
        child.subtype
          .split("-")
          .map((c) => c[0].toUpperCase() + c.substr(1))
          .join()
          .replace(/,/g, "")
      );
    } else {
      return (
        "widget/" + child.subtype[0].toUpperCase() + child.subtype.substr(1)
      );
    }
  } else if (child.ptype) {
    return "PP/" + child.ptype[0].toUpperCase() + child.ptype.substr(1);
  } else {
    if (child.type.includes("-")) {
      return child.type
        .split("-")
        .map((c) => c[0].toUpperCase() + c.substr(1))
        .join()
        .replace(/,/g, "");
    } else {
      return child.type[0].toUpperCase() + child.type.substr(1);
    }
  }
}

/**
 * Função responsável por importar os componentes
 */
function componentsImport() {
  //Interar sob os item da props com os children
  return props.data.children && props.data.children.length > 0
    ? props.data.children.map((child) => {
        compError.value = child; // Pega os dados do filho para enviar para o componente de erro se preciso
        //Monta um novo objeto com o componente importado para exibir
        return {
          data: { ...child },
          //Chamar os componentes
          component: defineAsyncComponent({
            loader: () => import(`./Cmps/${setNameComponent(child)}Dash.vue`),
            delay: 200,
            loadingComponent: LoadingComponent,
            errorComponent: ErrorComponent,
            timeout: 3000,
          }),
        };
      })
    : null;
}
const components = componentsImport();
</script>

<style></style>
