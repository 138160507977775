import { defineStore } from "pinia";
import { apiDev } from "@/services/api";

export const useViewsStore = defineStore("visao", {
  state: () => ({
    views: [],
    loading: false,
    loadingPublish: false,
    textSearch: "",
    pagination: {
      sortBy: "title",
      descending: false,
      page: 1,
      rowsPerPage: 50,
      rowsNumber: 0,
    },
    // REFERENTE A NAVEGAÇÃO
    showMenuView: true,
    isCreate: false,
    disable: true,
    currentTab: "settings",
    currentPage: true,
    currentView: null,
    defaultView: null,
    layout: [],
    selecteds: [],
    viewToSave: null,
  }),
  getters: {
    clearSelected(state) {
      return state.selecteds.length === 0 ? true : false;
    },
  },
  actions: {
    async getAllViews() {
      this.loading = true;
      let filters = {
        page: this.pagination.page,
        perPage: this.pagination.rowsPerPage,
        order: `${this.pagination.sortBy}_${
          this.pagination.descending ? "desc" : "asc"
        }`,
        title: this.textSearch,
      };
      let queries = this.mountQueries(filters);
      await apiDev
        .get(`/adm/v2/dash?${queries}`)
        .then((response) => {
          this.views = response.data.data.rows;
          this.pagination["rowsPerPage"] = response.data.data.perPage;
          this.pagination["page"] = response.data.data.page;
          this.pagination["rowsNumber"] = response.data.data.totalRows;
        })
        .catch((error) => {
          console.error("VISAO", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /* Traz a visão pelo seu Id */
    async getById(id) {
      const result = await apiDev
        .get("/adm/v2/dash/" + id)
        .then((res) => res.data)
        .catch((err) => err);
      return result;
    },

    /* Cria uma nova visão */
    async createView(newView) {
      const res = await apiDev
        .post("/adm/v2/dash", newView)
        .then((res) => res.data)
        .catch((err) => err);
      return res;
    },

    /* Atualiza uma visão */
    async updateView(id, newView) {
      const res = await apiDev
        .put("/adm/v2/dash/" + id, newView)
        .then((res) => res.data)
        .catch((err) => err);
      return res;
    },

    /* Deleta uma visão */
    async deleteView(viewId) {
      return await apiDev
        .delete(`/adm/v2/dash/${viewId}`)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          console.error(err);
          throw err;
        })
        .finally(() => {});
    },

    async cloneView(viewId) {
      return await apiDev
        .post(`/adm/v2/dash-copy/${viewId}`)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          console.error(err);
          throw err;
        })
        .finally(() => {
          this.loadingPublish = false;
        });
    },

    async publishView(id, comment) {
      this.loadingPublish = true;
      return await apiDev
        .post(`/adm/v2/dash/pub/${id}`, comment)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          console.error(err);
          throw err;
        })
        .finally(() => {
          this.loadingPublish = false;
        });
    },

    async setPagination(pagination) {
      this.pagination = {
        sortBy: pagination.sortBy,
        descending: pagination.descending,
        page: pagination.page,
        rowsPerPage: pagination.rowsPerPage,
        rowsNumber: pagination.rowsNumber,
      };

      await this.getAllViews();
    },

    mountQueries(filters) {
      return Object.entries(filters)
        .map(([key, value]) => {
          if (key === "filters") {
            return `${encodeURIComponent(key)}=${encodeURIComponent(
              JSON.stringify(value)
            )}`;
          } else if (Array.isArray(value)) {
            return value
              .map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`)
              .join("&");
          } else {
            return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
          }
        })
        .join("&");
    },
  },
});
