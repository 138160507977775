import { defineStore } from "pinia";

import activities_actions from "./activities_actions";
import tasks_actions from "./tasks_actions";
import data_mount_actions from "./data_mount_actions";
import project_actions from "./project_actions";
import gerais_actions from "./gerais_actions";
import organizers_actions from "./organizers_actions";
import boards_action from "./boards_action";
import data from "./data";
import comments_action from "./comments_action";
import checklist_action from "./checklist_action";
import sprint_action from "./sprint_action";

export const useProjects = defineStore("projects", {
  state: data,
  actions: {
    ...gerais_actions,
    ...organizers_actions,
    ...project_actions,
    ...data_mount_actions,
    ...activities_actions,
    ...tasks_actions,
    ...comments_action,
    ...checklist_action,
    ...boards_action,
    ...sprint_action
  },
});
