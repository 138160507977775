export const GlobalsPT = {
    save:'Salvar',
    cancel:'Cancelar',
  };
  
  export const GlobalsEN = {
    save:'Ahorrar',
    cancel:'Cancelar',
  };
  
  export const GlobalsES = {
    save:'Save',
    cancel:'Cancel',
  };
  