export const SettingsMenuPT = {
  title: "Configurações",
  profile: "Perfil",
  Appearance: "Aparência",
  AppearanceSubtitle: "Configure opções de aparência da aplicação.",
  Email: "Email",
  EmailSubtitle: "Gerencie as configurações de e-mail",
  projectsManagement: "Gestão de Projetos",
  projectsManagementSubtitle: "Agrupe projetos como desejar",
  logo: "Logotipo",
  logoSubtitle: "Selecione uma imagem para a página de login.",
  colors: "Cores Internas",
  colorsSubtitle: "Selecione as cores da aplicação",
  layout: "Layout do Login",
  layoutSubtitle: "Selecione uma opção de layout para a página de login.",
  loginWithGoogle: "Entrar com o Google",
  loginWithGoogleSubtitle: "Habilite a opção de entrar com o Google.",
  theme: "Tema",
  themeSubtitle: "Selecione um tema para a interface",
  logoIcon: "Logo Ícone",
  logoIconSubtitle: "Selecione uma imagem com o logo icone da sua empresa. Ela irá aparecer no topo do menu.",
  logoName: "Logo Nome",
  logoNameSubtitle: "Selecione uma imagem com o nome da sua empresa. Ela irá aparecer quando o menu estiver aberto.",
  selectedImage: "Escolha uma imagem",
  selectedImageSubtitle: "JPG, PNG, WEBP, Até 50 MB",
  searchImage: "Procurar Imagem",
  menus: "Menus",
  primaryColor: "Cor primária",
  secondaryColor: "Cor secundária",
  saveButton: "Salvar",
  backgroundColor: "Cor do plano de fundo",
  actionColor: "Cor da ação",
  customize: "Personalizar",
  modelPreview: "Pré-visualização do modelo.",
  colorSelection: "Seleção de cores",
  successSaveConfig: "Configurações salvas com sucesso.",
  errorSaveConfig: "Ops! Algo errado aconteceu.",
};
export const SettingsMenuEN = {
  title: "Settings",
  profile: "Profile",
  Appearance: "Appearance",
  AppearanceSubtitle: "Configure application appearance options.",
  Email: "Email",
  EmailSubtitle: "Manage email settings",
  projectsManagement: "Project Management",
  projectsManagementSubtitle: "Manage projects as desired",
  logo: "Logo",
  logoSubtitle: "Select an image for the login page.",
  colors: "Internal Colors",
  colorsSubtitle: "Select application colors",
  layout: "Login Layout",
  layoutSubtitle: "Select a layout option for the login page.",
  loginWithGoogle: "Sign in with Google",
  loginWithGoogleSubtitle: "Enable the option to sign in with Google.",
  theme: "Theme",
  themeSubtitle: "Select a theme for the interface",
  logoIcon: "Logo Icon",
  logoIconSubtitle: "Select an image with your company's logo icon. It will appear at the top of the menu.",
  logoName: "Logo Name",
  logoNameSubtitle: "Select an image with your company's name. It will appear when the menu is open.",
  selectedImage: "Choose an image",
  selectedImageSubtitle: "JPG, PNG, WEBP, Up to 50 MB",
  searchImage: "Search for Image",
  menus: "Menus",
  primaryColor: "Primary color",
  secondaryColor: "Secondary color",
  saveButton: "Save",
  backgroundColor: "Background Color",
  actionColor: "Action Color",
  customize: "Customize",
  modelPreview: "Model preview.",
  colorSelection: "Color selection",
  successSaveConfig: "Settings saved successfully.",
  errorSaveConfig: "Oops! Something went wrong.",
};
export const SettingsMenuES = {
  title: "Configuración",
  profile: "Perfil",
  Appearance: "Apariencia",
  AppearanceSubtitle: "Configura las opciones de apariencia de la aplicación.",
  Email: "Email",
  EmailSubtitle: "Gestiona la configuración del Email.",
  projectsManagement: "Gestión de Proyectos",
  projectsManagementSubtitle: "Agrupa proyectos según desees.",
  logo: "Logo",
  logoSubtitle: "Selecciona una imagen para la página de inicio de sesión.",
  colors: "Colores Internos",
  colorsSubtitle: "Selecciona los colores de la aplicación.",
  layout: "Layout de Login",
  layoutSubtitle: "Selecciona una opción de layout para la página de login.",
  loginWithGoogle: "Iniciar sesión con Google",
  loginWithGoogleSubtitle: "Habilita la opción de iniciar sesión con Google.",
  theme: "Tema",
  themeSubtitle: "Selecciona un tema para la interfaz.",
  logoIcon: "Logo Icono",
  logoIconSubtitle: "Selecciona una imagen con el icono del logo de tu empresa. Aparecerá en la parte superior del menú.",
  logoName: "Logo Nombre",
  logoNameSubtitle: "Selecciona una imagen con el nombre de tu empresa. Aparecerá cuando el menú esté abierto.",
  selectedImage: "Elige una imagen",
  selectedImageSubtitle: "JPG, PNG, WEBP, hasta 50 MB",
  searchImage: "Buscar Imagen",
  menus: "Menús",
  primaryColor: "Color primario",
  secondaryColor: "Color secundario",
  saveButton: "Guardar",
  backgroundColor: "Color de fondo",
  actionColor: "Color de acción",
  customize: "Personalizar",
  modelPreview: "Vista previa de la plantilla.",
  colorSelection: "Selección de colores",
  successSaveConfig: "La configuración se guardó correctamente.",
  errorSaveConfig: "¡Ups! Sucedió algo malo.",
};

export const ProfilePT = {
  title: "Perfil",
  subTitle: "",
  basicInformation: "",
  basicInformationSubtitle: "",
  userImage: "",
  userImageSubtitle: "",
  workStatus: "",
  workStatusSubtitle: "",
  workStatusInTheOffice: "",
  workStatusAbsent: "",
  workStatusAtHome: "",
  workStatusOnVocation: "",
  workStatusStartVocation: "",
  workStatusEndVocation: "",
  workStatusHintVocation: "",
};

export const ProfileEN = {};

export const ProfileES = {};

export const SettingsInputPT = {};

export const SettingsInputEN = {};

export const SettingsInputES = {};
