<template>
  <q-input
    v-model="value"
    :label="label"
    :placeholder="placeholder"
    :for="IdDoInput"
    :hint="hint"
    :type="type"
    :color="color"
    :error="error"
    outlined
    :clearable="clearable"
    @keyup.enter="() => $emit('keyUpEvent')"
    label-color="grey"
    :prefix="prefix"
    :suffix="suffix"
    :input-style="{ fontWeight: '600' }"
    :mask="mask"
    fill-mask
    debounce="500"
  >
    <template v-slot:prepend>
      <q-icon :name="icon" color="dark" v-if="icon !== undefined" />
    </template>
  </q-input>
</template>

<script>
export default {
  name: "InputQuasarComponent.vue",
  props: [
    "label",
    "placeholder",
    "IdDoInput",
    "hint",
    "type",
    "color",
    "icon",
    "error",
    "modelValue",
    "clearable",
    "prefix",
    "suffix",
    "mask",
  ],
  emits: ["update:modelValue", "keyUpEvent"],
  data() {
    return {
      text: "",
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>
