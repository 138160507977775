import { createApp } from "vue";
import App from "./App.vue";
import router from "./routes/router";
import i18n from "./i18n/i18n";

import VueDragscroll from "vue-dragscroll";
import { createPinia } from "pinia";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import GoogleSignInPlugin from "vue3-google-signin";
import mockServer from "./mockServer.js";
import JsonViewer from "vue-json-viewer";
import VueTailwindDatepicker from "vue-tailwind-datepicker";
import utils from "@/plugins/utils";

// Importação de arquivos de estilo
import "../assets/css/index.css";
import "../../global/fontawesome/css/all.min.css";
import "../public/css/EzGlobalWeb.css";
import "vue-json-viewer/style.css";

import { useHomeConfig } from "@/store/homeConfigStore.js"; // Importe sua store aqui

const app = createApp(App);

const pinia = createPinia(); // Crie o Pinia primeiro

app.use(pinia); // Use o Pinia na aplicação

const store = useHomeConfig(); // Crie uma instância da sua store

// Acesse a store para configurar dinamicamente o Quasar
app.use(Quasar, {
  ...quasarUserOptions,
  config: {
    brand: {
      primary: store.actionColor, // Use actionColor da sua store como cor primária
      secondary: store.secondaryColor,
      dark: store.menuColor,
      // outras cores podem ser configuradas aqui, como secondary, accent, etc.
    },
  },
});

app.use(JsonViewer);

app.use(utils);

i18n(app);

app.use(router);

app.use(GoogleSignInPlugin, {
  clientId:
    "1023896055843-bf1bab4320btp6c5np16bro515hma029.apps.googleusercontent.com",
});

app.use(VueTailwindDatepicker);

app.use(VueDragscroll);

if (process.env.NODE_ENV === "development") {
  mockServer();
}

app.mount("#app");
