import { defineStore } from "pinia";
import checkDataChange from "@/utils/checkDataChange";

export const useAccessGroup = defineStore("companies", {
  state: () => ({
    isCreate: false,
    groups: [],
    loading: false,
    order: "id_desc",
    pagination: {
      sortBy: "desc",
      descending: false,
      page: 1,
      rowsPerPage: 50,
      // rowsNumber: 0,
    },
    selectActive: "Y",
  }),
  getters: {},
  actions: {
    createRows() {
      const filters = {
        page: this.pagination.page,
        perPage: this.pagination.rowsPerPage,
        intSrc: this.brand,
        status: this.status,
      };

      const queryString = Object.entries(filters)
        .map(([key, value]) => {
          if (Array.isArray(value)) {
            return value
              .map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`)
              .join("&");
          } else {
            return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
          }
        })
        .join("&");

      console.log(queryString);
      // -----------------------------------
      this.loading = true;
      const newArray = new Array(60).fill({}).map((_, index) => {
        const randomNumber = Math.floor(Math.random() * 1000);

        return {
          id: randomNumber,
          title: `Name_${index + 1}`,
          isActive: index % 2 === 0,
          createdAt: new Date(),
          createdBy: { id: randomNumber, name: `Name_${index + 1}` },
          updatedAt: new Date(),
          updatedBy: { id: randomNumber, name: `Name_${index + 1}` },
        };
      });

      const data = {
        page: 1,
        pageRows: 3,
        perPage: 50,
        rows: newArray,
        totalRows: newArray.length,
      };

      this.pagination["page"] = data.page;
      this.pagination["rowsPerPage"] = data.perPage;
      // this.pagination["rowsNumber"] = response.data.totalRows;
      this.groups = data.rows;
      this.loading = false;
    },
    async setPagination(pagination) {
      console.log("HELPPPP", pagination);
      const dataPaginationChanged = checkDataChange(
        this.pagination,
        pagination
      );
      const keysWithValuesChanged = Object.keys(
        dataPaginationChanged.dataChanged
      );
      //// console.log(dataPaginationChanged);
      if (keysWithValuesChanged.every((key) => key === "page"))
        this.pagination["page"] = pagination.page;
      if (keysWithValuesChanged.every((key) => key === "sortBy"))
        this.pagination["sortBy"] = pagination.sortBy;
      if (
        keysWithValuesChanged.every((key) => key === "descending") ||
        (keysWithValuesChanged.some((key) => key === "page") &&
          keysWithValuesChanged.some(
            (key) => key === "descending" || key === "sortBy"
          ))
      ) {
        this.pagination["descending"] = pagination.descending;
        this.pagination["sortBy"] = pagination.sortBy;
        this.order = `${pagination.sortBy}_${
          pagination.descending ? "desc" : "asc"
        }`;
      }
      await this.getAllViews();
    },
  },
});
