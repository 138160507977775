<template>
  <q-dialog v-model="carousel">
    <div
      class="bg-grey100 z-20 shadow-lg overflow-y-hidden"
      style="width: 50%; max-width: 80vw"
    >
      <q-toolbar class="bg-lightGreen text-white">
        <q-toolbar-title class="uppercase font-medium text-body1">
          Novo Projeto
        </q-toolbar-title>
        <q-btn flat round dense icon="close" v-close-popup />
      </q-toolbar>

      <div
        id="projects"
        class="scroll overflow-auto w-full h-full p-4 bg-white"
        style="max-height: 85vh"
      >
        <form class="space-y-2 w-full">
          <div v-if="!store.withoutGroup" class="space-y-1">
            <q-item-label class="text-xs font-medium text-grey600"
              >Dê um nome para o seu projeto:</q-item-label
            >
            <SelectComponent
              icon="store"
              style="width: 100%"
              label="Selecione a Empresa *"
              :showAvatar="true"
              optionLabel="title"
              optionValue="id"
              :options="options"
              v-model="store.currentProject.organizer"
              :error="validateClient"
            />
          </div>
          <div class="space-y-1">
            <q-item-label class="text-xs font-medium text-grey600"
              >Dê um nome para o seu projeto:</q-item-label
            >
            <InputComponent
              style="width: 100%"
              label="Nome do projeto *"
              type="text"
              v-model="store.currentProject.title"
              maxlength="50"
              :error="validateName"
            />
          </div>
          <div class="space-y-1 pb-[20px]">
            <q-item-label class="text-xs font-medium text-grey600"
              >Defina um responsável pelo projeto:</q-item-label
            >
            <SelectComponent
              icon="store"
              style="width: 100%"
              label="Responśavel pelo projeto "
              :showAvatar="true"
              optionLabel="name"
              optionValue="id"
              :options="memberOptions"
              v-model="store.currentProject.headData"
            />
          </div>
          <div class="space-y-1">
            <q-item-label class="text-xs font-medium text-grey600"
              >Data final estimada:</q-item-label
            >
            <q-btn
              unelevated
              color="primary"
              :label="modelDatePicker"
              style="width: 100%"
            >
              <q-menu>
                <q-date
                  v-model="store.currentProject.endDate"
                  minimal
                  dense
                  mask="YYYY-MM-DD HH:mm:ss"
                />
              </q-menu>
            </q-btn>
          </div>

          <div class="flex justify-end w-full p-2">
            <q-btn
              flat
              dense
              label="salvar"
              color="primary"
              @click="emit('save')"
              :loading="loading"
            />
          </div>
        </form>
      </div>
    </div>
  </q-dialog>
</template>

<script setup>
import { defineProps, defineEmits, computed } from "vue";
import moment from "moment";

import "v-calendar/dist/style.css";
import SelectComponent from "../../../../../global/componentsVue/selectComponent/selectObjectComponent.vue";
import InputComponent from "../../../../../global/componentsVue/inputComponent/inputQuasarComponent.vue";
import { useProjects } from "@/store/products/projects";

const store = useProjects();
const props = defineProps([
  "openNewProject",
  "users",
  "clientValidate",
  "nameValidate",
  "loading",
]);
const loading = computed(() => props.loading);
const emit = defineEmits([
  "update:openNewProject",
  "save",
  "update:clientValidate",
  "update:nameValidate",
]);

const modelDatePicker = computed(() => {
  return moment(store.currentProject.endDate, "YYYY-MM-DD HH:mm:ss").format(
    "DD/MM/YYYY"
  );
});

const carousel = computed({
  get() {
    return props.openNewProject;
  },
  set(newValue) {
    emit("update:openNewProject", newValue);
  },
});
const validateClient = computed({
  get() {
    return props.clientValidate;
  },
  set(newValue) {
    emit("update:clientValidate", newValue);
  },
});
const validateName = computed({
  get() {
    return props.nameValidate;
  },
  set(newValue) {
    emit("update:nameValidate", newValue);
  },
});

const options = computed(() => {
  return store.organizers.map((company) => ({
    ...company,
    avatar: company.logo,
  }));
});

const memberOptions = computed(() => props.users);
</script>
