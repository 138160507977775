import script from "./tableRowAndColSpan.vue?vue&type=script&setup=true&lang=js"
export * from "./tableRowAndColSpan.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QTd from 'quasar/src/components/table/QTd.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTable,QTr,QTh,QTd});
