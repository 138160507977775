export const LoginPT = {
  password_login: "Senha",
  forgotPassword: "Esqueceu a senha?",
  keepLogged: "Mantenha-me conectado",
  addTask: "Adicionar tarefa",
  user: "Nome de usuário",
  password: "Senha",
  buttonEnter: "Entrar",
  googleButton: "Entrar com o Google",
  success: "Sucesso",
  sucessMessage: "Você está logado",
  error: "Erro",
  errorMessage: "Usuário ou senha incorreto",
  welcome: "Bem-vindo.",
  loginToContinue: "Faça login para continuar.",
  typeUser: "Digite seu usuário.",
  typePassword: "Digite sua senha.",
  dontKnowUs: "Ainda não conhece a Eztools?",
  accessOurWebsite: "Visite nosso site!",
  welcomeLoading: "Bem-vindo! Por favor aguarde..."

};
export const LoginEN = {
  forgotPassword: "Forgot the password?",
  keepLogged: "Keep me logged in",
  addTask: "Add task",
  user: "Username",
  password: "Password",
  buttonEnter: "Login",
  googleButton: "Login with Google",
  success: "Success",
  sucessMessage: "You are logged in",
  error: "Error",
  errorMessage: "Wrong username or password",
  welcome: "Welcome.",
  loginToContinue: "Log in to continue.",
  typeUser: "Type your username.",
  typePassword: "Type your password.",
  dontKnowUs: "Still don't know us?",
  accessOurWebsite: "Visit our website!",
  welcomeLoading: "Welcome! Please wait..."
};
export const LoginES = {
  forgotPassword: "Se olvidó de la contraseña?",
  keepLogged: "Mantenerme conectado",
  addTask: "Adicionar tarea",
  user: "Usuario",
  password: "Contraseña",
  buttonEnter: "Iniciar Sesión",
  googleButton: "Iniciar Sesión Con Google",
  success: "Éxito",
  sucessMessage: "Estás conectado",
  error: "Error",
  errorMessage: "Nombre de usuario o contraseña incorrectos",
  welcome: "Bienvenido.",
  loginToContinue: "Inicia sesión para continuar.",
  typeUser: "Ingresa tu usuario.",
  typePassword: "Ingresa tu contraseña.",
  dontKnowUs: "¿Aún no conoces Eztools?",
  accessOurWebsite: "Visite nuestro website!",
  welcomeLoading: "¡Bienvenido! Espere por favor..."
};

export const ForgotPT = {
  title: "esqueci minha senha ",
  subtitle:
    "Insira seu login ou nome de usuário. Você receberá um e-mail com as instruções para a troca de senha",
  input: "Login ou nome de usuário",
  button: "Recuperar Senha",
  backToLogin: "Voltar para a página Login",
};

export const ForgotEN = {
  title: "Forgot my password",
  subtitle:
    "Enter your login or username. You will receive an email with instructions for changing your password.",
  input: "Login or username",
  button: "Recover Password",
  backToLogin: "Back to login page",
};

export const ForgotES = {
  title: "olvide mi contraseña ",
  subtitle:
    "Ingrese su nombre de usuario. Recibirá un correo electrónico con instrucciones para cambiar su contraseña.",
  input: "Nombre de usuario",
  button: "Recuperar contraseña",
  backToLogin: "Volver a la página de inicio de sesión",
};
