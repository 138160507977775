import script from "./treeNode.vue?vue&type=script&setup=true&lang=js"
export * from "./treeNode.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSlideTransition from 'quasar/src/components/slide-transition/QSlideTransition.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QItemSection,QToggle,QItemLabel,QBtn,QSlideTransition});
