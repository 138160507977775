export default {
  /**
   * Método responsável por trazer todos os projetos e os Organizers
   * @returns void
   */
  async getProjects() {
    this.loadingProject = true;
    // Busca os Organizers
    const organizers = await this.getOrganizers();
    this.organizers = organizers.map((organizer) => ({
      id: organizer.organizerId,
      title: organizer.organizerValue,
    }));

    //Busca os Projetos
    const res = await this.getApi("get", "/handler/v2/agile/projects");
    const projects = res.data;

    this.listProjectsForBoards = res.data;

    if (projects.some((project) => project.projects)) {
      this.withoutGroup = false;
      this.projects = projects.map((p) => ({
        id: p.organizerId,
        organizerName: p.organizerName,
        imageHeader: "",
        AgileProjects: p.projects,
        logo: null,
        changeStatus: false,
      }));
    } else {
      this.withoutGroup = true;
      this.projects = [
        {
          id: 1,
          organizerName: "Sem Agrupador",
          imageHeader: "",
          AgileProjects: projects,
          logo: null,
          changeStatus: false,
        },
      ];
    }
    this.defaultProjects = this.$utils.cloneObject(this.projects);
    this.loadingProject = false;

    // Busca os Status
    await this.getStatus();

    // Busca os usuários
    this.users = await this.storeUsers.getUsers;
  },

  /**
   * Traz o projeto pelo ID
   * @param {String|Number} projectId
   * @returns Response
   */
  async getProjectById(projectId) {
    let id = projectId === "all" ? "" : projectId;
    const res = await this.getApi("get", "/handler/v2/agile/projects/" + id);

    if ((res && res.success) || Boolean(res.data)) {
      let organizer = await this.getApi(
        "get",
        "/handler/v2/agile/organizer/" + res.data.organizerId
      );

      this.currentProject = res.data;
      if (Array.isArray(organizer) && organizer.length > 0) {
        // console.log(organizer[0]);
        this.currentProject["organizer"] = organizer[0];
      }
      this.currentProject["organizerId"] = res.data.organizerId;

      let user = await this.storeUsers.getUserById(res.data.head);
      this.currentProject["headData"] = user;

      //Essa data serve para criar a linha do tempo do gantt de atividades
      this.currentProject["startDate"] = this.currentProject.createdAt;
    }
    this.loadingProject = false;
    return !res.error && Boolean(res.data);
  },

  async getProjectNameById(projectId) {
    let id = projectId === "all" ? "" : projectId;
    const res = await this.getApi("get", "/handler/v2/agile/projects/" + id);
    this.projectName = res.data.title;
    // console.log("AQUIIIIIIII", res.data.title);
  },

  /**
   * Cria Um novo Projeto
   * @param {Object} project
   * @returns {Boolean}
   */
  async insertProject(project) {
    let newProject = this.$utils.cloneObject(project);

    delete newProject["headData"];
    delete newProject["organizer"];

    newProject["statusId"] = this.status.find((s) => s.name === "start").id;

    // console.log(newProject)
    const res = await this.getApi(
      "post",
      "/handler/v2/agile/projects",
      newProject
    );
    if (res && res.success) {
      await this.getProjects();
    }

    return res && res.success;
  },

  /**
   * Atualiza um projeto pelo Id
   * @param {String|Number} projectId
   * @param {Object} project
   * @returns Response
   */
  async updateProject(projectId, project) {
    const res = await this.getApi(
      "put",
      `/handler/v2/agile/projects/${projectId}`,
      project
    );
    if (res && res.success) {
      this.getProjects();
    }
    return res;
  },

  /**
   * Tráz todos os status e atualiza this.status com a resposta
   */
  async getStatus() {
    const res = await this.getApi("get", "/handler/v2/agile/status");
    if (res && res.success) {
      this.status = res.data;
    }
  },
};
