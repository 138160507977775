<template>
  <div class="w-full">
    <q-select
      outlined
      dense
      :label="label"
      :options="options"
      :option-value="optionValue"
      :option-label="optionLabel"
      :value="modelValue"
      :hint="hint"
      v-model="value"
      :clearable="clearable"
      color="blue"
      transition-show="jump-up"
      transition-hide="jump-down"
      behavior="menu"
      :emit-value="emitValue"
      :label-color="error ? 'red' : ''"
      :loading="load"
      label-color="grey"
    >
      <template #selected>
        <p class="font-semibold">{{ value ? value[optionLabel] : "" }}</p>
      </template>
      <template #option="scope">
        <q-item-section v-bind="scope.itemProps" v-if="scope.opt.group">
          <q-item-label
            class="text-xs uppercase pl-2 pt-1 border-b border-grey400"
            >{{ scope.opt.group }}</q-item-label
          >
        </q-item-section>
        <q-item
          v-else
          v-bind="scope.itemProps"
          class="q-py-['2px'] q-px-md"
          style="min-height: 25px"
        >
          <q-item-section avatar v-if="scope.opt.icon">
            <q-icon :name="scope.opt.icon" size="xs" />
          </q-item-section>
          <q-item-section avatar v-if="showAvatar">
            <q-avatar
              size="30px"
              v-if="
                scope.opt.avatar !== '' &&
                scope.opt.avatar !== null &&
                scope.opt.avatar !== undefined
              "
            >
              <img :src="scope.opt.avatar" />
            </q-avatar>
            <q-avatar size="30px" v-else color="primary">
              <span class="font-black uppercase text-white">{{
                scope.opt[optionLabel] &&
                typeof scope.opt[optionLabel] === "string" &&
                scope.opt[optionLabel].split(" ")[0]
                  ? scope.opt[optionLabel].split(" ")[0][0].toUpperCase()
                  : ""
              }}</span>
              <span
                class="font-black uppercase text-white"
                v-if="
                  scope.opt[optionLabel] &&
                  typeof scope.opt[optionLabel] === 'string' &&
                  scope.opt[optionLabel].split(' ')[1]
                "
                >{{
                  scope.opt[optionLabel] &&
                  typeof scope.opt[optionLabel] === "string"
                    ? scope.opt[optionLabel].split(" ")[1][0].toUpperCase()
                    : ""
                }}</span
              >
            </q-avatar>
          </q-item-section>
          <q-item-section>
            <q-item-label class="text-weight-regular text-xs color-orange">
              {{
                optionLabel !== undefined &&
                typeof scope.opt[optionLabel] === "string" &&
                scope.opt[optionLabel].startsWith("$")
                  ? $t(scope.opt[optionLabel])
                  : scope.opt[optionLabel]
              }}
            </q-item-label>
          </q-item-section>
        </q-item>
      </template>
      <template v-slot:after v-if="!info">
        <q-icon v-if="appendIcon" :name="appendIcon" color="secondary">
          <q-tooltip v-if="appendtext" color="dark">
            <span class="inline-block text-xs max-w-[300px]">{{
              appendtext
            }}</span>
          </q-tooltip>
        </q-icon>
      </template>
      <template #after v-if="info">
        <q-btn
          icon="fal fa-info-circle"
          dense
          round
          unelevated
          flat
          color="blue-3"
          size="sm"
        >
          <q-tooltip >
            <span class="inline-block text-xs max-w-[300px]">
            {{ info }}
          </span>
          </q-tooltip>
        </q-btn>
      </template>
    </q-select>
  </div>
</template>

<script>
export default {
  name: "SelectComponent",
  props: [
    "modelValue",
    "info",
    "options",
    "label",
    "hint",
    "error",
    "optionValue",
    "optionLabel",
    "emitValue",
    "showAvatar",
    "loading",
    "appendIcon",
    "appendText",
    "clearable",
  ],
  emits: ["update:modelValue"],
  computed: {
    load() {
      return this.loading;
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  watch: {
    value(newValue) {
      // console.log(newValue)
    },
  },
};
</script>
