<template>
  <header
    class="flex items-center justify-between relative px-[2rem] h-16 space-x-2"
  >
    <div class="flex justify-between items-center space-x-2 w-full">
      <div class="flex items-center space-x-1">
        <q-breadcrumbs
          dense
          class="uppercase text-xl text-grey900 font-semibold"
          separator="/"
          active-color="primary"
        >
          <q-breadcrumbs-el label="Projetos" />
        </q-breadcrumbs>
      </div>

      <InputQuasarComponent
        v-model="searchProject"
        label="Pesquisar projeto."
        type="text"
        color="light-blue"
        icon="search"
        style="width: 100%"
      />

      <div>
        <div class="flex space-x-2">
          <q-btn
            dense
            flat
            unelevated
            color="accent"
            label="Projeto"
            icon="add"
            padding="3px"
            @click="(openNewProject = true), (isUpdate = false)"
            class="full-width"
          />
          <ModalNewProject
            v-model:project="store.currentProject"
            v-model:openNewProject="openNewProject"
            v-model:clientValidate="statusClient"
            v-model:nameValidate="statusName"
            @save="saveOrUpdateProject"
            :users="users"
            :loading="saveLoading"
          />
        </div>
      </div>
    </div>
  </header>

  <div class="px-[2rem] mt-2 h-full">
    <!-- v-if="projectListGroup" -->
    <q-table
      :rows="rows"
      :columns="cols"
      :loading="loading"
      row-key="id"
      separator="cell"
      style="height: calc(100% - 140px)"
      :pagination="initialPagination"
      class="my-sticky-header-table"
      table-header-class="table-header"
      flat
      bordered
    >
      <!-- LOADING -->
      <template v-slot:loading>
        <q-inner-loading showing>
          <q-spinner-dots size="sm" color="primary" />
        </q-inner-loading>
      </template>
      <!-- HEADER -->
      <template v-slot:header="props">
        <q-tr :props="props" class="bg-white">
          <q-th
            v-for="col in props.cols"
            :key="col.name"
            :props="props"
            class="uppercase text-primary"
          >
            {{ col.label }}
          </q-th>
        </q-tr>
      </template>

      <!-- PARENT ROW -->
      <template #body="props">
        <q-tr :props="props" class="group">
          <!-- Expand cell/button -->
          <q-td key="expand" name="expand" :props="props" auto-width>
            <q-btn
              flat
              dense
              round
              color="accent"
              :icon="props.row.expand ? 'remove' : 'add'"
              @click="props.row.expand = !props.row.expand"
            />
          </q-td>
          <q-td key="organizerName" :props="props">
            <q-chip square size="md">
              <q-avatar icon="layers" color="purple-4" text-color="white" />
              <q-item-label class="text-purple-4"
                >{{ props.row.organizerName }}
              </q-item-label>
            </q-chip>
            <q-btn
              :icon="orderChildRows ? 'arrow_downward' : ' arrow_upward'"
              flat
              dense
              color="primary"
              size="xs"
              @click="orderChildRows = !orderChildRows"
              class="opacity-0 group-hover:opacity-100"
            />
          </q-td>
          <q-td key="head" :props="props"></q-td>
          <q-td key="endDate" :props="props"></q-td>
          <q-td key="actions" :props="props" auto-width></q-td>
        </q-tr>
        <!-- CHILD ROW -->

        <q-tr
          v-for="(child, index) in childRows(props.row.AgileProjects)"
          :key="index"
          v-show="props.row.expand"
          :props="props"
          @dblclick="
            $router.push({
              name: 'activities',
              params: { companyId: props.row.id, projectId: child.id },
            })
          "
          class="group"
        >
          <!-- EXPAND -->
          <q-td key="expand" :props="props" />
          <!-- TITLE -->
          <q-td key="organizerName" :props="props">
            <q-item-label class="font-medium capitalize">{{
              child.title
            }}</q-item-label>
          </q-td>
          <!-- RESPONSÁVEL -->
          <q-td key="head" :props="props">
            <div class="flex items-center space-x-3">
              <q-avatar size="sm" color="secondary" text-color="white">
                <img
                  v-if="findUser(child.head)?.photourl"
                  :src="findUser(child.head)?.photourl"
                />
                <span v-else>{{
                  findUser(child.head)
                    ? getInitialLetters(findUser(child.head).name)
                    : "EZ"
                }}</span>
                <q-tooltip class="bg-dark" :offset="[10, 10]">
                  {{ findUser(child.head) ? findUser(child.head).name : "EZ" }}
                </q-tooltip>
              </q-avatar>

              <q-item-label class="font-medium">{{
                findUser(child.head) ? findUser(child.head).name : "EZ"
              }}</q-item-label>
            </div>
          </q-td>
          <!-- END DATE -->
          <q-td key="endDate" :props="props">
            <div class="flex space-x-2">
              <q-chip
                dense
                square
                outline
                :color="colorDate(child.endDate)"
                text-color="white"
              >
                <q-avatar
                  :icon="child.endDate === null ? 'info' : 'event'"
                  :color="colorDate(child.endDate)"
                  text-color="white"
                />
                {{
                  child.endDate === null
                    ? "Prazo não informado"
                    : moment(child.endDate).format("DD/MM/YYYY")
                }}
              </q-chip>
            </div>
          </q-td>
          <!-- ACTIONS -->
          <q-td key="actions" :props="props" class="flex justify-end space-x-3">
            <q-btn
              flat
              dense
              size="10px"
              color="primary"
              icon="edit"
              @click="editProject(child, props.row.id)"
              :loading="editLoading"
              class="opacity-0 group-hover:opacity-100"
            >
              <template v-slot:loading>
                <q-spinner-dots />
              </template>
              <q-tooltip class="bg-dark" :offset="[10, 10]"> Editar </q-tooltip>
            </q-btn>
            <q-btn
              flat
              dense
              size="12px"
              color="primary"
              icon="arrow_right_alt"
              @click="
                $router.push({
                  name: 'activities',
                  params: { companyId: props.row.id, projectId: child.id },
                })
              "
            >
              <q-tooltip class="bg-dark" :offset="[10, 10]">
                Atividades
              </q-tooltip>
            </q-btn>
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, inject, watch } from "vue";
import { useProjects } from "@/store/products/projects";
import { useProjectFilters } from "@/store/products/projects/filters";
import { useLoginStore } from "@/store/loginStore";
import { showNegativeNotify, showPositiveNotify } from "@/utils/useNotify";
import { getInitialLetters } from "@/utils/useUtils";
import InputQuasarComponent from "../../../../../global/componentsVue/inputComponent/inputQuasarComponent.vue";
import ModalNewProject from "../../../components/kanban/projectsList/modalNewProject.vue";
import { t } from "i18next";
import moment from "moment";

const $utils = inject("utils");
const store = useProjects();
const filtersStore = useProjectFilters();
const storeLogin = useLoginStore();

const cols = ref([]);
const rows = ref([]);

const orderChildRows = ref(true);
const searchProject = ref("");
const defaultProjectToEdit = ref(null);
const openNewProject = ref(false);
const editLoading = ref(false);
const saveLoading = ref(false);
const isUpdate = ref(true);

const statusClient = ref(false);
const statusName = ref(false);
const defaultRows = ref(null);
const initialPagination = {
  sortBy: "desc",
  descending: false,
  page: 1,
  rowsPerPage: 50,
  // rowsNumber: xx if getting data from a server
};

const loading = computed(() => store.loadingProject);
const users = computed(() => store.users);
const storeRows = computed(() => store.projects);

watch(
  () => storeRows,
  (newValue) => {
    if (newValue) {
      mountRows();
    }
  },
  { deep: true }
);

onMounted(async () => {
  await store.getProjects();
  await filtersStore.getTags();
  await filtersStore.getPriorities();
  await filtersStore.getUsers();
  await filtersStore.getStatus();
  mountRows();
  mountColumns();
  // console.log(store.defaultProjects);
  defaultRows.value = JSON.parse(JSON.stringify(store.defaultProjects));

  store.currentProject = {
    title: "",
    organizer: null,
    organizerId: null,
    head: storeLogin.userId,
    headData: {
      id: storeLogin.userId,
      name: storeLogin.username,
      avatar: null,
    },
    endDate: moment().format("YYYY-MM-DD HH:MM:mm"),
  };
  // console.log("TAGS", filtersStore.tagList);
  // console.log("PRIORITIES", filtersStore.priorities);
});

const childRows = (array) => {
  if (orderChildRows.value) {
    array.sort((a, b) => a.title.localeCompare(b.title));
  } else {
    array.sort((a, b) => b.title.localeCompare(a.title));
  }

  return array.filter((project) =>
    project.title.toLowerCase().includes(searchProject.value.toLowerCase())
  );
};

const mountRows = () => {
  rows.value = storeRows.value.map((element) => {
    return {
      id: element.id,
      organizerName: element.organizerName,
      endDate: "",
      AgileProjects: element.AgileProjects,
      expand: true,
    };
  });
};

const mountColumns = () => {
  const columnLabels = {
    organizerName: "NOME",
    head: "RESPONSÁVEL",
    endDate: "DATA FINAL",
    actions: "ACTIONS",
  };

  let expand = [{ label: "", name: "expand", field: "expand", align: "left" }];
  storeRows.value[0]["head"] = "";
  storeRows.value[0]["endDate"] = "";
  storeRows.value[0]["actions"] = "";

  const columns = Object.keys(storeRows.value[0])
    .map((element) => {
      const label = columnLabels[element] || null;
      return {
        name: element,
        field: element,
        label: element === "actions" ? "" : label,
        align: element === "actions" ? "right" : "left",
        sortable: element === "organizerName" ? true : false,
      };
    })
    .filter((col) => col.label !== null);

  const orderedColumns = ["organizerName", "head", "endDate", "actions"]
    .map((fieldName) => columns.find((col) => col.field === fieldName))
    .filter((col) => col !== undefined);

  cols.value = [...expand, ...orderedColumns];
};

async function editProject(item) {
  editLoading.value = true;
  const res = await store.getProjectById(item.id);

  if (res) {
    editLoading.value = false;
    defaultProjectToEdit.value = $utils.cloneObject(store.currentProject);
    isUpdate.value = true;
    openNewProject.value = true;
    editLoading.value = false;
  }
}

function findUser(id) {
  return users.value.find((user) => user.id === id);
}

function colorDate(date) {
  var given = moment(date);
  var current = moment().startOf("day");
  let diff = given.diff(current, "days");
  if (date === null) return "grey";
  if (diff <= 0) return "red";
  if (diff <= 7) return "orange";
  else return "green";
}

function CheckNewProjectValues() {
  let valuesChecked = [{ name: "Empresa" }, { name: "Nome do Projeto" }];

  if (
    (store.currentProject.organizerId === null ||
      store.currentProject.organizerId === "") &&
    !store.withoutGroup
  ) {
    valuesChecked[0]["filled"] = false;
  } else {
    valuesChecked[0]["filled"] = true;
  }

  if (
    store.currentProject.title === null ||
    store.currentProject.title === ""
  ) {
    valuesChecked[1]["filled"] = false;
  } else {
    valuesChecked[1]["filled"] = true;
  }

  return valuesChecked;
}

function showNotify(valuesChecked) {
  if (valuesChecked.every((value) => !value.filled)) {
    showNegativeNotify(t("kanban.allRequiredFieldsNewProject"));
    statusName.value = true;
  } else if (!valuesChecked[0].filled) {
    showNegativeNotify(t("kanban.requiredOrganizerFieldNewProject"));
    statusName.value = true;
  } else if (!valuesChecked[1].filled) {
    showNegativeNotify(t("kanban.requiredProjectNameFieldNewProject"));
    statusName.value = true;
  }
}

async function updateProject() {
  saveLoading.value = true;
  // console.log(defaultProjectToEdit.value);
  // console.log(store.currentProject);

  const projectChange = $utils.compareDiff(
    defaultProjectToEdit.value,
    store.currentProject,
    ["headData", "organizer"]
  );

  // console.log(projectChange.dataChanged);
  if (projectChange.changed) {
    const res = await store.updateProject(
      store.currentProject.id,
      projectChange.dataChanged
    );

    if (res && res.success) {
      saveLoading.value = false;
      showPositiveNotify(t("kanban.projectUpdatedSuccessfully"));
    } else {
      showNegativeNotify(t("kanban.projectUpdatedError"));
    }
    if (res && res.error) {
      showNegativeNotify(t("kanban.projectUpdatedError"));
    }
  } else showNegativeNotify(t("kanban.nothingChanged"));

  store.currentProject = $utils.cloneObject(store.defaultProject);
  isUpdate.value = false;
}

/** SALVA OU CHAMA A FUNÇÃO DE ATUALIZAR O PROJETO */
async function saveOrUpdateProject() {
  // Verifica se os valores obrigatórios foram preenchidos
  let valuesChecked = CheckNewProjectValues();

  // Exibe notificação alertando sobre os valores não preenchidos;
  showNotify(valuesChecked);

  if (valuesChecked.every((value) => value.filled)) {
    // Se o usuário não for preenchido, busca o usuário logado
    let loggedUserId = parseInt(localStorage.getItem("@vueweb:user"));
    // console.log(store.currentProject.head);
    if (store.currentProject.head === null && loggedUserId) {
      store.currentProject["head"] = {
        id: loggedUserId,
      };
    } else if (store.currentProject.head === null) {
      $utils.showNegativeNotify(t("kanban.chooseAHead"));
      return;
    }

    // Se isUpdate for False, criar novo projeto
    if (!isUpdate.value) {
      const res = await store.insertProject(store.currentProject);
      if (res) {
        $utils.showPositiveNotify(t("kanban.projectCreatedSuccessfully"));
      } else $utils.showNegativeNotify(t("kanban.projectCreatedError"));
      openNewProject.value = false;
    } else {
      await updateProject();

      openNewProject.value = false;
    }
  }
}

/** VERIFICA SE O MANAGER/HEAD FOI PREENCHIDO, SE NÃO EXIBE O ERRO NO SELECT */
watch(
  () => store.currentProject.organizer,
  (newValue) => {
    if (newValue != null && newValue !== "") {
      statusClient.value = false;
    }
  },
  { deep: true }
);

/** ADICIONA O ID DE ORGANIZER EM ORGANIZERID */
watch(
  () => store.currentProject.organizer,
  (newValue) => {
    if (newValue != null && newValue !== "") {
      // console.log(newValue);
      store.currentProject.organizerId = newValue.id;
    }
  },
  { deep: true }
);

/** ADICIONA O ID DE HEADDATA EM HEAD */
watch(
  () => store.currentProject.headData,
  (newValue) => {
    if (newValue != null && newValue !== "") {
      store.currentProject.head = newValue.id;
    }
  },
  { deep: true }
);

/** FORMATA A DATA PARA SALVAR */
watch(
  () => store.currentProject.endDate,
  (newValue) => {
    if (newValue != null && newValue !== "") {
      store.currentProject.endDate = moment(newValue).format(
        "YYYY-MM-DD HH:MM:mm"
      );
    }
  },
  { deep: true }
);

/** VERIFICA SE O TITULO FOI PREENCHIDO, SE NÃO EXIBE O ERRO NO INPUT */
watch(
  () => store.currentProject.title,
  (newValue) => {
    if (newValue != null && newValue !== "") {
      statusName.value = false;
    }
  },
  { deep: true }
);

//Muda a variavel IsUpdate para falso caso o modal seja fechado
watch(openNewProject, (newValue) => {
  if (!newValue) {
    isUpdate.value = false;
    store.currentProject = $utils.cloneObject(store.defaultProject);
  }
});
</script>

<style scoped>
thead tr {
  position: sticky !important;
  z-index: 1;
  top: 0 !important;
}

tbody {
  scroll-margin-top: 48px;
}
</style>
