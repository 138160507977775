import script from "./profileComponent.vue?vue&type=script&setup=true&lang=js"
export * from "./profileComponent.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QAvatar,QItemLabel,QInput,QIcon,QPopupProxy,QDate,QBtn});qInstall(script, 'directives', {ClosePopup});
