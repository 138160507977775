import "quasar/dist/quasar.css";
import lang from "quasar/lang/pt-BR.js";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/material-icons-outlined/material-icons-outlined.css";
import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';;

// To be used on app.use(Quasar, { ... })
export default {
  config: {
    brand: {
      primary: "#1874cf",
      positive: "#60a5fa",
      secondary: "#5DA6AD",
      accent: "#4F2B72",
      dark: "#000000",
    },
  },
  plugins: {
    Notify,
    Dialog,
  },
  lang: lang,
 };
