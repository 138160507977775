import { showNegativeNotify, showPositiveNotify } from "@/utils/useNotify";
import checkComponent from "@/utils/checkComponent";
import checkDataChange from "@/utils/checkDataChange";
import compareDiff from "@/utils/compareDiff";
import cloneObject from "@/utils/cloneObject";
import formatNumber from "@/utils/formatNumber";
import leftPad from "@/utils/numberUtils";
const utils = {
  showNegativeNotify,
  showPositiveNotify,
  checkComponent,
  checkDataChange,
  compareDiff,
  cloneObject,
  formatNumber,
  leftPad,
};
export default {
  install: (app) => {
    // inject a globally available $translate() method
    app.config.globalProperties.$utils = utils;
    app.provide("utils", utils);
  },
};
