<template>
  <q-dialog>
    <q-card>
      <q-toolbar class="bg-secondary text-white">
        <p class="w-80 uppercase">Alterar imagem de perfil</p>
        <q-btn flat icon="close" round v-close-popup />
      </q-toolbar>
      <div class="py-4 px-4 space-y-4">
        <div class="flex flex-col justify-center items-center space-y-3">
          <q-avatar
            size="80px"
            font-size="50px"
            color="secondary"
            text-color="white"
          >
            <img v-if="user.photourl" :src="user.photourl" />
            <span v-else>{{ getInitialLetters(user.name) }}</span>
          </q-avatar>
          <!-- BUTTON FILE -->
          <div class="space-y-1 w-full">
            <q-uploader
              field-name="image"
              accept="image/*"
              auto-upload
              flat
              dense
              bordered
              max-file-size="1080"
              @rejected="onRejected"
              style="width: 100%"
              disable
            />
            <p class="text-xs text-grey500" id="file_input_help">
              SVG, PNG, JPG (MAX. 1080px).
            </p>
          </div>

          <!-- BUTTON FILE -->
        </div>
        <div class="flex items-center space-x-2">
          <span class="uppercase text-sm">ou escolha um avatar</span>
          <hr class="border-[0.px] text-grey400 w-[50%]" />
        </div>
        <div
          v-if="!showImages"
          class="grid grid-cols-4 justify-items-center gap-y-2"
        >
          <q-skeleton type="circle" v-for="(item, index) in 5" :key="index" />
        </div>
        <div v-else class="grid grid-cols-4 justify-items-center gap-y-2">
          <q-avatar
            class="cursor-pointer"
            v-for="(item, index) in imagesProfile"
            :key="index.id"
            @click="$emit('changeImage', item.url)"
            size="60px"
          >
            <img :src="item.url" />
          </q-avatar>
          <q-avatar
            size="60px"
            color="secondary"
            text-color="white"
            class="cursor-pointer"
            @click="$emit('changeImage', 'emptyImage')"
          >
            <span>{{ getInitialLetters(user.name) }}</span>
          </q-avatar>
        </div>
        <div class="flex justify-end">
          <q-btn
            flat
            dense
            label="Salvar"
            color="primary"
            v-close-popup
            @click="$emit('saveImage')"
          />
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { ref, defineEmits, defineProps } from "vue";
import { getInitialLetters } from "@/utils/useUtils";
// import Image from "@/assets/images/avatar/avatar_3.webp"

defineProps(["user"]);
defineEmits(["changeImage", "saveImage"]);

const showImages = ref(false);

setTimeout(() => {
  showImages.value = true;
}, 1500);

const onRejected = (rejectedEntries) => {
  console.log(rejectedEntries);
};

const imagesProfile = ref([
  {
    id: 1,
    url: "https://i.ibb.co/HdCb9t0/avatar-3.webp",
  },
  {
    id: 2,
    url: "https://i.ibb.co/gTh5X2M/avatar-4.webp",
  },
  {
    id: 3,
    url: "https://i.ibb.co/vX5Vn4G/avatar-1.webp",
  },
  {
    id: 4,
    url: "https://i.ibb.co/Hndts5J/avatar-2.webp",
  },
]);
</script>
