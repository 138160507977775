export default {
  /**
   * Traz os organizers
   * @returns Response.data
   */
  async getOrganizers() {
    const res = await this.getApi("get", "/handler/v2/agile/organizer");

    // console.log(res.data);
    if (res.data) return res.data;
    else {
      // console.log("Origanizers error: ", res);
      return [];
    }
  },
};
