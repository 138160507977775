import moment from "moment";
import { apiDev } from "@/services/api";
import { Buffer } from "buffer";

export default {
  mountFilters(filters, title, item) {
    // console.log(title, item);
    const { status, id } = item;

    if (!filters.where) {
      filters.where = {};
    }

    if (title === "users" || title === "tags" || title === "head") {
      // Verificação para os títulos 'users' e 'tags'
      if (status) {
        if (Object.hasOwnProperty.call(filters.where, title)) {
          if (title === "head") filters.where[title].push(id);
          else filters.where[title] += ";" + id;
        } else {
          if (title === "head") filters.where[title] = [id];
          else filters.where[title] = id.toString();
        }
      } else {
        // Remover o valor do objeto filters, se existir
        if (Object.hasOwnProperty.call(filters.where, title)) {
          if (title === "head") {
            const index = filters.where.head.indexOf(id);
            filters.where.head.splice(index, 1);
            if (filters.where.head.length === 0) delete filters.where.head;
          } else
            filters.where[title] = filters.where[title]
              .split(";")
              .filter((filterId) => filterId !== id.toString())
              .join(";"); // Remove o id da string
          if (filters.where[title] === "") {
            delete filters.where[title];
          }
        }
      }
    } else {
      // Para outros títulos, mantém o comportamento original do array
      if (item.date) {
        console.log(item.date);
        if (status) filters.where[title] = item.date;
        else delete filters.where[title];
      } else {
        if (status) {
          if (Object.hasOwnProperty.call(filters.where, title)) {
            filters.where[title].push(id);
          } else {
            filters.where[title] = [id];
          }
        } else {
          // Remover o valor do objeto filters, se existir
          if (Object.hasOwnProperty.call(filters.where, title)) {
            const index = filters.where[title].indexOf(id);
            if (index !== -1) {
              filters.where[title].splice(index, 1);

              if (filters.where[title].length === 0) {
                delete filters.where[title];
              }
            }
          }
        }
      }
    }

    // console.log("MOUNTFILTERS", filters);
    if (this.route.path.includes("boards")) {
      // console.log("MOUNT QUERY BOARDS", filters);
      this.mountQueryBoards();
    } else {
      // console.log("MOUNT QUERY PROJECTS", filters);
      this.mountQuery();
    }
  },

  mountQuery() {
    let filters = null;
    if (Object.keys(this.filters).length > 0) {
      const filtersToString = JSON.stringify(this.filters);
      filters = Buffer.from(filtersToString).toString("base64");
    }
    console.log("FILTERS BASE64", filters);
    return filters;
  },
  mountQueryBoards() {
    let filters = null;
    if (Object.keys(this.boardFilters).length > 0) {
      const filtersToString = JSON.stringify(this.boardFilters);
      filters = Buffer.from(filtersToString).toString("base64");
    }
    // console.log("FILTERS BOARDS BASE64", filters);
    return filters;
  },

  updateFiltersWithGroups(title, item) {
    const { status, id } = item;
    if (!this.filters.where) {
      this.filters.where = {};
    }
    if (status) {
      // Se o status for true, remove o title do objeto
      delete this.filters.where[title];
    } else {
      // Se o status for false, adiciona o novo title e value ao objeto
      this.filters.where[title] = id.toString();
    }
    console.log("UPDATEFILTERS", this.filters);
    this.mountQuery();
  },

  /**
   * Método responsável por fazer as chamadas à api
   * @param {String} method
   * @param {String} endpoint
   * @param {Object|Array} body
   * @returns {Object} Response
   */
  async getApi(method, endpoint, body = null) {
    // console.log(body);
    try {
      let res = { error: true };
      if (body !== null) {
        res = await apiDev[method](endpoint, body)
          .then((res) => res.data)
          .catch((error) => {
            console.log(
              `Erro na chamada |${method}| para ${endpoint}: ${error}`
            );
            return error;
          });
      } else {
        res = await apiDev[method](endpoint)
          .then((res) => res.data)
          .catch((error) => {
            console.log(
              `Erro na chamada |${method}| para ${endpoint}: ${error}`
            );
            return error;
          });
      }

      return res;
    } catch (error) {
      console.log(`Erro na chamada |${method}| para ${endpoint}: ${error}`);
      return { error };
    }
  },
  /**
   * Método responsável por trazer a atividade ou tarefa pelo id
   * @param {number,string} id
   * @param {string} type  [stories ou tasks]
   * @returns
   */
  async findDataById(id, type) {
    let currentId = id === "all" ? "" : id;
    const newtype = this.groupBy === "storyId" ? "tasks" : type;
    let res = await this.getApi(
      "get",
      `/handler/v2/agile/${newtype}/${currentId}`
    );

    res.data.tags = res.data.tagsObj ? res.data.tagsObj : [];

    res.data.users = res.data.usersObj ? res.data.usersObj : [];

    res.data["priority"] = res.data.storyPriority
      ? res.data.storyPriority
      : res.data.taskPriority
      ? res.data.taskPriority
      : {};
    res.data["status"] = this.storeFilters.statusList.find(
      (status) => status.id === res.data.statusId
    );
    return res.data;
  },

  /**
   * Método responsável por criar um novo card, tanto em tarefas quanto em atividades
   * @param {string} key  [currentProject ou currentActivity]
   * @param {object} infosIds {projectId:id,storyId:id<optional>}
   * @param {string} type [stories ou tasks]
   * @param {string} typeId [storyId ou taskId]
   * @param {string,number} laneId id da lane que o card foi criado
   * @param {object} card Card criado { title:title}
   * @returns
   */
  async insertCard(
    key,
    infosIds,
    type,
    typeId,
    laneId,
    card,
    statusId = null,
    container = null
  ) {
    let newcard = {
      ...infosIds,
      startDate: moment().format("YYYY-MM-DDTHH:MM:mm"),
      endDate: moment().format("YYYY-MM-DDTHH:MM:mm"),
      description: "",
      createdById: this.storeLogin.userId,
      updatedById: this.storeLogin.userId,
      statusId: statusId === null ? laneId : statusId,
      ...card,
    };

    const res = await this.getApi("post", "/handler/v2/agile/" + type, newcard);

    console.log(res);
    if (res.success) {
      let resCard = await this.findDataById(res.data[typeId], type);
      if (resCard.tagsObj) resCard["tags"] = resCard.tagsObj;
      if (resCard.usersObj) resCard["users"] = resCard.usersObj;
      resCard["priority"] = this.getItemDataToCard(
        resCard.priorityId,
        "priorities",
        "storeFilters"
      );
      resCard["status"] = this.getItemDataToCard(
        resCard.statusId,
        "statusList",
        "storeFilters"
      );

      // console.log(resCard);

      //eslint-disable-next-line
      let cardToGantt = {
        startDate: resCard.startDate,
        endDate: resCard.endDate,
        ganttBarConfig: {
          id: resCard.id,
          label: resCard.title,
          style: {
            // background: this[currentObject].lanes.find(lane => lane.id == subitem.laneId).color,
            background: key === "currentProject" ? "#168821" : "#5C9BA9",
            color: "white",
            opacity: "0.65",
          },
        },
      };
      if (container === "backlog") {
        this.addCardInDataBacklog(key, "gantt", cardToGantt, container);
        this.addCardInDataBacklog(key, "lanes", resCard, container);
      } else {
        this.addCardInData(key, "lanes", resCard, laneId);
        this.addCardInData(key, "gantt", cardToGantt);
      }
    }

    return res;
  },

  async updateCard(key, id, card) {
    const res = await this.getApi(
      "put",
      `/handler/v2/agile/${key}/${id}`,
      card
    );

    if (res.success && res.data.updated > 0) return res;
    else {
      this.$utils.showNegativeNotify(this.t("kanban.movedError"));
      return { success: false };
    }
  },
};
