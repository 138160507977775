import { apiDev } from "@/services/api";
import { Buffer } from "buffer";

export default {

  setViewKanban(value) {
    localStorage.setItem("@viewKanbanBoard", value);
    this.viewKanban = value;
  },
  /**
   * Cria uma board
   * @param {Object} data Dados da board
   * @returns
   */
  async createBoard(data) {
    let newboard = { ...data };
    console.log(newboard);
    // newboard["createdById"] = this.storeLogin.userId;
    // newboard["updatedById"] = this.storeLogin.userId;
    const res = await apiDev
      .post("/handler/v2/agile/boards", newboard)
      .then((response) => response.data)
      .catch((error) => error);

    console.log(res);
    if (res.success) {
      newboard["id"] = res.data.boardId;
      return res;
    }

    return { error: true };
  },
  /**
   * Busca todas as boards
   */
  async getBoards() {
    const res = await apiDev
      .get("/handler/v2/agile/boards")
      .then((response) => response.data)
      .catch((error) => error);

    if (res.success) {
      this.boardsList = res.data;
    }
  },

  async getBoardName(id) {
    await apiDev
      .get(`/handler/v2/agile/boards/${id}`)
      .then((response) => {
        this.boardName = response.data?.data?.title;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  /**
   * Busca a board pelo id
   * @param {Number} id
   */
  async getBoardById(id, sprintId) {
    let filter = {};
    if (sprintId !== "inative") {
      filter["where"] = {};
      filter["where"]["sprint"] = sprintId !== "inative" ? sprintId : null;
    }
    const filtersToString = JSON.stringify(filter);
    let filters = Buffer.from(filtersToString).toString("base64");

    let query = Object.keys(filter).length > 0 ? "?filter=" + filters : "";

    const res = await apiDev
      .get(`/handler/v2/agile/boards/${id}${query}`)
      .then((response) => response.data)
      .catch((error) => error);

    if (res.success) {
      this.currentBoard = { ...res.data };
      this.currentBoard.projectIds = [];
      for (let id of res.data.projectIds.split(";")) {
        const hasProject = await this.getProjectById(id);
        if (hasProject) {
          this.currentBoard.projectIds.push({
            id: this.currentProject.id,
            name: this.currentProject.title,
          });
        }
      }
      this.currentBoard.stakeholderIds = [];
      for (let id of res.data.stakeholderIds.split(";")) {
        const user = await this.storeUsers.getUserById(id);
        if (user) {
          // console.log(user);
          this.currentBoard.stakeholderIds.push({
            id: user.id,
            name: user.name,
          });
        }
      }

      this.defaultFormBoard = JSON.parse(JSON.stringify(this.currentBoard));
      this.currentBoardId = res.data.id;
      this.currentBoard["lanes"] = await this.getBoardLanesPerGroupBy(query);

      // console.log(this.currentBoard)
    }

    if (!this.currentBoard.activeSprintId) {
      this.viewKanban = "backlog";
      //chamar endpoint para backlog
    }
  },

  async deleteBoard(id) {
    apiDev
      .delete(`/handler/v2/agile/boards/${id}`)
      .then((response) => {
        // console.log(response);
        this.currentBoard = response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async updateBoard(id, data) {
    const res = await this.getApi(
      "put",
      `/handler/v2/agile/boards/${id}`,
      data
    );

    console.log(res);
    return {
      error: false,
    };
  },
};
