import { useStorage } from "@vueuse/core";
// import { boardActivities } from "../../../api-examples/projects";
import { useHomeConfig } from "../../homeConfigStore.js";
import moment from "moment";
import { useLoginStore } from "../../loginStore";
import { useUsers } from "../../securityAndSettings/usersStore";
import { inject } from "vue";
import { useProjectFilters } from "./filters";
import { useTranslation } from "i18next-vue";
import { useRoute } from "vue-router";
import { showNegativeNotify } from "@/utils/useNotify";

export default function data() {
  const imagesStore = useHomeConfig();
  const storeLogin = useLoginStore();
  const storeUsers = useUsers();
  const storeFilters = useProjectFilters();
  const $utils = inject("utils");
  const { t } = useTranslation();
  const route = useRoute();
  return {
    showNegativeNotify,
    imagesStore,
    storeLogin,
    t,
    route,
    storeUsers,
    $utils,
    storeFilters,
    users: [],
    projects: [],
    listProjectsForBoards: [],
    title: "",
    filters: {},
    boardFilters: {},
    base64Filters: null,
    currentProject: {
      title: "",
      organizer: null,
      organizerId: null,
      head: storeLogin.userId,
      headData: {
        id: storeLogin.userId,
        name: storeLogin.username,
        avatar: null,
      },
      endDate: moment().format("YYYY-MM-DD HH:MM:mm"),
    },
    defaultProject: {
      title: "",
      organizer: null,
      organizerId: null,
      head: storeLogin.userId,
      headData: {
        id: storeLogin.userId,
        name: storeLogin.username,
        avatar: null,
      },
      endDate: moment().format("YYYY-MM-DD HH:MM:mm"),
    },
    withoutChangesCurrentProject: {},
    currentCompanyId: useStorage("@eztools.companyId", null),
    currentProjectId: useStorage("@eztools.projectId", null),
    dataActivities: [],
    defaultProjects: null,
    dataActivitiesDefault: [],
    viewKanban: localStorage.getItem("@viewKanbanBoard") || "list",
    viewBacklog: "tasks",
    filterType: "all",
    organizers: [],
    // boardActivities,
    projectActivitiesWithTask: [],
    currentActivity: {},
    allTasksOfProject: [],
    status: [],
    loadingProject: false,
    activiyLoading: false,
    tasksLoading: false,
    loadingChecklistItem: false,
    withoutGroup: false,
    projectName: null,
    activityName: null,
    comments: [],
    loadingComments: false,
    checklist: [],
    checklistLoading: false,
    sprintAndBacklogLoading: false,
    textForFilterKanban: "",
    groupBy: useStorage("@eztools.groupBy", "statusId"),
    groupLanes: useStorage("@eztools.groupLanes", "statusLanes"),
    backlogGroup: "priorityId",
    //Boards
    boardsList: [],
    currentBoard: {},
    currentBoardId: useStorage("@eztools.boardId", null),

    showListBoards: false,
    dataKanbanBacklog: [
      {
        collapsed: "N",
        color: "#1066a9",
        custBtns: "N",
        entityModelId: 1,
        fillColor: "#1066a9",
        hidden: "N",
        id: 1,
        saveBtn: "N",
        sla: "N",
        title: "backlog",
        type: "USETK",
        typeBacklog: "backlog",
        uuid: "b4ba7771-5c07-4418-94ac-5cc1f4ddbc41",
        tasks: [],
      },
      {
        collapsed: "N",
        color: "#1066a9",
        custBtns: "N",
        entityModelId: 1,
        fillColor: "#1066a9",
        hidden: "N",
        id: 2,
        saveBtn: "N",
        sla: "N",
        title: "Sprint 1",
        type: "USETK",
        typeBacklog: "sprint",
        uuid: "b4ba7771-5c07-4418-94ac-5cc1f4ddbc41",
        tasks: [],
      },
    ],
    sprintId: null,
    formBoard: {
      title: null,
      description: null,
      projectIds: [],
      stakeholderIds: [],
      // sprintTime: 5,
      sprintTitle: null,

      // startDate: new Date().toLocaleDateString("pt-Br"),
    },
    loadBtnEditBoard: true,
    defaultFormBoard: {},
    currentSprint: {},
    boardName: "",
    sprintName: "",
    sprintTasksDefault: [],
  };
}
