<template>
  <HeaderComponent @saveProfile="updateUser" />
  <div class="flex pr-4 h-[calc(100%_-_95px)]">
    <div class="border-r border-grey200 w-1/4 h-full">
      <q-tabs
        v-model="currentTab"
        vertical
        indicator-color="secondary"
        active-color="secondary"
        inline-label
        no-caps
        style=""
      >
        <q-tab
          name="profile"
          icon="person"
          label="Perfil"
          style="justify-content: flex-start; padding-left: 1.7rem"
      /></q-tabs>
    </div>
    <div class="h-full w-full overflow-y-auto">
      <q-tab-panels
        v-model="currentTab"
        animated
        swipeable
        vertical
        transition-prev="jump-up"
        transition-next="jump-up"
        style="height: 100%"
      >
        <q-tab-panel name="profile" style="padding: 0 16px 16px 16px">
          <ProfileComponent @saveImage="updateUser" />
        </q-tab-panel>
      </q-tab-panels>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useUsers } from "@/store/securityAndSettings/usersStore.js";
import { useLoginStore } from "@/store/loginStore";
import HeaderComponent from "@/components/profile/headerComponent.vue";
import ProfileComponent from "../../components/profile/profileComponent.vue";
import { showNegativeNotify, showPositiveNotify } from "@/utils/useNotify";

const store = useUsers();
const loginStore = useLoginStore();
const currentTab = ref("profile");
const currentUser = computed(() => store.currentUser);

const updateUser = async () => {
  let userCopy = JSON.parse(JSON.stringify(store.currentUser));

  let id = userCopy.id;
  delete userCopy["createdAt"];
  delete userCopy["updatedAt"];
  delete userCopy["authType"];

  let res = await store.updateUser(id, userCopy);

  if (res.success) {
    if (res.data.data.photourl) {
      localStorage.setItem("@vueweb:imageProfile", res.data.data.photourl);
      loginStore.profileImage = res.data.data.photourl;
    } else {
      localStorage.removeItem("@vueweb:imageProfile");
      loginStore.profileImage = "";
    }
    showPositiveNotify(
      `Usuário ${currentUser.value.name} foi editado com sucesso.`
    );
    return;
  }
  showNegativeNotify(
    `Houve um erro ao editar o usuário: ${currentUser.value.name}`
  );
};
</script>
