//eslint-disable-next-line
import { Buffer } from "buffer";
import { showPositiveNotify, showNegativeNotify } from "@/utils/useNotify.js";
export default {
  //eslint-disable-next-line
  async updateSprint(data, sprintId, isInit = true) {
    // console.log(this.sprintId);
    // console.log("UPDATE SPRINT NEW DATA", data);

    const resultSprint = await this.getApi(
      "put",
      `/handler/v2/agile/sprints/${sprintId}`,
      data
    );
    // console.log(resultSprint);
    if (resultSprint.success) {
      if (isInit) {
        const resultBoard = await this.getApi(
          "put",
          `/handler/v2/agile/boards/${this.currentBoardId}`,
          { activeSprintId: sprintId }
        );

        if (resultBoard.success) {
          // showPositiveNotify("Sprint alterada com sucesso");
          return { error: false };
        }
        showNegativeNotify(this.t("boards.updadeSprintError"));
        return { error: true };
      }
      // showPositiveNotify("Sprint alterada com sucesso");
      return { error: false };
    } else {
      showNegativeNotify(this.t("boards.updadeSprintError"));
      return { error: true };
    }
  },
  /**
   * Recupera os dados em backlog - só funciona no agrupamento de tarefas por atividades
   * @param {Number} boardId
   */
  async getBacklog(boardId) {
    this.sprintAndBacklogLoading = true;

    let status = this.storeFilters.statusList.find(
      (status) => status.title === "backlog"
    );

    let query = "?q_boardId=" + boardId + "groupBy=statusId";

    if (!this.boardFilters.where) {
      this.boardFilters["where"] = {
        taskSprintId: null,
      };
    } else {
      this.boardFilters.where["taskSprintId"] = null;
    }
    if (!this.boardFilters.where) {
      this.boardFilters["where"] = {
        statusId: [status.id],
      };
    } else {
      this.boardFilters.where["statusId"] = [status.id];
    }

    query = query + "&filters=" + this.mountQueryBoards();

    // // console.log(JSON.parse(Buffer.from(filters, "base64").toString()));
    const resData = await this.getApi(
      "get",
      "/handler/v2/agile/boards/tasks" + query
    );

    // console.log("BACKLOG", resData);
    if (resData.success) {
      const newdata = await this.addTagsAndUsersInTasks(resData.data);

      this.currentBoard.backlog = {
        id: "backlog",
        title: "backlog",
        type: "backlog",
        tasks: resData?.data[0]?.items ? newdata[0].items : [],
      };
    }
    this.sprintAndBacklogLoading = false;
  },
  /**
   * Recupera os dados da sprint - só funciona no agrupamento de tarefas por atividades
   * @param {Number} boardId
   */
  async getSprint(sprintId) {
    this.sprintAndBacklogLoading = true;
    let status = this.storeFilters.statusList.find(
      (status) => status.title === "backlog"
    );

    // console.log(this.boardFilters.where);
    if (this.boardFilters.where) {
      if (
        this.boardFilters.where.statusId &&
        this.boardFilters.where.statusId.includes(status.id)
      ) {
        let indexStatusInFilter = this.boardFilters.where.statusId.findIndex(
          (id) => id === status.id
        );
        if (
          indexStatusInFilter >= 0 &&
          this.boardFilters.where.statusId.length > 0
        ) {
          this.boardFilters.where.statusId.splice(indexStatusInFilter, 1);
        }
        if (this.boardFilters.where.statusId.length == 0) {
          delete this.boardFilters.where.statusId;
        }
      }
      if (
        Object.keys(this.boardFilters.where).some(
          (key) => key === "taskSprintId"
        )
      ) {
        delete this.boardFilters.where.taskSprintId;
      }
    }

    let query =
      this.boardFilters.where && Object.keys(this.boardFilters.where).length > 0
        ? "?filters=" + this.mountQueryBoards()
        : "";

    // console.log(query);
    const resData = await this.getApi(
      "get",
      "/handler/v2/agile/sprints/" + sprintId + query
    );

    if (resData.data) {
      this.sprintName = resData.data.title;
    }
    // console.log(resData);

    if (resData.success) {
      const data = { ...resData.data };
      data["items"] = resData.data.tasks;
      const newdata = await this.addTagsAndUsersInTasks([data]);
      data["tasks"] = newdata[0].items;
      this.currentBoard.sprint = {
        type: "sprint",
        ...data,
      };

      this.sprintTasksDefault = [...data.tasks];
    }
    this.sprintAndBacklogLoading = false;
  },

  async getDataBacklogPage(boardId, sprintId) {
    await this.getBacklog(boardId);
    await this.getSprint(sprintId);
  },

  async createSprint(data) {
    let newdata = { ...data };

    newdata["createdById"] = this.storeLogin.userId;
    newdata["updatedById"] = this.storeLogin.userId;
    try {
      const response = await this.getApi(
        "post",
        "/handler/v2/agile/sprints",
        newdata
      );
      // console.log("DATA NEW SPRINT", data);
      if (response.success) {
        showPositiveNotify("Sprint criada com sucesso");
        return response.data;
      } else {
        return { error: true };
      }
    } catch (error) {
      showNegativeNotify(
        "Houve um erro ao criar a sprint. Revise os dados do formulário"
      );
      console.error("ocorreu um erro ao fazer a chamada de api");
    }
  },
};
