export const KanbanPT = {
  button: "Compartilhar",
  searchInput: "Insira uma palavra chave",
  newBoard: "Novo Board",
  listBoards: "Lista de Boards",
  addTask: "Adicionar Tarefa",
  titleDeleteTask: "Deseja deletar esta tarefa?",
  delete: "Deletar",
  cancel: "Cancelar",
  deletingTask: "Deletando Tarefa",
  deletedTask: "Tarefa Deletada",
  filterUserTooltip: "Todos os usuários",
  filterByUser: "Minhas Tarefas",
  filterByStatus: "Status",
  filterPriorities: "Prioridades",
  filterByTags: "Tags",
  filterByCompany: "Empresas",
  filterByData: "Data",
  priorityLow: "Baixa",
  priorityMedium: "Média",
  priorityHigh: "Alta",
  priorityUrgent: "Urgente",
  DataNoData: "Sem Data",
  DataOverdue: "Em Atraso",
  DataToBeDeliveredInOneDay: "A ser entregue em um dia",
  DataToBeDeliveredInAWeek: "A ser entregue em uma semana",
  DataToBeDeliveredInAMonth: "A ser entregue em um mês",
  newBoardTitle: "Criar Novo Board",
  newBoardInput: "Nome do Board",
  newBoardHint: "Escolha um nome e pressione enter para continuar",
  start: "Inicio",
  progress: "Em progresso",
  blocked: "Bloqueado",
  concluded: "Concluído",
  canceled: "Cancelado",
  backlog:"Pendências",
  validation:"Validação",
  addActivity: "Adicionar Atividade",
  allRequiredFieldsNewProject:
    "Os campos 'Empresa' e 'Nome do projeto' são obrigatórios!",
  requiredOrganizerFieldNewProject: "O campo 'Empresa' é obrigatório!",
  requiredProjectNameFieldNewProject:
    "O campo 'Nome do projeto' é obrigatório!",
  projectCreatedSuccessfully: "Projeto Criado com sucesso!",
  projectCreatedError: "Projeto não foi criado!",
  chooseAHead:"Por favor selecione um responsável",
  projectUpdatedSuccessfully: "Projeto Atualizado com sucesso!",
  projectUpdatedError: "O projeto não pode ser atualizado!",
  nothingChanged: "Nenhum campo foi alterado!",
  searchUsers: "Pesquisar Usuário",
  searchTags: "Pequisar tag",
  description: "Descrição",
  msgToDescription: "Hmmm. Sua tarefa está precisando de uma descrição!",
  withoutGroup:"Sem Agrupador",
  alertEndDate:"A data de início não pode ser maior que a data de entrega",
  movedError:"A tarefa ou atividade, não pode ser atualizada, tente novamente mais tarde."
};

export const KanbanEN = {
  button: "Share",
  searchInput: "Enter a keyword ",
  newBoard: "New Board",
  listBoards: "List of Boards",
  addTask: "Add Task",
  titleDeleteTask: "Do you want to delete this task?",
  delete: "Delete",
  cancel: "Cancel",
  deletingTask: "Deleting Task",
  deletedTask: "Task deleted",
  filterUserTooltip: "All Users",
  filterByUser: "My Tasks",
  filterPriorities: "Priorities",
  filterByTags: "Tags",
  filterByData: "Data",
  filterByStatus: "Status",
  priorityLow: "Low",
  priorityMedium: "Medium",
  priorityHigh: "High",
  priorityUrgent: "Urgent",
  DataNoData: "No Data",
  DataOverdue: "Overdue",
  DataToBeDeliveredInOneDay: "To be delivered in one day",
  DataToBeDeliveredInAWeek: "To be delivered in a week",
  DataToBeDeliveredInAMonth: "To be delivered in a month",
  newBoardTitle: "Create New Board",
  newBoardInput: "Name of Board",
  newBoardHint: "Choose a name and press enter to continue",
  start: "Start",
  progress: "In progress",
  blocked: "Blocked",
  concluded: "Conluded",
  canceled: "Canceled",
  pending: "Pending",
  
  backlog:"Backlog",
  validation:"Validation",
  addActivity: "Add Activity",
  filterByCompany: "Companies",
  allRequiredFieldsNewProject:
    "The fields 'Company' and 'Project name' are mandatory!",
  requiredOrganizerFieldNewProject: "The 'Company' field is mandatory!",
  requiredProjectNameFieldNewProject: "The 'Project name' field is required!",
  projectCreatedSuccessfully: "project created successfully!",
  projectCreatedError: "Project not created!",
  chooseAHead:"Please select a person in charge",
  projectUpdatedSuccessfully: "project updated successfully!",
  projectUpdatedError: "The project cannot be updated!",
  nothingChanged: "No fields have been changed!",
  searchUsers: "Search User",
  searchTags: "Search Tag",
  description: "Description",
  msgToDescription: "Hmmm. Your task needs a description!",
  withoutGroup:"Without group",
  alertEndDate:"The start date cannot be greater than the due date",
  movedError:"The task or activity cannot be updated, please try again later."
};

export const KanbanES = {
  button: "Compartir",
  searchInput: "Ingrese una palabra clave",
  newBoard: "Nuevo Board",
  listBoards: "Lista de Boards",
  addTask: "Agregar Tarea",
  titleDeleteTask: "¿Quieres eliminar esta tarea?",
  delete: "Eliminar",
  cancel: "Cancelar",
  deletingTask: "Borrando Tarea",
  deletedTask: "Tarefa Eliminada",
  filterUserTooltip: "todos los usuarios",
  filterByUser: "Mis Tareas",
  filterPriorities: "Prioridades",
  filterByTags: "Tags",
  filterByData: "Fecha",
  filterByStatus: "Status",
  priorityLow: "Baja",
  priorityMedium: "Media",
  priorityHigh: "Alta",
  priorityUrgent: "Urgente",
  DataNoData: "Sin fecha",
  DataOverdue: "en retraso",
  DataToBeDeliveredInOneDay: "Para ser entregado en un día",
  DataToBeDeliveredInAWeek: "Para ser entregado en una semana",
  DataToBeDeliveredInAMonth: "Para ser entregado en un mes",
  newBoardTitle: "Crear Nuevo Board",
  newBoardInput: "Nombre de Board",
  newBoardHint: "Elige un nombre y presiona enter para continuar",
  start: "Comenzar",
  progress: "En progreso",
  blocked: "Interrumpido",
  concluded: "Conluído",
  pending: "Pendiente",
  canceled: "Cancelado",
  backlog:"Reserva",
  validation:"Validación",
  addActivity: "Añadir Actividad",
  filterByCompany: "Compañías",
  allRequiredFieldsNewProject:
    "¡Los campos 'Empresa' y 'Nombre del proyecto' son obligatorios!",
  requiredOrganizerFieldNewProject: "¡El campo 'Empresa' es obligatorio!",
  requiredProjectNameFieldNewProject:
    "¡El campo 'Nombre del proyecto' es obligatorio!",
  projectCreatedSuccessfully: "¡Proyecto creado con éxito!",
  projectCreatedError: "¡Proyecto no creado!",
  
  chooseAHead:"Por favor seleccione una persona a cargo",
  projectUpdatedSuccessfully: "¡Proyecto actualizado con éxito!",
  projectUpdatedError: "¡El proyecto no se puede actualizar!",
  nothingChanged: "¡Ningún campo ha sido cambiado!",
  searchUsers: "Buscar Usuario",
  searchTags: "Buscar etiquetas",
  description: "Descrição",
  msgToDescription: "Mmm. ¡Tu tarea necesita una descripción!",
  withoutGroup:"Sin Mero",
  alertEndDate:"La fecha de inicio no puede ser mayor que la fecha de vencimiento.",
  movedError:"La tarea o actividad no se puede actualizar, inténtalo de nuevo más tarde."
};
