<template>
  <div
    class="w-full h-full flex justify-start items-center flex-col relative animate-pulse"
  >
    <div
      class="w-11/12 h-16 my-4 rounded-sm bg-grey100 flex justify-end items-center"
    >
      <div
        v-for="index in 4"
        :key="`item-${index}`"
        :class="`w-${index}4 h-8 bg-grey200 mx-2`"
      ></div>
    </div>
    <div
      class="w-11/12 h-5/6 bg-grey100 grid grid-cols-1 grid-rows-8 justify-end items-center gap-4 p-4"
    >
      <div
        v-for="index in 8"
        :key="`item-${index}`"
        :class="`w-full h-full bg-grey200 mx-2`"
      ></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
